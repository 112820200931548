import { USERS_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],


    //## User Data List
    userListPaginatePage: 0,
    userListPaginateLimit: null,
    userListPaginateSortBy: null,
    userList: [],
    userListTotalNum: null,

    recentCreateNewUserData: null,
    recentUpdateUserData: null,
   
}

const users = (state = INIT_STATE, action) => {
    switch(action.type) {
        
        //###--------------------------------------------------
    
        case USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_REQUEST: 
            return {
                ...state,
            };
        case USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_SUCCESS: 
            return {
                ...state,
                userList: action.userList,
                userListPaginatePage: action.page,
                userListPaginateLimit: action.limit,
                userListPaginateSortBy: action.sortBy,
            };
        case USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_SUCCESS_ADD_MORE: 
            return {
                ...state,
                userList: [...state.userList, ...action.userList],
                userListPaginatePage: action.page,
                userListPaginateLimit: action.limit,
                userListPaginateSortBy: action.sortBy,
            };
        case USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };      
        case USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //###--------------------------------------------------
    
        case USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_REQUEST: 
            return {
                ...state,
            };
        case USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_SUCCESS: 
            return {
                ...state,
                userListTotalNum: action.userListTotalNum,
            };
        case USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                userListTotalNum: null,
            };      
        case USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                userListTotalNum: 0,
            };   
        //###--------------------------------------------------
        //###--------------------------------------------------
    
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_INFO_SUCCESS: 
            return {
                ...state,

            };
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };      
        //###--------------------------------------------------
    
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_COMPANY_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_COMPANY_INFO_SUCCESS: 
            return {
                ...state,
                recentUpdateUserData: action.userData,
            };
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_COMPANY_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };
        //###--------------------------------------------------
    
        case USERS_ACTION_TYPES.CREATE_NEW_USER_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case USERS_ACTION_TYPES.CREATE_NEW_USER_INFO_SUCCESS: 
            return {
                ...state,
                recentCreateNewUserData: action.userData,

            };
        case USERS_ACTION_TYPES.CREATE_NEW_USER_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };

        //###--------------------------------------------------
    
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_CONTACT_NAME_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_CONTACT_NAME_SUCCESS: 
            return {
                ...state,
                recentUpdateUserData: action.userData,
            };
        case USERS_ACTION_TYPES.UPDATE_ONE_USER_CONTACT_NAME_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };

                //###--------------------------------------------------
        case USERS_ACTION_TYPES.DELETE_ONE_USER_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case USERS_ACTION_TYPES.DELETE_ONE_USER_INFO_SUCCESS: 
            return {
                ...state,

            };
        case USERS_ACTION_TYPES.DELETE_ONE_USER_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };
        //###--------------------------------------------------

        
        default: 
            return state;
    }

}

export { users };