import axios from 'axios';
import { API_CONSTANTS } from '../../api';
import { ADMIN_JWT_KEY } from '../../api';

/**
*   NAME: savePlayerId
*   DESCRIPTION: async save player id of admin
*   METHOD: POST
*/
async function savePlayerId(phoneNumber, playerId) {
    const requestOptions = {
        url: API_CONSTANTS.OSG_SAVE_PLAYER_ID,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ADMIN_JWT_KEY,
            // 'Access-Control-Allow-Origin':'*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify({
            phoneNumber: phoneNumber,
            playerId: playerId
        })
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("savePlayerId Request Error:");
        console.error(error);

        var data = {requestError: error.message};
        return data;
    }
}


/**
*   NAME: getPaginateNotifList
*   DESCRIPTION: get paginate company list
*   METHOD: POST
*/
async function getPaginateNotifList(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.NOTIF_LIST_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getPaginateNotifList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: getCountTotalNotifNum
*   DESCRIPTION: count total company list
*   METHOD: POST
*/
async function getCountTotalNotifNum(token,filterBy) {
    const requestOptions = {
        url: API_CONSTANTS.NOTIF_COUNT_TOTAL_NUM_URL,
        method: 'POST',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            filterBy: filterBy,
        }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getCountTotalNotifNum Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}




/**
*   NAME: putUpdateOneNotifStatus
*   DESCRIPTION: update one notification status
*   METHOD: PUT
*/
async function putUpdateOneNotifStatus(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.NOTIF_UPDATE_STATUS_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateOneNotifStatus Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: putUpdateOneNotifInfo
*   DESCRIPTION: update one notification info
*   METHOD: PUT
*/
async function putUpdateOneNotifInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.NOTIF_UPDATE_INFO_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateOneNotifInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

export const notifServices = {

    savePlayerId,
    getPaginateNotifList,
    getCountTotalNotifNum,

    putUpdateOneNotifStatus,
    putUpdateOneNotifInfo,
};