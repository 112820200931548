import saveAs from 'file-saver';

import { TRUCKS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// COMPANY_STATUS,
	// COMPANY_TYPE, 
} from '../../../constants';
import { TRUCKS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { truckServices } from '../../../api/services';

//##-------------------------------------------------------------------------

function requestGetOneTruckCargoInsuranceDocList(truckId){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_REQUEST,
		truckId,
	}
}

function successGetOneTruckCargoInsuranceDocList(docList){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_SUCCESS,
		docList,
	}
}

function failureGetOneTruckCargoInsuranceDocList(error){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_FAILURE,
		error,
	}
}

function emptyGetOneTruckCargoInsuranceDocList(error){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_EMPTY,
		error,
	}
}

function getOneTruckCargoInsuranceDocList(truckId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestGetOneTruckCargoInsuranceDocList(
    					truckId
    				)
    			);

    			let responseJson = await truckServices
    			.getOneTruckCargoInsuranceDocList(
    				token,
    				truckId
    			)

    			if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							if(responseJson.data.length > 0){
								await dispatch(
									successGetOneTruckCargoInsuranceDocList(
										responseJson.data
									)
								);

								return responseJson.data;
							}
							else{
								await dispatch(
									emptyGetOneTruckCargoInsuranceDocList(
										TRUCKS_CONSTANTS.ERROR_FAILURE_GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_EMPTY
									)
								);

								return null;
							}
						}
						else{
							await dispatch(
								emptyGetOneTruckCargoInsuranceDocList(
									TRUCKS_CONSTANTS.ERROR_FAILURE_GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_EMPTY
								)
							);

							return null;
						}
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetOneTruckCargoInsuranceDocList(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetOneTruckCargoInsuranceDocList(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureGetOneTruckCargoInsuranceDocList(
								TRUCKS_CONSTANTS.ERROR_FAILURE_GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST
							)
						);
		                return null;
					}
				}
				else{
					await dispatch(
						failureGetOneTruckCargoInsuranceDocList(
							TRUCKS_CONSTANTS.ERROR_FAILURE_GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetOneTruckCargoInsuranceDocList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetOneTruckCargoInsuranceDocList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------
function requestDownloadOneTruckCargoInsurancePdf(truckId,docId){
	return {
		type: TRUCKS_ACTION_TYPES.DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_REQUEST,
		truckId,
		docId
	}
}

function successDownloadOneTruckCargoInsurancePdf(){
	return {
		type: TRUCKS_ACTION_TYPES.DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_SUCCESS,
	}
}

function failureDownloadOneTruckCargoInsurancePdf(error){
	return {
		type: TRUCKS_ACTION_TYPES.DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_FAILURE,
		error
	}
}


function downloadOneTruckCargoInsurancePdf(truckId,docId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestDownloadOneTruckCargoInsurancePdf(
    					truckId,
    					docId,
    				)
    			);

    			let response = await truckServices
    			.downloadOneTruckCargoInsurancePdf(
    				token,
    				truckId,
    				docId,
    			)

    			if(response.status === GLOBAL_CONSTANTS.HTTP_STATUS_SUCCESS){
					try{
						let pdfData = response.data;


						let blob = new Blob([pdfData], { type: "application/pdf;charset=utf-8" });
						await saveAs(blob,docId + ".pdf")

						await dispatch(
							successDownloadOneTruckCargoInsurancePdf(
	
							)
						); 

						return true;
					}
					catch(errSaveAsFile){
						await dispatch(
							failureDownloadOneTruckCargoInsurancePdf(
								errSaveAsFile.message
							)
						);
		            	return false;
					}
				}
				else{
						
					if(response.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(response.response.data);
	                        await dispatch(
	                        	failureDownloadOneTruckCargoInsurancePdf(errMsg)
	                        );
	                    return false;
	                }
					else if(response.requestError) {
	                    await dispatch(
	                    	failureDownloadOneTruckCargoInsurancePdf(
	                    		response.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureDownloadOneTruckCargoInsurancePdf(
								TRUCKS_CONSTANTS.ERROR_FAILURE_DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_PDF
							)
						);
		                return null;
					}
				}
    		}
    		catch(error){
    			await dispatch(
    				failureDownloadOneTruckCargoInsurancePdf(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureDownloadOneTruckCargoInsurancePdf(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------

export const truckCargoInsuranceDocActions = {

	requestGetOneTruckCargoInsuranceDocList,
	successGetOneTruckCargoInsuranceDocList,
	failureGetOneTruckCargoInsuranceDocList,
	emptyGetOneTruckCargoInsuranceDocList,
	getOneTruckCargoInsuranceDocList,


	requestDownloadOneTruckCargoInsurancePdf,
	successDownloadOneTruckCargoInsurancePdf,
	failureDownloadOneTruckCargoInsurancePdf,
	downloadOneTruckCargoInsurancePdf,



}