
import { LIGHTBOX_ACTION_TYPES } from '../constants';
// import { LIGHTBOX_CONSTANTS } from '../constants';
// import { GLOBAL_CONSTANTS } from '../../../constants';


function openLightbox(imageList){
    return {
        type: LIGHTBOX_ACTION_TYPES.OPEN_LIGHTBOX,
        imageList,
    }
}

function closeLightbox(){
    return {
        type: LIGHTBOX_ACTION_TYPES.CLOSE_LIGHTBOX,
    }
}

//##---------------------------------------------------

function setLightboxImageList(imageList){
    return {
        type: LIGHTBOX_ACTION_TYPES.SET_LIGHTBOX_IMAGE_LIST,
        imageList
    }
}

function resetLightboxImageList(){
    return {
        type: LIGHTBOX_ACTION_TYPES.RESET_LIGHTBOX_IMAGE_LIST,
    }
}

function setLightboxPhotoIndex(photoIndex){
    return {
        type: LIGHTBOX_ACTION_TYPES.SET_LIGHTBOX_PHOTO_INDEX,
        photoIndex
    }
}


export const lightboxActions = {
    openLightbox,
    closeLightbox,
    setLightboxImageList,
    resetLightboxImageList,
    setLightboxPhotoIndex,
};