import { ADMIN_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],

    adminCompanyStatusList: [],
}

const admin = (state = INIT_STATE, action) => {
    switch(action.type) {


        case ADMIN_ACTION_TYPES.GET_ALL_ADMIN_COMPANY_STATUS_LIST_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case ADMIN_ACTION_TYPES.GET_ALL_ADMIN_COMPANY_STATUS_LIST_SUCCESS: 
            return {
                ...state,
                adminCompanyStatusList: action.adminCompanyStatusList,
            };
        case ADMIN_ACTION_TYPES.GET_ALL_ADMIN_COMPANY_STATUS_LIST_FAILURE: 
            return {
                ...state,
                error: [...action.error],
            };
        //##---------------------------------------------------------------
        case ADMIN_ACTION_TYPES.CREATE_NEW_ADMIN_COMPANY_STATUS_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case ADMIN_ACTION_TYPES.CREATE_NEW_ADMIN_COMPANY_STATUS_SUCCESS: 
            return {
                ...state,

            };
        case ADMIN_ACTION_TYPES.CREATE_NEW_ADMIN_COMPANY_STATUS_FAILURE: 
            return {
                ...state,
                error: [...action.error],
            };
        //##---------------------------------------------------------------
        case ADMIN_ACTION_TYPES.UPDATE_ADMIN_COMPANY_STATUS_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case ADMIN_ACTION_TYPES.UPDATE_ADMIN_COMPANY_STATUS_SUCCESS: 
            return {
                ...state,
            };
        case ADMIN_ACTION_TYPES.UPDATE_ADMIN_COMPANY_STATUS_FAILURE: 
            return {
                ...state,
                error: [...action.error],
            };
        //##---------------------------------------------------------------
        case ADMIN_ACTION_TYPES.DELETE_ADMIN_COMPANY_STATUS_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case ADMIN_ACTION_TYPES.DELETE_ADMIN_COMPANY_STATUS_SUCCESS: 
            return {
                ...state,
            };
        case ADMIN_ACTION_TYPES.DELETE_ADMIN_COMPANY_STATUS_FAILURE: 
            return {
                ...state,
                error: [...action.error],
            };        
        default: 
            return state;
    }

}

export { admin };