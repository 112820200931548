export const DRIVERS_CONSTANTS = {
	ERROR_FAILURE_PAGINATE_DRIVER: 'ERROR_FAILURE_PAGINATE_DRIVER',
	ERROR_FAILURE_COUNT_PAGINATE_DRIVER: 'ERROR_FAILURE_COUNT_PAGINATE_DRIVER',
	ERROR_EMPTY_COUNT_PAGINATE_DRIVER: 'ERROR_EMPTY_COUNT_PAGINATE_DRIVER',
	ERROR_FAILURE_EDIT_ONE_DRIVER_INFO: 'ERROR_FAILURE_EDIT_ONE_DRIVER_INFO',
	
	ERROR_FAILURE_GET_ONE_DRIVER_LICENSE_DOC_LIST: 'ERROR_FAILURE_GET_ONE_DRIVER_LICENSE_DOC_LIST',
	ERROR_FAILURE_GET_ONE_DRIVER_LICENSE_DOC_LIST_EMPTY: 'ไม่มีเอกสาร',
	ERROR_FAILURE_DOWNLOAD_ONE_DRIVER_LICENSE_PDF: 'ERROR_FAILURE_DOWNLOAD_ONE_DRIVER_LICENSE_PDF',


	
}