import { USERS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// USER_STATUS,

} from '../../../constants';
import { USERS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { userServices } from '../../../api/services';



function requestEditOneUserCompanyInfo(){
	return {
		type: USERS_ACTION_TYPES.UPDATE_ONE_USER_COMPANY_INFO_REQUEST
	}
}

function successEditOneUserCompanyInfo(userData){
	return {
		type: USERS_ACTION_TYPES.UPDATE_ONE_USER_COMPANY_INFO_SUCCESS,
		userData,
	}
}



function failureEditOneUserCompanyInfo(error){
	return {
		type: USERS_ACTION_TYPES.UPDATE_ONE_USER_COMPANY_INFO_FAILURE,
		error,
	}
}


function editOneUserCompanyInfo(email,companyRefId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneUserCompanyInfo());


    			let payload ={
		    		email: email,
			        companyRefId: companyRefId,
		    	}

		    	//## [DEBUG]
	    		// console.log('-----User Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await userServices
    			.putUpdateUserCompanyInfoByEmail(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneUserCompanyInfo(
									responseJson.data
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneUserCompanyInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneUserCompanyInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneUserCompanyInfo(
								USERS_CONSTANTS.ERROR_FAILURE_EDIT_USER_COMPANY_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneUserCompanyInfo(
							USERS_CONSTANTS.ERROR_FAILURE_EDIT_USER_COMPANY_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneUserCompanyInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneUserCompanyInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


//##-------------------------------------------------------
function requestCreateNewUserInfo(){
	return {
		type: USERS_ACTION_TYPES.CREATE_NEW_USER_INFO_REQUEST
	}
}

function successCreateNewUserInfo(userData){
	return {
		type: USERS_ACTION_TYPES.CREATE_NEW_USER_INFO_SUCCESS,
		userData,
	}
}



function failureCreateNewUserInfo(error){
	return {
		type: USERS_ACTION_TYPES.CREATE_NEW_USER_INFO_FAILURE,
		error,
	}
}


function createNewUserInfo(newUserData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestCreateNewUserInfo());


    			let payload ={
    				email:  newUserData.email,
		    		phoneNumber: newUserData.phoneNumber,
			        companyRefId: newUserData.companyRefId,
			        authorizations: newUserData.authorizations ? newUserData.authorizations : [],
					name: newUserData.name,
		    	}

		    	//## [DEBUG]
	    		// console.log('-----User Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await userServices
    			.postCreateNewUserInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successCreateNewUserInfo(
									responseJson.data
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureCreateNewUserInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureCreateNewUserInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureCreateNewUserInfo(
								USERS_CONSTANTS.ERROR_FAILURE_CREATE_ONE_USER_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureCreateNewUserInfo(
							USERS_CONSTANTS.ERROR_FAILURE_CREATE_ONE_USER_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureCreateNewUserInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureCreateNewUserInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


//##-------------------------------------------------------


function requestEditOneUserContactName(){
	return {
		type: USERS_ACTION_TYPES.UPDATE_ONE_USER_CONTACT_NAME_REQUEST
	}
}

function successEditOneUserContactName(userData){
	return {
		type: USERS_ACTION_TYPES.UPDATE_ONE_USER_CONTACT_NAME_SUCCESS,
		userData,
	}
}



function failureEditOneUserContactName(error){
	return {
		type: USERS_ACTION_TYPES.UPDATE_ONE_USER_CONTACT_NAME_FAILURE,
		error,
	}
}


function editOneUserContactName(phoneNumber,name){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneUserContactName());


    			let payload ={
		    		phoneNumber: phoneNumber,
			        name: name,
		    	}

		    	//## [DEBUG]
	    		// console.log('-----User Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await userServices
    			.putUpdateUserContactName(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneUserContactName(
									responseJson.data
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneUserContactName(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneUserContactName(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneUserContactName(
								USERS_CONSTANTS.ERROR_FAILURE_EDIT_USER_CONTACT_NAME
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneUserContactName(
							USERS_CONSTANTS.ERROR_FAILURE_EDIT_USER_CONTACT_NAME
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneUserContactName(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneUserContactName(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}



//##-------------------------------------------------------


export const editUserActions = {


	requestEditOneUserCompanyInfo,
	successEditOneUserCompanyInfo,
	failureEditOneUserCompanyInfo,
	editOneUserCompanyInfo,

	requestCreateNewUserInfo,
	successCreateNewUserInfo,
	failureCreateNewUserInfo,
	createNewUserInfo,


	requestEditOneUserContactName,
	successEditOneUserContactName,
	failureEditOneUserContactName,
	editOneUserContactName,
}