// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';



const errorMuiStyles = theme => ({
    // margin: {
    //     margin: theme.spacing.unit,
    // },
    // cssRoot: {
    //     color: theme.palette.getContrastText(purple[500]),
    //     backgroundColor: purple[500],
    //     '&:hover': {
    //         backgroundColor: purple[700],
    //     },
    // },
    errorRoot: {
        height: '100vh',
    },
    errorFrame:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',

    },
    errorText:{
        textAlign: 'center',
    },
    errorMessageHeading:{
        color: theme.color.error.m500.color,
    },
    errorMessageHeading2:{
        color: theme.color.error.m200.color,
    },
});


export { errorMuiStyles };