export const ADMIN_ACTION_TYPES = {

	GET_ALL_ADMIN_COMPANY_STATUS_LIST_REQUEST: 'GET_ALL_ADMIN_COMPANY_STATUS_LIST_REQUEST',
	GET_ALL_ADMIN_COMPANY_STATUS_LIST_SUCCESS: 'GET_ALL_ADMIN_COMPANY_STATUS_LIST_SUCCESS',
	GET_ALL_ADMIN_COMPANY_STATUS_LIST_FAILURE: 'GET_ALL_ADMIN_COMPANY_STATUS_LIST_FAILURE',


	CREATE_NEW_ADMIN_COMPANY_STATUS_REQUEST: 'CREATE_NEW_ADMIN_COMPANY_STATUS_REQUEST',
	CREATE_NEW_ADMIN_COMPANY_STATUS_SUCCESS: 'CREATE_NEW_ADMIN_COMPANY_STATUS_SUCCESS',
	CREATE_NEW_ADMIN_COMPANY_STATUS_FAILURE: 'CREATE_NEW_ADMIN_COMPANY_STATUS_FAILURE',

	UPDATE_ADMIN_COMPANY_STATUS_REQUEST: 'UPDATE_ADMIN_COMPANY_STATUS_REQUEST',
	UPDATE_ADMIN_COMPANY_STATUS_SUCCESS: 'UPDATE_ADMIN_COMPANY_STATUS_SUCCESS',
	UPDATE_ADMIN_COMPANY_STATUS_FAILURE: 'UPDATE_ADMIN_COMPANY_STATUS_FAILURE',

	DELETE_ADMIN_COMPANY_STATUS_REQUEST: 'DELETE_ADMIN_COMPANY_STATUS_REQUEST',
	DELETE_ADMIN_COMPANY_STATUS_SUCCESS: 'DELETE_ADMIN_COMPANY_STATUS_SUCCESS',
	DELETE_ADMIN_COMPANY_STATUS_FAILURE: 'DELETE_ADMIN_COMPANY_STATUS_FAILURE',

}