import { ROOT_ACTION_TYPES } from '../constants';

const INIT_STATE = {
	error: [],
    prefetchingLoading: true,
    prefetchState: false,
    
}

const root = (state = INIT_STATE, action) => {
	switch(action.type) {
        case ROOT_ACTION_TYPES.PREFETCH_START: 
            return {
                ...state,
                prefetchingLoading: true,
                error: [],

            };
        case ROOT_ACTION_TYPES.PREFETCH_FINISHED: 
            return {
                ...state,
                prefetchingLoading: false,
                error: [],
            };
        
                        
        default: 
            return state;
    }
}



export { root };