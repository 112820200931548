import { COMPANY_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],


    //## Company Data List
    companyListPaginatePage: 0,
    companyListPaginateLimit: null,
    companyListPaginateSortBy: null,
    companyList: [],
    companyListTotalNum: null,

    //## Company Verify Doc List
    requestCompanyVerifyDocCompanyId: null,
    companyVerifyDocList: [],

    requestDownloadCompanyVerifyPdfCompanyId: null,
    requestDownloadCompanyVerifyPdfDocId: null,

    //## Company BankAccount Doc List
    requestCompanyBankAccountDocCompanyId: null,
    companyBankAccountDocList: [],

    requestDownloadCompanyBankAccountPdfCompanyId: null,
    requestDownloadCompanyBankAccountPdfDocId: null,
   
}

const company = (state = INIT_STATE, action) => {
    switch(action.type) {
        
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.GET_PAGINATE_COMPANY_LIST_REQUEST: 
            return {
                ...state,
            };
        case COMPANY_ACTION_TYPES.GET_PAGINATE_COMPANY_LIST_SUCCESS: 
            return {
                ...state,
                companyList: action.companyList,
                companyListPaginatePage: action.page,
                companyListPaginateLimit: action.limit,
                companyListPaginateSortBy: action.sortBy,
            };
        case COMPANY_ACTION_TYPES.GET_PAGINATE_COMPANY_LIST_SUCCESS_ADD_MORE: 
            return {
                ...state,
                companyList: [...state.companyList, ...action.companyList],
                companyListPaginatePage: action.page,
                companyListPaginateLimit: action.limit,
                companyListPaginateSortBy: action.sortBy,
            };
        case COMPANY_ACTION_TYPES.GET_PAGINATE_COMPANY_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };      
        case COMPANY_ACTION_TYPES.GET_PAGINATE_COMPANY_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.COUNT_PAGINATE_COMPANY_LIST_REQUEST: 
            return {
                ...state,
            };
        case COMPANY_ACTION_TYPES.COUNT_PAGINATE_COMPANY_LIST_SUCCESS: 
            return {
                ...state,
                companyListTotalNum: action.companyListTotalNum,
            };
        case COMPANY_ACTION_TYPES.COUNT_PAGINATE_COMPANY_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                companyListTotalNum: null,
            };      
        case COMPANY_ACTION_TYPES.COUNT_PAGINATE_COMPANY_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                companyListTotalNum: 0,
            };   
        //###--------------------------------------------------
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_INFO_SUCCESS: 
            return {
                ...state,

            };
        case COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_BADGE_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_BADGE_INFO_SUCCESS: 
            return {
                ...state,

            };
        case COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_BADGE_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };      
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_REQUEST: 
            return {
                ...state,
                error: [],
                requestCompanyVerifyDocCompanyId: action.companyId,
            };
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_SUCCESS: 
            return {
                ...state,
                companyVerifyDocList: action.docList,
            };
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                companyVerifyDocList: [],
            };  
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                companyVerifyDocList: [],
            }; 
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_REQUEST: 
            return {
                ...state,
                error: [],
                requestDownloadCompanyVerifyPdfCompanyId: action.companyId,
                requestDownloadCompanyVerifyPdfDocId: action.docId,
            };
        case COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_SUCCESS: 
            return {
                ...state,

            };
        case COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };    
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_REQUEST: 
            return {
                ...state,
                error: [],
                requestCompanyBankAccountDocCompanyId: action.companyId,
            };
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_SUCCESS: 
            return {
                ...state,
                companyBankAccountDocList: action.docList,
            };
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                companyBankAccountDocList: [],
            };  
        case COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                companyBankAccountDocList: [],
            }; 
        //###--------------------------------------------------
    
        case COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_REQUEST: 
            return {
                ...state,
                error: [],
                requestDownloadCompanyBankAccountPdfCompanyId: action.companyId,
                requestDownloadCompanyBankAccountPdfDocId: action.docId,
            };
        case COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_SUCCESS: 
            return {
                ...state,

            };
        case COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };       
        //###--------------------------------------------------
        case COMPANY_ACTION_TYPES.DELETE_ONE_COMPANY_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case COMPANY_ACTION_TYPES.DELETE_ONE_COMPANY_INFO_SUCCESS: 
            return {
                ...state,

            };
        case COMPANY_ACTION_TYPES.DELETE_ONE_COMPANY_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };

        //###--------------------------------------------------

        
        default: 
            return state;
    }

}

export { company };