const IMAGES = {

	LOGO_WHITE: require('./logo_white_1024.png'),
	LOGO_BLACK: require('./logo_black_1024.png'),

	EX_TRUCK_FRONT: require('./truck_front_1.png'),
	EX_TRUCK_SIDE: require('./truck_side_1.png'),
	EX_TRUCK_BACK: require('./truck_back_1.png'),

	EX_NID_CARD: require('./nid_card.png'),
	EX_BANK_ACCOUNT: require('./bank_account.png'),
	EX_DRIVER_LICENSE: require('./driver_license.png'),


	ADD_BUTTON: require('./add_button_512.png'), 
	BOX: require('./box_512.png'),
	CANCEL: require('./cancel_512.png'),
	CHECK: require('./check_512.png'),
	CHECKLIST: require('./checklist_512.png'),
	CONTACT: require('./contact_512.png'),
	COURIER: require('./courier_512.png'),
	DIAMOND: require('./diamond_512.png'),
	EMAIL: require('./email_512.png'),
	FLAG: require('./flag_512.png'),
	LIKE: require('./like_512.png'),
	OPERATOR: require('./operator_512.png'),
	PAPER_PLANE: require('./paper_plane_512.png'),
	PDF: require('./pdf_512.png'),
	SETTINGS: require('./settings_512.png'),
	SHIPPED: require('./shipped_512.png'),
	SPEAKER: require('./speaker_512.png'),
	USER: require('./user_512.png'),
	WARNING: require('./warning_512.png'),

}

export { IMAGES };