//###------------- [PRE] ---------
const waitForVerify = { 
	code: 5, 
	name: "รอยืนยันผู้ประกาศ",
	bgColor: '#ffff00', 
	fontColor: '#000000',
};

//###------------- [POSTED] ---------
const available = { 
	code: 10, 
	name: "ว่าง", 
	bgColor: '#ffff00', 
	fontColor: '#000000'
};

//###------------- [WORKING] ---------

const accepted = { 
	code: 20, 
	name: "รอการขนส่ง", 
	bgColor: '#ffab40', 
	fontColor: '#000000'
};

//###------------- [COMPLETED] ---------

const completed = { 
	code: 40, 
	name: "ทำงานเสร็จเรียบร้อย",
	bgColor: '#ffab40', 
	fontColor: '#000000'
};

// const startDelivery = { code: 2, name: "เดินทางไปต้นทาง", bgColor: '#ff4081', fontColor: '#000000'};
// const arrivedOrigin = { code: 3, name: "ระหว่างการขนส่ง", bgColor: '#5e35b1', fontColor: '#ffffff'};
// const arrivedDestination = { code: 4, name: "ขนส่งเสร็จสิ้น", bgColor: '#3949ab', fontColor: '#ffffff'};
// const waitDeliveryDocs = { code: 5, name: "รอการเอกสารขนส่ง", bgColor: '#82b1ff', fontColor: '#000000'};
// const waitEmsReceipt = { code: 6, name: "รอเลข ems tracking", bgColor: '#1de9b6', fontColor: '#000000'};

const waitForDocument = { 
	code: 50, 
	name: "รอเอกสารขนส่ง", 
	bgColor: '#82b1ff', 
	fontColor: '#000000'
};

const gotAllDeliveryDoc = { 
	code: 51, 
	name: "รอการตรวจสอบเอกสาร", 
	bgColor: '#c6ff00', 
	fontColor: '#000000'
};
const paid = { 
	code: 52, 
	name: "จ่ายเงินเรียบร้อย", 
	bgColor: '#1b5e20', 
	fontColor: '#ffffff'
};

//###------------- [CANCEL] ---------

const cancelByUs = { 
	code: 90, 
	name: "งานยกเลิกจาก Logisty", 
	bgColor: '#ef7c82', 
	fontColor: '#000000'
};
const cancelByShipperAvailable = { 
	code: 99, 
	name: "งานยกเลิกจากshipperตอนงานว่าง", 
	bgColor: '#b71c1c', 
	fontColor: '#ffffff'
};
const cancelByShipperAccepted = { 
	code: 100, 
	name: "งานยกเลิกจากshipperงานมีคนรับแล้ว", 
	bgColor: '#b71c1c', 
	fontColor: '#ffffff'
};
const cancelByCarrier = { 
	code: 101, 
	name: "งานยกเลิกจากcarrier", 
	bgColor: '#b71c1c', 
	fontColor: '#ffffff'
};


const JOB_STATUS = {
	waitForVerify: waitForVerify,
	available: available,
	accepted: accepted,
	waitForDocument: waitForDocument,
	gotAllDeliveryDoc: gotAllDeliveryDoc,
	completed: completed,
	paid: paid,
	cancelByUs: cancelByUs,
	cancelByShipperAvailable: cancelByShipperAvailable,
	cancelByShipperAccepted: cancelByShipperAccepted,
	cancelByCarrier: cancelByCarrier,
};

const JOB_STATUS_ARRAY = [
	waitForVerify,
	available,
	accepted,
	waitForDocument,
	gotAllDeliveryDoc,
	completed,
	paid,
	cancelByUs,
	cancelByShipperAvailable,
	cancelByShipperAccepted,
	cancelByCarrier,
];

export { JOB_STATUS, JOB_STATUS_ARRAY };