import { USER_STATUS } from '../constants';
/**
*	NAME: convertShipperStatus
*	DESCRIPTION: convert shipper status to object to display
*	ARGUMENT: number
*	RETURN: object
*/
function convertUserStatus(statusCode){

	var ret = {};

	if(statusCode === USER_STATUS.waitingApprove.code){
		ret = USER_STATUS.waitingApprove.name;
	}
	else if(statusCode === USER_STATUS.approved.code){
		ret = USER_STATUS.approved.name;
	}
	else if(statusCode === USER_STATUS.banned.code){
		ret = USER_STATUS.banned.name;
	}
	

    return ret;
}

function userStatusCellRenderer(params){

	var fontColor = '#000000';
	var bgColor = '#ffffff';
	var valueShow = '?';

	//## [DEBUG]
	// console.log('userStatusCellRenderer');
	// console.log(params);

	if(params.value === USER_STATUS.waitingApprove.code){
		fontColor = USER_STATUS.waitingApprove.fontColor;
		bgColor = USER_STATUS.waitingApprove.bgColor;
		valueShow = USER_STATUS.waitingApprove.name;
	}
	else if(params.value === USER_STATUS.approved.code){
		fontColor = USER_STATUS.approved.fontColor;
		bgColor = USER_STATUS.approved.bgColor;
		valueShow = USER_STATUS.approved.name;
	}
	else if(params.value === USER_STATUS.banned.code){
		fontColor = USER_STATUS.banned.fontColor;
		bgColor = USER_STATUS.banned.bgColor;
		valueShow = USER_STATUS.banned.name;
	}
	else if(params.value === USER_STATUS.quiting.code){
		fontColor = USER_STATUS.quiting.fontColor;
		bgColor = USER_STATUS.quiting.bgColor;
		valueShow = USER_STATUS.quiting.name;
	}


	var ret = "<div style='width: 100%; height: 100%; text-align: center; "
	ret += "background-color:" + bgColor + "; "
	ret += "color: " + fontColor + ";' >";
	ret += valueShow + "</div>"


	return ret;

}

export { 
	convertUserStatus,
	userStatusCellRenderer, 
};


