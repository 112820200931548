import { NOTIF_STATUS } from '../constants';
/**
*	NAME: convertShipperStatus
*	DESCRIPTION: convert shipper status to object to display
*	ARGUMENT: number
*	RETURN: object
*/
function convertNotifStatus(notifStatusNum){

	var ret = {};

	if(notifStatusNum === NOTIF_STATUS.created.code){
		ret = NOTIF_STATUS.created.name;
	}
	else if(notifStatusNum === NOTIF_STATUS.read.code){
		ret = NOTIF_STATUS.read.name;
	}
	else if(notifStatusNum === NOTIF_STATUS.finished.code){
		ret = NOTIF_STATUS.finished.name;
	}
	

    return ret;
}

function notifStatusCellRenderer(params){

	var fontColor = '#000000';
	var bgColor = '#ffffff';
	var valueShow = '?';

	//## [DEBUG]
	// console.log('notifStatusCellRenderer');
	// console.log(params);

	if(params.value === NOTIF_STATUS.created.code){
		fontColor = NOTIF_STATUS.created.fontColor;
		bgColor = NOTIF_STATUS.created.bgColor;
		valueShow = NOTIF_STATUS.created.name;
	}
	else if(params.value === NOTIF_STATUS.read.code){
		fontColor = NOTIF_STATUS.read.fontColor;
		bgColor = NOTIF_STATUS.read.bgColor;
		valueShow = NOTIF_STATUS.read.name;
	}
	else if(params.value === NOTIF_STATUS.finished.code){
		fontColor = NOTIF_STATUS.finished.fontColor;
		bgColor = NOTIF_STATUS.finished.bgColor;
		valueShow = NOTIF_STATUS.finished.name;
	}


	var ret = "<div style='width: 100%; height: 100%; text-align: center; "
	ret += "background-color:" + bgColor + "; "
	ret += "color: " + fontColor + ";' >";
	ret += valueShow + "</div>"


	return ret;

}

export { 
	convertNotifStatus,
	notifStatusCellRenderer, 
};


