import { USERS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
} from '../../../constants';
import { USERS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { userServices } from '../../../api/services';



function requestGetPaginateUserList(page,limit,sortBy){
	return {
		type: USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_REQUEST
	}
}

function successGetPaginateUserList(userList,page,limit,sortBy){
	return {
		type: USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_SUCCESS,
		userList,
		page,
		limit,
		sortBy,
	}
}

function successGetPaginateUserListAddMore(userList,page,limit,sortBy){
	return {
		type: USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_SUCCESS_ADD_MORE,
		userList,
		page,
		limit,
		sortBy,
	}
}

function failureGetPaginateUserList(error){
	return {
		type: USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_FAILURE,
		error,
	}
}

function emptyGetPaginateUserList(error){
	return {
		type: USERS_ACTION_TYPES.GET_PAGINATE_USER_LIST_EMPTY,
		error,
	}
}

function getPaginateUserList(page, limit, sortBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestGetPaginateUserList());

    			//## Get Only Job below available JOb
    			let filterBy = {
    				
    			}

    			let select = null;

    			if(!sortBy){
    				sortBy = APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY;
    			}

    			let payload = {
    				page: page,
    				limit: limit,
    				filterBy: filterBy,
    				sortBy: sortBy,
    				select: select,
    			}

    			let responseJson = await userServices.getPaginateUserList(
						    				token,
						    				payload
						    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							// if(page === 0){
							// 	await dispatch(
							// 		successGetPaginateUserList(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }
							// else if(page > 0){
							// 	await dispatch(
							// 		successGetPaginateUserListAddMore(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }

							await dispatch(
								successGetPaginateUserList(
									responseJson.data,
									page,
									limit,
									sortBy,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyGetPaginateUserList(
		                			USERS_CONSTANTS.ERROR_FAILURE_PAGINATE_USER
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetPaginateUserList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetPaginateUserList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureGetPaginateUserList(
								USERS_CONSTANTS.ERROR_FAILURE_PAGINATE_USER
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureGetPaginateUserList(
							USERS_CONSTANTS.ERROR_FAILURE_PAGINATE_USER
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetPaginateUserList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetPaginateUserList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


//##-------------------------------------------------------------------------
function requestCountPaginateUserList(page,limit,sortBy){
	return {
		type: USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_REQUEST
	}
}

function successCountPaginateUserList(userListTotalNum){
	return {
		type: USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_SUCCESS,
		userListTotalNum,
	}
}

function failureCountPaginateUserList(error){
	return {
		type: USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_FAILURE,
		error,
	}
}

function emptyCountPaginateUserList(error){
	return {
		type: USERS_ACTION_TYPES.COUNT_PAGINATE_USER_LIST_EMPTY,
		error,
	}
}

function countPaginateUserList(filterBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestCountPaginateUserList());

    			//## Get Only Job below available JOb



    			let responseJson = await userServices
    			.getCountTotalUserNum(
					token,						    				
    				filterBy
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){

							await dispatch(
								successCountPaginateUserList(
									responseJson.data
								)
							);

							return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyCountPaginateUserList(
		                			USERS_CONSTANTS.ERROR_EMPTY_COUNT_PAGINATE_USER
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureCountPaginateUserList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureCountPaginateUserList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureCountPaginateUserList(
								USERS_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_USER
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureCountPaginateUserList(
							USERS_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_USER
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(failureCountPaginateUserList(error.message));
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureCountPaginateUserList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


export const userListActions = {

	requestGetPaginateUserList,
	successGetPaginateUserList,
	successGetPaginateUserListAddMore,
	failureGetPaginateUserList,
	emptyGetPaginateUserList,
	getPaginateUserList,


	requestCountPaginateUserList,
	successCountPaginateUserList,
	failureCountPaginateUserList,
	emptyCountPaginateUserList,
	countPaginateUserList,


}