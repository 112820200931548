
const company = {
	name: "บริษัท",
	db: "company",
	bgColor: '#0000FF',
	fontColor: '#ffffff',

};
const individual = {
	name: "บุคคลธรรมดา", 
	db: "individual",
	bgColor: '#00FFFF',
	fontColor: '#000000',
};


const mapping = {
	company: "บริษัท",
	individual: "บุคคลธรรมดา",
}


const COMPANY_TYPE = {
	company: company,
	individual: individual,

	mapping: mapping,

};
export { COMPANY_TYPE };