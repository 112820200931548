export const TRUCKS_ACTION_TYPES = {

	GET_ALL_TRUCK_TYPE_LIST_REQUEST: 'GET_ALL_TRUCK_TYPE_LIST_REQUEST',
	GET_ALL_TRUCK_TYPE_LIST_SUCCESS: 'GET_ALL_TRUCK_TYPE_LIST_SUCCESS',
	GET_ALL_TRUCK_TYPE_LIST_FAILURE: 'GET_ALL_TRUCK_TYPE_LIST_FAILURE',
	GET_ALL_TRUCK_TYPE_LIST_EMPTY: 'GET_ALL_TRUCK_TYPE_LIST_EMPTY',

	UPDATE_ONE_TRUCK_TYPE_INFO_REQUEST: 'UPDATE_ONE_TRUCK_TYPE_INFO_REQUEST',
	UPDATE_ONE_TRUCK_TYPE_INFO_SUCCESS: 'UPDATE_ONE_TRUCK_TYPE_INFO_SUCCESS',
	UPDATE_ONE_TRUCK_TYPE_INFO_FAILURE: 'UPDATE_ONE_TRUCK_TYPE_INFO_FAILURE',

	
	GET_PAGINATE_TRUCK_LIST_REQUEST: 'GET_PAGINATE_TRUCK_LIST_REQUEST',
	GET_PAGINATE_TRUCK_LIST_SUCCESS: 'GET_PAGINATE_TRUCK_LIST_SUCCESS',
	GET_PAGINATE_TRUCK_LIST_SUCCESS_ADD_MORE: 'GET_PAGINATE_TRUCK_LIST_SUCCESS_ADD_MORE',
	GET_PAGINATE_TRUCK_LIST_FAILURE: 'GET_PAGINATE_TRUCK_LIST_FAILURE',
	GET_PAGINATE_TRUCK_LIST_EMPTY: 'GET_PAGINATE_TRUCK_LIST_EMPTY',


	COUNT_PAGINATE_TRUCK_LIST_REQUEST: 'COUNT_PAGINATE_TRUCK_LIST_REQUEST',
	COUNT_PAGINATE_TRUCK_LIST_SUCCESS: 'COUNT_PAGINATE_TRUCK_LIST_SUCCESS',
	COUNT_PAGINATE_TRUCK_LIST_FAILURE: 'COUNT_PAGINATE_TRUCK_LIST_FAILURE',
	COUNT_PAGINATE_TRUCK_LIST_EMPTY: 'COUNT_PAGINATE_TRUCK_LIST_EMPTY',

	UPDATE_ONE_TRUCK_INFO_REQUEST: 'UPDATE_ONE_TRUCK_INFO_REQUEST',
	UPDATE_ONE_TRUCK_INFO_SUCCESS: 'UPDATE_ONE_TRUCK_INFO_SUCCESS',
	UPDATE_ONE_TRUCK_INFO_FAILURE: 'UPDATE_ONE_TRUCK_INFO_FAILURE',

	//## Truck Cargo Insurance Document
	GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_REQUEST: 'GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_REQUEST',
	GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_SUCCESS: 'GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_SUCCESS',
	GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_FAILURE: 'GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_FAILURE',
	GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_EMPTY: 'GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_EMPTY',


	DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_REQUEST: 'DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_REQUEST',
	DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_SUCCESS: 'DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_SUCCESS',
	DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_FAILURE: 'DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_FAILURE',

}