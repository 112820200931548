import axios from 'axios';
import { API_CONSTANTS } from '../../api';


/**
*   NAME: getPaginateCompanyList
*   DESCRIPTION: get paginate company list
*   METHOD: POST
*/
async function getPaginateCompanyList(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_LIST_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getPaginateCompanyList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: getCountTotalCompanyNum
*   DESCRIPTION: count total company list
*   METHOD: POST
*/
async function getCountTotalCompanyNum(token,filterBy) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_COUNT_TOTAL_NUM_URL,
        method: 'POST',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            filterBy: filterBy,
        }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getCountTotalCompanyNum Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: putUpdateOneCompanyInfo
*   DESCRIPTION: update company info
*   METHOD: PUT
*/
async function putUpdateOneCompanyInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_UPDATE_INFO_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateOneCompanyInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: putUpdateOneCompanyBadgeInfo
*   DESCRIPTION: update one company badge info
*   METHOD: PUT
*/
async function putUpdateOneCompanyBadgeInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_UPDATE_BADGE_INFO_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateOneCompanyBadgeInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: getOneCompanyVerifyDocList
*   DESCRIPTION: get One Company Verify list
*   METHOD: GET
*/
async function getOneCompanyVerifyDocList(token,companyId) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_GET_ALL_VERIFY_DOC_URL + "/" + companyId,
        method: 'GET',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        // data: JSON.stringify({
        //     filterBy: filterBy,
        // }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getOneCompanyVerifyDocList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: getOneCompanyVerifyDocList
*   DESCRIPTION: get One Company Verify list
*   METHOD: GET
*/
async function downloadOneCompanyVerifyPdf(token,companyId,docId) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_GET_ONE_VERIFY_DOC_PDF_URL,
        method: 'POST',
        responseType: 'blob',
        headers: { 
            'Accept': 'application/pdf',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            companyId: companyId,
            docId: docId,
        }),
    };


    try {

        let response = await axios.request(requestOptions);

        return response;
    }
    catch (error){
        //## [DEBUG]
        console.error("downloadOneCompanyVerifyPdf Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}



/**
*   NAME: getOneCompanyBankAccountDocList
*   DESCRIPTION: get One Company Verify list
*   METHOD: GET
*/
async function getOneCompanyBankAccountDocList(token,companyId) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_GET_ALL_BANK_ACCOUNT_DOC_URL + "/" + companyId,
        method: 'GET',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        // data: JSON.stringify({
        //     filterBy: filterBy,
        // }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getOneCompanyBankAccountDocList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: getOneCompanyBankAccountDocList
*   DESCRIPTION: get One Company Verify list
*   METHOD: GET
*/
async function downloadOneCompanyBankAccountPdf(token,companyId,docId) {
    const requestOptions = {
        url: API_CONSTANTS.COMPANY_GET_ONE_BANK_ACCOUNT_DOC_PDF_URL,
        method: 'POST',
        responseType: 'blob',
        headers: { 
            'Accept': 'application/pdf',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            companyId: companyId,
            docId: docId,
        }),
    };


    try {

        let response = await axios.request(requestOptions);

        return response;
    }
    catch (error){
        //## [DEBUG]
        console.error("downloadOneCompanyBankAccountPdf Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: deleteOneCompanyInfo
*   DESCRIPTION: delete one Company Info
*   METHOD: GET
*/
async function deleteOneCompanyInfo(token,payload){

    const requestOptions = {
        url: API_CONSTANTS.COMPANY_DELETE_ONE_INFO_URL,
        method: 'DELETE',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("deleteOneCompanyInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

export const companyServices = {
    getPaginateCompanyList,
    getCountTotalCompanyNum,
    putUpdateOneCompanyInfo,
    putUpdateOneCompanyBadgeInfo,

    getOneCompanyVerifyDocList,
    downloadOneCompanyVerifyPdf,

    getOneCompanyBankAccountDocList,
    downloadOneCompanyBankAccountPdf,

    deleteOneCompanyInfo,
};