import axios from 'axios';
import { API_CONSTANTS } from '../../api';


/**
*   NAME: getPaginateDriverList
*   DESCRIPTION: get paginate company list
*   METHOD: POST
*/
async function getPaginateDriverList(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.DRIVER_LIST_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getPaginateDriverList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: getCountTotalDriverNum
*   DESCRIPTION: count total company list
*   METHOD: POST
*/
async function getCountTotalDriverNum(token,filterBy) {
    const requestOptions = {
        url: API_CONSTANTS.DRIVER_COUNT_TOTAL_NUM_URL,
        method: 'POST',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            filterBy: filterBy,
        }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getCountTotalDriverNum Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: putUpdateOneDriverInfo
*   DESCRIPTION: get paginate company list
*   METHOD: PUT
*/
async function putUpdateOneDriverInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.DRIVER_UPDATE_INFO_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateOneDriverInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: getOneDriverLicenseDocList
*   DESCRIPTION: get One Driver Verify list
*   METHOD: GET
*/
async function getOneDriverLicenseDocList(token,driverId) {
    const requestOptions = {
        url: API_CONSTANTS.DRIVER_GET_ALL_LICENSE_DOC_URL + "/" + driverId,
        method: 'GET',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        // data: JSON.stringify({
        //     filterBy: filterBy,
        // }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getOneDriverLicenseDocList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: getOneDriverLicenseDocList
*   DESCRIPTION: get One Driver Verify list
*   METHOD: GET
*/
async function downloadOneDriverLicensePdf(token,driverId,docId) {
    const requestOptions = {
        url: API_CONSTANTS.DRIVER_GET_ONE_LICENSE_DOC_PDF_URL,
        method: 'POST',
        responseType: 'blob',
        headers: { 
            'Accept': 'application/pdf',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,
            
            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            driverId: driverId,
            docId: docId,
        }),
    };


    try {

        let response = await axios.request(requestOptions);

        return response;
    }
    catch (error){
        //## [DEBUG]
        console.error("downloadOneDriverLicensePdf Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}





export const driverServices = {
    getPaginateDriverList,
    getCountTotalDriverNum,
    putUpdateOneDriverInfo,

    getOneDriverLicenseDocList,
    downloadOneDriverLicensePdf,

};