import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLine } from '@fortawesome/free-brands-svg-icons';

// import axios from 'axios';

// import ApiConstant from '../constant/ApiConstant';
// import {TYPES} from '../types'

import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';

import { navActions } from '../../nav/actions';

import { loginActions } from '../../auth/actions';

import { withMultipleStyles } from '../../../utilities';

import { 
    overlaySpacing,
    layoutMuiStyles, 
} from '../../../styles';
import { loginMuiStyles } from '../styles';








class LoginPage extends React.Component {

    //## Initial State
    state = {
        isLogin: false,
        redirectFrom: null,
        redirectToReferrer: false,

        username: '',
        isValidUsername: false,

        password: '',
        isValidPassword: false,

        isValidAll: false,

        openConfirmLoginDialog: false, 
    }

    constructor(props){
        super(props);

        

        //## Binding


        this.onPageFocus = this.onPageFocus.bind(this);
        this.onGetRedirectFrom = this.onGetRedirectFrom.bind(this);

        this.onUserNameChange = this.onUserNameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);

        this.onClickLogin = this.onClickLogin.bind(this);
        this.onLogin = this.onLogin.bind(this);

        this.handleOpenConfirmLoginDialog = this.handleOpenConfirmLoginDialog.bind(this);
        this.handleCloseConfirmLoginDialog = this.handleCloseConfirmLoginDialog.bind(this);

    }

    

    async componentDidMount(){
        const { dispatch } = this.props;
        let token = await localStorage.getItem('token');

        if(token){
            await dispatch(navActions.navigateToDashboardPage());
        }

        // if(
        //     this.props.subscribedNotification === false
        //     && process.env.REACT_APP_ENV !== 'development'
        // ){
        //     await dispatch(spinnerActions.showSpinner());
        // }
        
    }

    async componentDidUpdate(prevProps, prevState){
        // const { dispatch } = this.props;
        if(
            prevState.isValidUsername !== this.state.isValidUsername
            || prevState.isValidPassword !== this.state.isValidPassword
        ){
            await this.onCheckUnlockAll();
        }

        // if(prevProps.subscribedNotification !== this.props.subscribedNotification){
        //     if(this.props.subscribedNotification === true){
        //         await dispatch(spinnerActions.hideSpinner());
        //     }
        // }
    }

    //##-----------------------------------------------------------------



    //##-----------------------------------------------------------------


    onPageFocus() {
        const { dispatch } = this.props;
        dispatch(navActions.focusLoginPage());
    }



    async onGetRedirectFrom(): Promise<void>{
        //## [DEBUG]
        console.log("the path name is ",this.props.location.pathname);

        // const { dispatch } = this.props;

        let { from } = this.props.location.state || { from: { pathname: "/jobs" } };


        let currentUrl = from.pathname;

        //## Save new to localStorage and Redux store
        await localStorage.setItem('starterUrl',currentUrl);
        // await dispatch(navActions.saveStarterUrl(currentUrl));


        return Promise.resolve();
    }

    //##-------------------------------------------------------------------

    onUserNameChange = (event) => {
        let text = event.target.value;
        this.setState({
            username: text,
        });
        if(text){
            this.setState({
                isValidUsername: true
            })
        }
        else{
            this.setState({
                isValidUsername: false
            })
        }
    };

    onPasswordChange = (event) => {
        let text = event.target.value;
        this.setState({
            password: text
        })
        if(text){
            this.setState({
                isValidPassword: true
            })
        }
        else{
            this.setState({
                isValidPassword: false
            })
        }
    }

    onCheckUnlockAll() {
        let {
            isValidUsername,
            isValidPassword,
        } = this.state;

        if(
            isValidUsername
            && isValidPassword
        ){
            this.setState({
                isValidAll: true,
            })
        }
        else{
            this.setState({
                isValidAll: false,
            })
        }
    }

    //##-------------------------------------------------------------------
    onKeyPressLogin = (event) => {
        //## [DEBUG]
        // console.log(`Pressed keyCode ${event.key}`);

        if (event.key === 'Enter') {

            //## [DEBUG]
            console.log(`Pressed keyCode ${event.key}`);

            // Do code here
            event.preventDefault();

            this.onClickLogin(event);
        }

    }


    async onClickLogin(event): Promise<void>  {

        const { 
            dispatch,
            // subscribedNotification 
        } = this.props;

        let {
            isValidAll
        } = this.state;

        // if(subscribedNotification === true){
            if(isValidAll){
                await this.onLogin();
            }
            else{
                dispatch(alertActions.showAlert(
                        'เกิดข้อผิดพลาด',
                        'กรอกข้อมูลไม่ครบ',
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )
            }
        // }
        // else{
        //     await this.handleOpenConfirmLoginDialog();
        // }

        return Promise.resolve();
    }

    async onLogin(): Promise<void>  {

        const { 
            dispatch,

        } = this.props;

        let {
            username,
            password,
        } = this.state;

        //## {DEBUG}
        // console.log('Start Login Process')

        
        //## login Action
        await dispatch(spinnerActions.showSpinner());

        let token = await dispatch(
            loginActions.login(
                username,
                password,
            )
        );

        await dispatch(spinnerActions.hideSpinner());

        //## Navigation to Dashboard
        if(token){
            dispatch(navActions.navigateToDashboardPage());
        }
        

        return Promise.resolve();
    }


    

    //##-----------------------------------------------------------
    handleOpenConfirmLoginDialog = () => {
        this.setState({
            openConfirmLoginDialog: true,
        })
    }

    handleCloseConfirmLoginDialog = async(isOkToLogin) => {

        //## [DEBUG]
        console.log('isOkToLogin: ' + isOkToLogin);

        const {
            dispatch
        } = this.props;

        let {
            isValidAll
        } = this.state;

        await this.setState({
            openConfirmLoginDialog: false,
        })

        if(isOkToLogin){
            if(isValidAll){
                await this.onLogin();
            }
            else{
                await dispatch(alertActions.showAlert(
                        'เกิดข้อผิดพลาด',
                        'กรอกข้อมูลไม่ครบ',
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )
            }
        }

        return Promise.resolve();
    }

    //##-----------------------------------------------------------


    render() {
        const { classes } = this.props;
        
        return (
            <div 
                className={classes.loginFrame}
            >
                <Grid container spacing={overlaySpacing}>
                    <Grid item xs={12}>
                        <div 
                            className={classNames(
                                classes.loginTitle, 
                            )}
                        >
                            {'Logisty Admin (v0.5)'}
                        </div>
                        <div 
                            className={classNames(
                                classes.loginSubtitle, 
                            )}
                        >
                            {'(C) Copyright reserved by logisty.asia'}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.divider} />
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={false} sm={false} md={1} lg={3} />
                            <Grid item xs={12} sm={12} md={10} lg={6} >   
                                <TextField 
                                    margin="normal"
                                    label="Username" 
                                    value={this.state.username}
                                    onChange={this.onUserNameChange} 
                                    onKeyPress={(event) => this.onKeyPressLogin(event)}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={false} sm={false} md={1} lg={3} />
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={false} sm={false} md={1} lg={3} />
                            <Grid item xs={12} sm={12} md={10} lg={6} >    
                                <TextField  
                                    type="password" 
                                    label="Password"
                                    margin="normal"
                                    value={this.state.password}
                                    onChange ={this.onPasswordChange} 
                                    onKeyPress={(event) => this.onKeyPressLogin(event)}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={false} sm={false} md={1} lg={3} />
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={24}
                        >
                            
                            <Grid item xs={6} /> 
                            <Grid item xs={6}>    
                                <Typography 
                                    variant="subtitle1" 
                                    gutterBottom
                                >
                                    {
                                        this.props.subscribedNotification
                                        ?
                                        'Subscribe Notification'
                                        :
                                        'Unsubscribe Notification'
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>    
                                <Button 
                                    disabled={!this.state.isValidAll}
                                    onClick={(event) => this.onClickLogin(event)}
                                >
                                    Login
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.openConfirmLoginDialog}
                    onClose={this.handleCloseConfirmLogin}
                    aria-labelledby="confirm-login-title"
                    aria-describedby="confirm-login-description"
                >
                    <DialogTitle id="confirm-login-title">
                        {"ยังไม่ได้รับการเปิด Notification"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="confirm-login-description">
                            {"คุณแน่ใจที่ Login เลยทั้งที่ไม่ได้เปิด Notification ?"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={() => this.handleCloseConfirmLoginDialog(false)} 
                            color="primary"
                        >
                            {'ไม่'}
                        </Button>
                        <Button 
                            onClick={() => this.handleCloseConfirmLoginDialog(true)} 
                            color="primary" 
                            autoFocus
                        >
                            {'ตกลง'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
        
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({

    token: state.auth.token,
    isLogin: state.auth.isLogin,

    subscribedNotification: state.notif.subscribedNotification,

})

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles,
    loginMuiStyles
)(LoginPage);

const connectedLoginPageContainers = connect(mapStateToProps)(
    injectedStylesContainers
);
export { connectedLoginPageContainers  as LoginPage };