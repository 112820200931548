export const APP_CONSTANTS = {
	ERROR_UNAUTHORIZED_REQUEST: 'Error unauthorized request',

	OSG_NOTIF_PROMPT_ACTION_MESSAGE: "ขออนุญาติแสดงการแจ้งเตือนจากทางเวป เพื่อรับข้อมูลข่าวสารใหม่ๆ",
	OSG_NOTIF_PROMPT_ACCEPT_BTN_TEXT: "อนุญาติ",
	OSG_NOTIF_PROMPT_CANCEL_BTN_TEXT: "ไม่",

	ERROR_DIALOG_TITLE: 'เกิดข้อผิดพลาด',
	SUCCESS_DIALOG_TITLE: 'สำเร็จ',
	SUCCESS_EDIT_MESSAGE: 'แก้ไขข้อมูลสำเร็จ',

	DOCUMENT_UPLOAD_MAXIMUM_FILE_SIZE: 10000000,
	DOCUMENT_FILE_SIZE_EXCEED_MAXIMUM_ALERT_TITLE: 'ขนาดไฟล์มีปัญหา',
	DOCUMENT_FILE_SIZE_EXCEED_MAXIMUM_ALERT_BODY: 'ขนาดไฟล์ใหญ่เกิน 10 MB ไม่สามารถอัพโหลดได้',
	DOCUMENT_FILE_PICKER_NOT_SELECTED: 'คุณไม่ได้เลือกไฟล์',
	DOCUMENT_CONTENT_TYPE: 'application/pdf',

	IMAGE_CONTENT_TYPE: 'image/jpeg',


	DEFAULT_PAGINATION_PAGE_SIZE: 20,
	DEFAULT_PAGINATION_SORT_BY: {
		createdAt: -1
	},
	DEFAULT_PAGINATION_ROW_PER_PAGE_ARRAY: [
		1,
		2,
		10,
		20,
		30,
		50,
		80,
		100,
		200,
		300,
		500,
	],
	
}