import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import classNames from 'classnames';



import Lightbox from 'react-image-lightbox';
import "../../../assets/css/lightbox.css";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { 
//     faEye,

// } from '@fortawesome/free-solid-svg-icons';



import { 
    withMultipleStyles,
} from '../../../utilities';

import { 
    layoutMuiStyles,
    THEME, 
} from '../../../styles';

import { lightboxActions } from '../../lightbox/actions';



class LightBoxContainers extends React.Component {

    state = {
        
    }

    constructor(props) {
        super(props);


        this.onOpenLightBox = this.onOpenLightBox.bind(this);
        this.onCloseLightBox = this.onCloseLightBox.bind(this);
        this.onSetPhotoIndex = this.onSetPhotoIndex.bind(this);
    }

    

    componentDidMount(){

    }


    componentDidUpdate(prevProps,prevState){

    }

    


    onOpenLightBox = () => {
        const { dispatch } = this.props;

        dispatch(lightboxActions.openLightbox());
    }

    onCloseLightBox = () => {
        const { dispatch } = this.props;

        dispatch(lightboxActions.closeLightbox());
    }

    onSetPhotoIndex = (value) => {
        const { dispatch } = this.props;

        dispatch(lightboxActions.setLightboxPhotoIndex(value));
    }




    render() {

        let { 

            isOpen,
            images, 
            photoIndex,
        } = this.props;


        if(isOpen){
            return (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    imageTitle={photoIndex + 1 + "/" + images.length}
                    onCloseRequest={() => this.onCloseLightBox()}
                    onMovePrevRequest={() =>
                        this.onSetPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        this.onSetPhotoIndex((photoIndex + 1) % images.length)
                    }
                    reactModalStyle={{
                        overlay: {
                            zIndex: THEME.lightBoxZIndex
                        }
                    }}
                />
            )
        }
        else{ 
            return (
                <div></div>
            );
        }
    }
};



const mapStateToProps = (state) => ({
    isOpen: state.lightbox.isOpen,
    images: state.lightbox.images,
    photoIndex: state.lightbox.photoIndex,

})


LightBoxContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,


}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(LightBoxContainers);


const connectedContainers = connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as LightBoxContainers };