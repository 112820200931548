import { ADMIN_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
} from '../../../constants';
import { ADMIN_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { adminCompanyStatusServices } from '../../../api/services';



function requestGetAllAdminCompanyStatusList(){
	return {
		type: ADMIN_ACTION_TYPES.GET_ALL_ADMIN_COMPANY_STATUS_LIST_REQUEST
	}
}

function successGetAllAdminCompanyStatusList(adminCompanyStatusList){
	return {
		type: ADMIN_ACTION_TYPES.GET_ALL_ADMIN_COMPANY_STATUS_LIST_SUCCESS,
		adminCompanyStatusList,
	}
}



function failureGetAllAdminCompanyStatusList(error){
	return {
		type: ADMIN_ACTION_TYPES.GET_ALL_ADMIN_COMPANY_STATUS_LIST_FAILURE,
		error,
	}
}


function getAllAdminCompanyStatusList(){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestGetAllAdminCompanyStatusList());




    			let responseJson = await adminCompanyStatusServices.getAllAdminCompanyStatus(
						    				token
						    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							

							await dispatch(
								successGetAllAdminCompanyStatusList(
									responseJson.data,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		failureGetAllAdminCompanyStatusList(
		                			ADMIN_CONSTANTS.ERROR_FAILURE_ALL_ADMIN_COMPANY_STATUS
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetAllAdminCompanyStatusList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetAllAdminCompanyStatusList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureGetAllAdminCompanyStatusList(
								ADMIN_CONSTANTS.ERROR_FAILURE_ALL_ADMIN_COMPANY_STATUS
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureGetAllAdminCompanyStatusList(
							ADMIN_CONSTANTS.ERROR_FAILURE_ALL_ADMIN_COMPANY_STATUS
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetAllAdminCompanyStatusList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetAllAdminCompanyStatusList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


//##-------------------------------------------------------------------------
function requestCreateNewAdminCompanyStatus(){
	return {
		type: ADMIN_ACTION_TYPES.CREATE_NEW_ADMIN_COMPANY_STATUS_REQUEST
	}
}

function successCreateNewAdminCompanyStatus(newAdminCompanyStatusData){
	return {
		type: ADMIN_ACTION_TYPES.CREATE_NEW_ADMIN_COMPANY_STATUS_SUCCESS,
		newAdminCompanyStatusData,
	}
}



function failureCreateNewAdminCompanyStatus(error){
	return {
		type: ADMIN_ACTION_TYPES.CREATE_NEW_ADMIN_COMPANY_STATUS_FAILURE,
		error,
	}
}


function createNewAdminCompanyStatus(newData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestCreateNewAdminCompanyStatus());

    			let payload = {
    				name: newData.name,
    				remark: newData.remark,
    				category: newData.category,
    			}


    			let responseJson = await adminCompanyStatusServices
    				.createNewAdminCompanyStatus(
	    				token,
	    				payload
	    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							

							await dispatch(
								successCreateNewAdminCompanyStatus(
									responseJson.data,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		failureCreateNewAdminCompanyStatus(
		                			ADMIN_CONSTANTS.ERROR_FAILURE_CREATE_NEW_COMPANY_STATUS
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureCreateNewAdminCompanyStatus(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureCreateNewAdminCompanyStatus(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureCreateNewAdminCompanyStatus(
								ADMIN_CONSTANTS.ERROR_FAILURE_CREATE_NEW_COMPANY_STATUS
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureCreateNewAdminCompanyStatus(
							ADMIN_CONSTANTS.ERROR_FAILURE_CREATE_NEW_COMPANY_STATUS
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureCreateNewAdminCompanyStatus(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureCreateNewAdminCompanyStatus(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##-------------------------------------------------------------------------
function requestUpdateAdminCompanyStatus(adminCompanyStatusId){
	return {
		type: ADMIN_ACTION_TYPES.UPDATE_ADMIN_COMPANY_STATUS_REQUEST,
		adminCompanyStatusId
	}
}

function successUpdateAdminCompanyStatus(updatedAdminCompanyStatusData){
	return {
		type: ADMIN_ACTION_TYPES.UPDATE_ADMIN_COMPANY_STATUS_SUCCESS,
		updatedAdminCompanyStatusData,
	}
}



function failureUpdateAdminCompanyStatus(error){
	return {
		type: ADMIN_ACTION_TYPES.UPDATE_ADMIN_COMPANY_STATUS_FAILURE,
		error,
	}
}


function updateAdminCompanyStatus(newData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestUpdateAdminCompanyStatus(newData.adminCompanyStatusId));

    			let payload = {
    				adminCompanyStatusId: newData.adminCompanyStatusId,
    				name: newData.name,
    				remark: newData.remark,
    				category: newData.category,
    			}


    			let responseJson = await adminCompanyStatusServices
    				.updateAdminCompanyStatus(
	    				token,
	    				payload
	    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							

							await dispatch(
								successUpdateAdminCompanyStatus(
									responseJson.data,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		failureUpdateAdminCompanyStatus(
		                			ADMIN_CONSTANTS.ERROR_FAILURE_UPDATE_COMPANY_STATUS
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureUpdateAdminCompanyStatus(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureUpdateAdminCompanyStatus(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureUpdateAdminCompanyStatus(
								ADMIN_CONSTANTS.ERROR_FAILURE_UPDATE_COMPANY_STATUS
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureUpdateAdminCompanyStatus(
							ADMIN_CONSTANTS.ERROR_FAILURE_UPDATE_COMPANY_STATUS
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureUpdateAdminCompanyStatus(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureUpdateAdminCompanyStatus(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##-------------------------------------------------------------------------
function requestDeleteAdminCompanyStatus(adminCompanyStatusId){
	return {
		type: ADMIN_ACTION_TYPES.DELETE_ADMIN_COMPANY_STATUS_REQUEST,
		adminCompanyStatusId
	}
}

function successDeleteAdminCompanyStatus(updatedAdminCompanyStatusData){
	return {
		type: ADMIN_ACTION_TYPES.DELETE_ADMIN_COMPANY_STATUS_SUCCESS,
		updatedAdminCompanyStatusData,
	}
}



function failureDeleteAdminCompanyStatus(error){
	return {
		type: ADMIN_ACTION_TYPES.DELETE_ADMIN_COMPANY_STATUS_FAILURE,
		error,
	}
}


function deleteAdminCompanyStatus(adminCompanyStatusId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestDeleteAdminCompanyStatus(adminCompanyStatusId));

    			let payload = {
    				adminCompanyStatusId: adminCompanyStatusId

    			}


    			let responseJson = await adminCompanyStatusServices
    				.deleteCompanyStatus(
	    				token,
	    				payload
	    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
							successDeleteAdminCompanyStatus(
								
							)
						);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureDeleteAdminCompanyStatus(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureDeleteAdminCompanyStatus(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureDeleteAdminCompanyStatus(
								ADMIN_CONSTANTS.ERROR_FAILURE_DELETE_COMPANY_STATUS
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureDeleteAdminCompanyStatus(
							ADMIN_CONSTANTS.ERROR_FAILURE_DELETE_COMPANY_STATUS
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureDeleteAdminCompanyStatus(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureDeleteAdminCompanyStatus(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}



//##-------------------------------------------------------------------------


export const adminCompanyStatusActions = {

	requestGetAllAdminCompanyStatusList,
	successGetAllAdminCompanyStatusList,
	failureGetAllAdminCompanyStatusList,
	getAllAdminCompanyStatusList,


	requestCreateNewAdminCompanyStatus,
	successCreateNewAdminCompanyStatus,
	failureCreateNewAdminCompanyStatus,
	createNewAdminCompanyStatus,

	requestUpdateAdminCompanyStatus,
	successUpdateAdminCompanyStatus,
	failureUpdateAdminCompanyStatus,
	updateAdminCompanyStatus,


	requestDeleteAdminCompanyStatus,
	successDeleteAdminCompanyStatus,
	failureDeleteAdminCompanyStatus,
	deleteAdminCompanyStatus,


}