import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';



import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSave,
    faChevronRight,
    faChevronLeft,

} from '@fortawesome/free-solid-svg-icons';

import { 
	withMultipleStyles,
	extractValues,
	hasDocRenderer,
	extractObjectByKey,
	lookupValue,
	// lookupKey,
	companyStatusCellRenderer,
	companyTypeCellRenderer,
	getDateTimeColumnType,
	adminCompanyStatusRenderer,

} from '../../../utilities';
import { 
	layoutMuiStyles,

} from '../../../styles';
import { 
	APP_CONSTANTS,
	COMPANY_STATUS,
	COMPANY_TYPE, 
	BANK_NAME,
} from '../../../constants';

import {
	CompanyVerifyDocumentRenderer,
	CompanyVerifyDocumentDialog,

	CompanyBankAccountDocumentRenderer,
	CompanyBankAccountDocumentDialog,

	CompanyBadgeEditRenderer,
	CompanyBadgeEditDialog,

	DeleteOneCompanyButtonRenderer,
} from '../containers';

import {
	companyListActions,
	editCompanyActions,
	deleteCompanyActions,
} from '../actions';

import {

	editUserActions,
} from '../../users/actions';

import {
	adminCompanyStatusActions,

} from '../../admin/actions';

import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';



class CompanyListTableContainers extends React.Component {

	state = {

		isPrefetched: false,

		//##---------------- Table Pagination ---------------------------

		page: 0,
		limit: APP_CONSTANTS.DEFAULT_PAGINATION_PAGE_SIZE,
		sortBy: APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY,
		filterBy: {},

		//## Total Page possible to show
		totalPage: 0,
		rowPerPageArray: APP_CONSTANTS.DEFAULT_PAGINATION_ROW_PER_PAGE_ARRAY,

		disabledPrevPageBtn: true,
		disabledNextPageBtn: false,

		adminCompanyStatusList: [],
		

		//##---------------- agGridReact ---------------------------

		context: { componentParent: this },
		frameworkComponents: {
	        companyVerifyDocumentRenderer: CompanyVerifyDocumentRenderer,
	        companyBankAccountDocumentRenderer: CompanyBankAccountDocumentRenderer,
	        companyBadgeEditRenderer: CompanyBadgeEditRenderer,
	        deleteOneCompanyButtonRenderer: DeleteOneCompanyButtonRenderer,
	    },

		defaultColDef: {
			// set every column width
	        width: 100,
	        // make every column editable
	        editable: true,
	        // make every column use 'text' filter by default
	        filter: 'agTextColumnFilter',
	        //## Column resizable
	        resizable: true,	
	        sortable: true,
		},
		columnTypes: {
			nonEditableColumn: {editable: false},
	        dateColumn: getDateTimeColumnType()
		},

		columnDefs: [

			{
				field: "cid",
				headerName: "Company Id",
				width: 150, 
				editable: true,
				// pinned: 'left',
			},
			{
				field: "carrierStatus",
				headerName: "สถานะ [0=waiting/1=approved/2=banned]", 
				width: 200,

				//## Cell Editor Mode
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
				    values: extractValues(COMPANY_STATUS.mapping),
				    cellRenderer: (params) => companyTypeCellRenderer(params),
				},
				
				
				//## See README.md part tutorial for more information
				
				//## Use a "valueFormatter" to Format Select Input 
				valueFormatter: function (params) {

					//## [DEBUG]
					// console.log('valueFormatter')
			    	// console.log(params);

			    	var code = parseInt(params.value,10);

			        // convert code to value
			        return lookupValue(COMPANY_STATUS.mapping, code);
			    },

			    //## Convert String to int for save data
			    valueSetter: function (params) {

					//## [DEBUG]
					// console.log('valueSetter')
			    	// console.log(params);

			    	if (params.oldValue!==params.newValue) {
				        params.data['carrierStatus'] = parseInt(params.newValue,10);
				        // get grid to refresh the cell
				        return true;
				    } else {
				        // no change, so no refresh needed
				        return false;
				    }
			    },
			    //## Use to render view output html 
			    cellRenderer: (params) => companyStatusCellRenderer(params),

			    //# [Enterprise feature NEED]
			    // filter: 'agSetColumnFilter',
            	// filterParams: {
             //    	values: extractValues(COMPANY_STATUS.mapping),
             //    	cellRenderer: (params) => companyStatusCellRenderer(params)
            	// },
			},
			{
				field: "type",
				headerName: "ชนิด", 
				width: 200,
				//## Cell Editor Mode
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
				    values: extractValues(COMPANY_TYPE.mapping),
				    cellRenderer: (params) => companyTypeCellRenderer(params),
				},


				//## Use to Format Select Input 
				valueFormatter: function (params) {

			        // convert code to value
			        return lookupValue(COMPANY_TYPE.mapping, params.value);
			    },
				
				//## Use to render view output html 	
			    cellRenderer: (params) => companyTypeCellRenderer(params),

			},
			{
				field: "email",
				headerName: "email",
				width: 200, 
				editable: true,
			},
			{
				field: "remark",
				headerName: "Remark", 
				width: 200,
				
			},
			{
				headerName: "Admin",
				children: [
					{
						field: "adminCompanyStatus",
						headerName: "สถานะบริษัทจากแอดมิน", 
						width: 200,
						editable: true,
						//## Cell Editor Mode
						cellEditor: 'agSelectCellEditor',
						cellEditorParams: this._adminCompanyStatusEditorParam.bind(this),



						
						//## Use to render view output html 	
					    cellRenderer: (params) => adminCompanyStatusRenderer(params),
					},
					{
						field: "adminRemark2",
						headerName: "ซัพจากที่ไหน", 
						width: 200,
						editable: true,
					},
					{
						field: "adminRemark3",
						headerName: "adminRemark3", 
						width: 200,
						editable: true,
					},
					// {
					// 	field: "adminRemark4",
					// 	headerName: "adminRemark4", 
					// 	width: 200,
					// 	editable: true,
					// },
					// {
					// 	field: "adminRemark5",
					// 	headerName: "adminRemark5", 
					// 	width: 200,
					// 	editable: true,
					// },
					// {
					// 	field: "adminRemark6",
					// 	headerName: "adminRemark6", 
					// 	width: 200,
					// 	editable: true,
					// },
					{
						field: "docVerifyList",
						headerName: "ส่งเอกสารยืนยันตัวตน", 
						width: 150,
						editable: false,

						cellRenderer: (params) => hasDocRenderer(params),
					},
					{
						field: "docBankAccountList",
						headerName: "ส่งเอกสารบัญชีแล้ว", 
						width: 150,
						editable: false,

						cellRenderer: (params) => hasDocRenderer(params),
					},
				]
			},
			{
				headerName: "Dispatcher",
				children: [
					{
						field: "dispatchers.phoneNumber",
						headerName: "เบอร์โทรบริษัท", 
						width: 200,
						editable: false,

						valueGetter: function (params) {

							//## [DEBUG]
							// console.log(params)

							let phoneNumber = '-';

							let data = params.data;
							if(data.dispatchers){
								if(data.dispatchers.length > 0){
									phoneNumber = data.dispatchers[0].phoneNumber;
								}
							}

							

							// let  phoneNumber = params.value[0].phoneNumber;

					        // convert code to value
					        return phoneNumber;
					    },
					},
					{
						field: "dispatchers.name",
						headerName: "ชื่อ คนติดต่อ", 
						width: 200,
						editable: true,
						valueGetter: function (params) {

							//## [DEBUG]
							// console.log(params)

							let name = '-';

							let data = params.data;
							if(data.dispatchers){
								if(data.dispatchers.length > 0){
									name = data.dispatchers[0].name;
								}
							}

							

							// let  phoneNumber = params.value[0].phoneNumber;

					        // convert code to value
					        return name;
					    },
					    valueSetter: function (params) {

							//## [DEBUG]
							// console.log(params)

							let name = '-';

							let data = params.data;
							if(data.dispatchers){
								if(data.dispatchers.length > 0){
									name = params.newValue;
								}
							}

							

							// let  phoneNumber = params.value[0].phoneNumber;

					        // convert code to value
					        return params.data.dispatchers[0].name = name;
					    },
					}
				]
			},
			{
				field: "name",
				headerName: "ชื่อบริษัท", 
				width: 200,
				
			},
			{
				field: "nid",
				headerName: "เลขประจำตัวผู้เสียภาษี", 
				width: 200,
				
			},
			{
				field: "companyVerifyDocument",
				headerName: "เอกสารยืนยันตัวตน", 
				filter: false,
				resizable: false,
				sortable: false,
				editable: false,
				width: 200,
				cellRenderer: "companyVerifyDocumentRenderer",
			},
			{
				headerName: "ที่อยู่",
				children: [
					{
						field: "address",
						headerName: "ที่อยู่", 
						width: 200,
						
					},
					{
						field: "shippingAddress",
						headerName: "ที่อยู่จัดส่งเอกสาร", 
						width: 200,
						
					},
				]
			},
			{
				headerName: "รายละเอียดธนาคาร",
        		children: [
        			{
						field: "companyBankAccount",
						headerName: "เอกสารบัญชีธนาคาร", 
						filter: false,
						resizable: false,
						sortable: false,
						editable: false,
						width: 200,
						cellRenderer: "companyBankAccountDocumentRenderer",
					},
					{
						field: "bankAccNo",
						headerName: "เลขบัญชีธนาคาร", 
						width: 200,
						
					},
					{
						field: "bankAccCode",
						headerName: "ธนาคาร", 
						width: 300,
						cellEditor: 'agSelectCellEditor',
						cellEditorParams: {
						    values: extractValues(BANK_NAME.mapping),
						    
						},
						refData: BANK_NAME.mapping,
					},
					{
						field: "bankAccName",
						headerName: "ชื่อบัญชีธนาคาร", 
						width: 200,

					},
				]
			},
			{
				headerName: "สมาชิก/ผลงาน",
				children: [
					{
						field: "badge",
						headerName: "badge",
						editable: false, 
						width: 400,
						
					},
					{
						field: "badgeInput",
						headerName: "แก้ไขbadge",
						filter: false,
						resizable: false,
						sortable: false,
						editable: false,
						width: 200,
						cellRenderer: "companyBadgeEditRenderer",
					},
				]
			},
			{
				headerName: "Shipper Statistic",
        		children: [
					{
						field: "jobShipperCreatedNum",
						headerName: "งานที่สร้าง", 
						editable: false,
						width: 150,	
					},
					{
						field: "jobShipperAcceptedNum",
						headerName: "งานที่มีคนรับ",
						editable: false,
						width: 150, 	
					},
					{
						field: "jobShipperFinishedNum",
						headerName: "งานที่เสร็จสิ้น", 
						editable: false,
						width: 150,	
					},
					{
						field: "jobShipperAvailableCancelNum",
						headerName: "งานว่างที่ยกเลิก", 
						editable: false,
						width: 150,	
					},
					{
						field: "jobShipperAcceptedCancelNum",
						headerName: "งานที่มีคนรับแล้วยกเลิก", 
						editable: false,
						width: 150,	
					},
					{
						field: "jobShipperCancelByCarrierNum",
						headerName: "งานถูกยกเลิกจาก Carrier",
						editable: false,
						width: 150, 	
					},
				]
			},
			{
				headerName: "Carrier Statistic",

        		children: [
					{
						field: "jobCarrierAcceptedNum",
						headerName: "งานที่รับ",
						editable: false,
						width: 150, 
					},
					{
						field: "jobCarrierFinishedNum",
						headerName: "งานที่ขนส่งเรียบร้อย", 
						editable: false,
						width: 150, 	
					},
					{
						field: "jobShipperFinishedNum",
						headerName: "งานที่เสร็จสิ้น", 
						editable: false,
						width: 150, 	
					},
					{
						field: "jobCarrierAcceptedCancelNum",
						headerName: "งานรับแล้วยกเลิก",
						editable: false,
						width: 150,  	
					},
					{
						field: "jobCarrierCancelByShipperNum",
						headerName: "งานถูกยกเลิกจาก shipper", 	
						editable: false,
						width: 150, 
					},
				]
			},
			{
				field: "createdAt",
				headerName: "วันที่สร้าง", 
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
			},
			{
				field: "updatedAt",
				headerName: "วันที่อัพเดต", 
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
				
			},
			{
				field: "Delete",
				headerName: "ลบ", 
				width: 150,
				editable: false,
				cellRenderer: "deleteOneCompanyButtonRenderer",
			},
		],

		//## Raw data 
		rowData: [
			{_id: 555,cid: "Toyota",carrierStatus: 0,type: 'individual', name: "Celica", nid: 35000, createdAt: '2019-05-12T01:29:15.647Z', updatedAt: '2019-05-23T01:29:15.647Z'},
			{_id: 666,cid: "Ford",carrierStatus: 1,type: 'company', name: "Mondeo", nid: 32000},
			{_id: 777,cid: "Porsche",carrierStatus: 2,type: 'individual', name: "Boxter", nid: 72000}
		],

		//## Total data row in server database
		rowTotal: 0,

		editedData: [],


		editedDispatchers: [],



		//##------------------- Company Verify Document---------
		viewDocCompanyId: null,

		isShowCompanyVerifyDialog: false,

		//##------------------- Company Bank Account Document---------
		isShowCompanyBankAccountDialog: false,

		//##------------------- Company Badge Dialog ---------
		isShowCompanyBadgeEditDialog: false,


	}


    constructor(props) {
        super(props);

        this.onPrefetch = this.onPrefetch.bind(this);

        // this.agGridApi = this.agGridApi.bind(this);
        // this.agGridColumnApi = this.agGridColumnApi.bind(this);
        this.onEditOneCell = this.onEditOneCell.bind(this);
        this.onSaveEdit = this.onSaveEdit.bind(this);
        this.onResetEditedData = this.onResetEditedData.bind(this);
        this.onResetEditedDispacthers = this.onResetEditedDispacthers.bind(this);

        this.onClickPrevPage = this.onClickPrevPage.bind(this);
		this.onClickNextPage = this.onClickNextPage.bind(this);
		this.onDisablePrevNextPageButton = this.onDisablePrevNextPageButton.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);

        this.companyVerifyDocDialogRef = React.createRef();
        this.onViewCompanyVerifyDocument = this.onViewCompanyVerifyDocument.bind(this);
        this.onCloseCompanyVerifyDialog = this.onCloseCompanyVerifyDialog.bind(this);

        this.companyBankAccountDocDialogRef = React.createRef();
        this.onViewCompanyBankAccountDocument = this.onViewCompanyBankAccountDocument.bind(this);
        this.onCloseCompanyBankAccountDialog = this.onCloseCompanyBankAccountDialog.bind(this);


        this.companyBadgeEditDialogRef = React.createRef();
        this.onViewCompanyBadgeEditDialog = this.onViewCompanyBadgeEditDialog.bind(this);
        this.onCloseCompanyBadgeEditDialog = this.onCloseCompanyBadgeEditDialog.bind(this);


        this.onDeleteOneCompany = this.onDeleteOneCompany.bind(this);
    }

    //##----------------------------------------------
    static getDerivedStateFromProps(nextProps, prevState){
    		
    	let retState = null

    	let {
    		companyList,
    		companyListTotalNum,
    		adminCompanyStatusList,
    	} = nextProps;

    	let newList = companyList;
    	let newListTotalNum = companyListTotalNum;


    	if(newList){
    		if(newList.length > 0){
    			let newString = JSON.stringify(newList);
    			let oldString = JSON.stringify(prevState.rowData);

    			if(newString !== oldString){
    				retState = {
    					...retState,
    					rowData: newList,
    				}
    			}
    		}
	    }

	    if(newListTotalNum !== prevState.rowTotal){
	    	let totalPage = 0;
	    	let limit = prevState.limit;


	    	let disabledPrevPageBtn = true;
	    	let disabledNextPageBtn = false;

	    	let totalRowInOnePage = prevState.limit;

	    	if(limit > 0){
	    		totalPage = Math.ceil(newListTotalNum / limit)
	    	}
	    	else{
	    		totalPage = 0;
	    	}

	    	if(newListTotalNum <= totalRowInOnePage){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= true;
	    	}

	    	retState = {
	    		...retState,
	    		rowTotal: newListTotalNum,
	    		totalPage: totalPage,
	    		disabledPrevPageBtn: disabledPrevPageBtn,
	    		disabledNextPageBtn: disabledNextPageBtn,
	    	}
	    }



    	let newAdminCompanyStatusList = adminCompanyStatusList;


    	if(newAdminCompanyStatusList){
    		if(newAdminCompanyStatusList.length > 0){
    			let newString = JSON.stringify(newAdminCompanyStatusList);
    			let oldString = JSON.stringify(prevState.adminCompanyStatusList);

    			if(newString !== oldString){
    				retState = {
    					...retState,
    					adminCompanyStatusList: newAdminCompanyStatusList,
    				}
    			}
    		}
	    }


    	return retState;
    }


    componentDidMount(){
    	this.onPrefetch();
    }

    componentDidUpdate(prevProps,prevState){
    	// if(JSON.stringify(prevState.rowData) !== JSON.stringify(this.state.rowData)){
    	// 	console.log('Component Did Update Row Data: ')
    	// 	console.log(this.state.rowData);
    	// }

    	//## Change Limit -> disable next/prev button of table
    	// if(prevState.limit !== this.state.limit){
    	// 	this.onDidUpdateLimit();
    	// }
    	
    }

    //##----------------------------------------------
    async onPrefetch(){
    	const { dispatch } = this.props;

    	let {
    		page,
    		limit,
    		sortBy,
    		filterBy,
    	} = this.state;


    	await dispatch(spinnerActions.showSpinner());

    	let companyList = await dispatch(
    		companyListActions.getPaginateCompanyList(
    			page,
    			limit,
    			sortBy,
    		)
    	);



    	let companyTotalNum = await dispatch(
    		companyListActions.countPaginateCompanyList(
    			filterBy
    		)
    	);

    	let adminCompanyStatusList = await dispatch(
    		adminCompanyStatusActions.getAllAdminCompanyStatusList(
    		)
    	);

        if(adminCompanyStatusList){
            await this.setState({
                adminCompanyStatusList: adminCompanyStatusList,
            })
        }

    	if(this.state.isPrefetched === false){
    		await this.setState({
    			isPrefetched: true,
    		})
    	}

    	await dispatch(spinnerActions.hideSpinner());


    	if(!companyList || !companyTotalNum){
    		let errorMsg = this.props.companyError.join('\n');

    		await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )

    	}





    	return Promise.resolve();
    }

    //##----------------------------------------------
    _adminCompanyStatusEditorParam() {
    	return {
		    values: extractObjectByKey(this.state.adminCompanyStatusList, 'name'),
		    cellRenderer: (params) => adminCompanyStatusRenderer(params),
		};
    }


    //##----------------------------------------------
    onGridReady = (params) => {
    	this.agGridApi = params.api;
    	this.agGridColumnApi = params.columnApi;
    }

    onEditOneCell = (params) => {
    	// notice that the data always contains the keys rather than values after editing
	       
    	//## [DEBUG]
    	// console.log("onEditOneCell: ", params);

    	let oneRowData = {...params.data};
    	let rowId = oneRowData._id;
    	let editColumnName = params.column ? params.column.colId : null;

    	if(rowId && editColumnName){

    		let {
    			editedData
    		} = this.state;

    		//## [DEBUG]
    		// console.log("OneRowEditData: ", oneRowData);
    		// console.log("rowId: ", rowId);
    		// console.log("editColumnName: ", editColumnName);

    		//## Remove Status 
    		if(editColumnName !== 'carrierStatus'){
    			delete oneRowData['carrierStatus'];
    			delete oneRowData['shipperStatus'];
    		}

    		delete oneRowData['cid'];

    		//## loop update edit Data
    		let isAlreadyEdited = false;
    		for(var i =0; i < editedData.length; i++){
    			if(editedData[i]._id === rowId){
    				editedData[i] = oneRowData;
    				isAlreadyEdited = true;
    				break;
    			}
    		}

    		if(!isAlreadyEdited){
    			editedData.push(oneRowData);
    		}

    		//## [DEBUG]
    		// console.log('editedData: ',editedData);

    		this.setState({
    			editedData: editedData,
    		})

    		//## edit dispacther name
    		if(editColumnName === 'dispatchers.name'){
    			if(oneRowData.dispatchers){
    				if(oneRowData.dispatchers.length > 0){
    					var newDispatcherName = oneRowData.dispatchers[0].name;
    					var newDispatcherPhoneNumber = oneRowData.dispatchers[0].phoneNumber;
    					//## [DEBUG]
    					// console.log('newDispatcherName: ',newDispatcherName);
    					// console.log('newDispatcherPhoneNumber: ',newDispatcherPhoneNumber);

    					var oneDispatcherData = {
    						name: newDispatcherName,
    						phoneNumber: newDispatcherPhoneNumber,
    					}

    					let {
			    			editedDispatchers
			    		} = this.state;

			    		let newEditedDispatchers = [...editedDispatchers];

			    		newEditedDispatchers.push(oneDispatcherData);

			    		//## [DEBUG]
    					// console.log('newEditedDispatchers: ',newEditedDispatchers);
    					

    					this.setState({
    						editedDispatchers: newEditedDispatchers,
    					})
	    				
    				}
    			}
    		}

    	}
    }

    onSaveEdit = async() => {
    	const { dispatch } = this.props;
    	let {
    		editedData,
    		editedDispatchers,
    	} = this.state;


    	await dispatch(spinnerActions.showSpinner());

    	if(editedData.length > 0){


	    	const promises = editedData.map( async(oneRow) =>  {


	    		let isSuccess = await dispatch(
	    			editCompanyActions.editOneCompanyInfo(
	    				oneRow
	    			)
	    		)

	    		if(isSuccess){
	    			return null;
	    		}
	    		else{
	    			let errorMsg = this.props.companyError.join('\n');

	    			return errorMsg;
	    		}
	    	})

	    	let updatedResultArray = await Promise.all(promises);
	    	let updatedResultArrayFiltered = await updatedResultArray.filter(v => v !== null);

	    	if(updatedResultArrayFiltered){
	    		if(updatedResultArrayFiltered.length > 0){
	    			let allErrorMsg = "";
	    			for(var i = 0; i < updatedResultArrayFiltered.length; i++){
	    				allErrorMsg += updatedResultArrayFiltered + '\n';
	    			}


	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                        allErrorMsg,
	                        ALERT_CONSTANTS.ERROR_MODE
	                    )
	                )
	    		}
	    		else{

	    			await this.onResetEditedData();

	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.SUCCESS_DIALOG_TITLE,
	                        APP_CONSTANTS.SUCCESS_EDIT_MESSAGE,
	                        ALERT_CONSTANTS.SUCCESS_MODE
	                    )
	                )
	    		}
	    	}

	    	await this.onPrefetch();

	    	
	    }
	    else{
	    	await dispatch(alertActions.showAlert(
	                APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                'ไม่มีข้อมูลให้แก้ไข',
	                ALERT_CONSTANTS.ERROR_MODE
	            )
	        )
	    }

	    if(editedDispatchers.length > 0){

	    	const promises2 = editedDispatchers.map( async(oneRow) =>  {


	    		let isSuccess = await dispatch(
	    			editUserActions.editOneUserContactName(
	    				oneRow.phoneNumber,
	    				oneRow.name,
	    			)
	    		)

	    		if(isSuccess){
	    			return null;
	    		}
	    		else{
	    			let errorMsg = this.props.companyError.join('\n');

	    			return errorMsg;
	    		}
	    	})

	    	let updatedResultArray2 = await Promise.all(promises2);
	    	let updatedResultArrayFiltered2 = await updatedResultArray2.filter(v => v !== null);

	    	if(updatedResultArrayFiltered2){
	    		if(updatedResultArrayFiltered2.length > 0){
	    			let allErrorMsg2 = "";
	    			for(var j = 0; j < updatedResultArrayFiltered2.length; j++){
	    				allErrorMsg2 += updatedResultArrayFiltered2 + '\n';
	    			}


	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                        allErrorMsg2,
	                        ALERT_CONSTANTS.ERROR_MODE
	                    )
	                )
	    		}
	    		else{

	    			await this.onResetEditedDispacthers();

	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.SUCCESS_DIALOG_TITLE,
	                        APP_CONSTANTS.SUCCESS_EDIT_MESSAGE,
	                        ALERT_CONSTANTS.SUCCESS_MODE
	                    )
	                )
	    		}
	    	}

	    	await this.onPrefetch();

	    	
	    }

	    await dispatch(spinnerActions.hideSpinner());


    	return Promise.resolve();
    }

    onResetEditedData = async() => {

    	await this.setState({
    		editedData: [],
    	})

    	return Promise.resolve();
    }

    onResetEditedDispacthers = async() => {
    	await this.setState({
    		editedDispatchers: [],
    	})
    }

    //##----------------------------------------------




    onClickPrevPage = async() => {
    	let {
    		page,


    	} = this.state;

    	let nextPage = page - 1;

    	if(nextPage <= 0){
    		nextPage = 0;
    	}


    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();

    	return Promise.resolve();
    }

    onClickNextPage = async() => {
    	let {
    		page,
    		totalPage,
    	} = this.state;

    	let nextPage = page + 1;

    	if(nextPage >= (totalPage-1)){
    		nextPage = totalPage-1;
    	}

    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();
    	

    	return Promise.resolve();
    }

    onDisablePrevNextPageButton = () => {
    	let {
    		page,
    		limit,
    		totalPage,
    		disabledPrevPageBtn,
    		disabledNextPageBtn,

    		rowTotal
    	} = this.state;

    	//## [DEBUG]
    	// console.log('--onDisablePrevNextPageButton:---')
    	// console.log('page: ', page);
    	// console.log('totalPage: ', totalPage);


    	if(rowTotal <= limit){
    		disabledPrevPageBtn = true;
			disabledNextPageBtn	= true;
    	}
    	else{
    		if(page <= 0){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= false;
	    	}
	    	else if(page >= (totalPage-1)){
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= true;
	    	}
	    	else{
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= false;
	    	}
    	}

    	//## [DEBUG]
    	// console.log('disabledPrevPageBtn: ', disabledPrevPageBtn)
    	// console.log('disabledNextPageBtn: ', disabledNextPageBtn)
		

    	this.setState({
    		disabledPrevPageBtn: disabledPrevPageBtn,
    		disabledNextPageBtn: disabledNextPageBtn,
    	})
    }

    onChangeLimit = async(event) => {

    	let {

    		page,
    		totalPage,
    		
    	} = this.state;

    	let newLimit = parseInt(event.target.value,10);

    	//## {DEBUG}

    	// console.log('newLimit: ' + newLimit);
    	// console.log('currentLimit: ' + this.state.limit);

    	if(newLimit !== this.state.limit){
    		//## Reset page when change new limit
    		page = 0;
	    	 
	    }

    	await this.setState({
    		page: page,
    		limit: newLimit,
    	})


    	await this.onPrefetch();


    	let {
    		rowTotal,
    	} = this.state;

    	//## Calculate new total page after update limit and rowTotal
    	if(newLimit > 0){
    		totalPage = Math.ceil(rowTotal / newLimit)
    	}
    	else{
    		totalPage = 0;
    	}

    	await this.setState({
    		totalPage: totalPage,
    	})


    	await this.onDisablePrevNextPageButton();



    	return Promise.resolve();
    }



    //##----------------------------------------------
    onViewCompanyVerifyDocument = (companyId) => {
    	//## [DEBUG]
    	// console.log('onViewCompanyVerifyDocument: '+ companyId);
    	this.setState({
    		isShowCompanyVerifyDialog: true,
    		viewDocCompanyId: companyId,
    	})
    }


    onCloseCompanyVerifyDialog = () => {
    	this.setState({
    		isShowCompanyVerifyDialog: false,
    		viewDocCompanyId: null,
    	})
    }


    //##----------------------------------------------
    onViewCompanyBankAccountDocument = (companyId) => {
    	//## [DEBUG]
    	// console.log('onViewCompanyBankAccountDocument: '+ companyId);
    	this.setState({
    		isShowCompanyBankAccountDialog: true,
    		viewDocCompanyId: companyId,
    	})
    }


    onCloseCompanyBankAccountDialog = () => {
    	this.setState({
    		isShowCompanyBankAccountDialog: false,
    		viewDocCompanyId: null,
    	})
    }


    //##----------------------------------------------
    onViewCompanyBadgeEditDialog = (companyId) => {
    	//## [DEBUG]
    	// console.log('onViewCompanyBankAccountDocument: '+ companyId);
    	this.setState({
    		isShowCompanyBadgeEditDialog: true,
    		viewDocCompanyId: companyId,
    	})
    }


    onCloseCompanyBadgeEditDialog = () => {
    	this.setState({
    		isShowCompanyBadgeEditDialog: false,
    		viewDocCompanyId: null,
    	})
    }

    //##----------------------------------------------

    onDeleteOneCompany = async(companyId) => {
    	const { dispatch } = this.props;

    	await dispatch(spinnerActions.showSpinner());

    	let isConfirmed = await window.confirm("แน่ใจที่จะลบ!");

    	if(isConfirmed){

	    	let isSuccess = await dispatch(
	    		deleteCompanyActions.deleteOneCompany(
	    			companyId
	    		)
	    	);

	    	if(!isSuccess){
	    		let allErrorMsg = this.props.companyError.join('\n');

	    		await dispatch(alertActions.showAlert(
	                    APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                    allErrorMsg,
	                    ALERT_CONSTANTS.ERROR_MODE
	                )
	            )
	    	}
	    	else{
	    		await this.onPrefetch();
	    	}
	    }

    	await dispatch(spinnerActions.hideSpinner());

    }


    //##----------------------------------------------

    render() {
        const { classes } = this.props;

        return (
        	<Grid container spacing={0}>
        		<Grid 
        			item 
        			xs={12} 
        			className={classes.agGridContainer}
        		>
        			<Grid container spacing={0}>
        				<Grid 
		        			item 
		        			xs={12} 
		        			className={classes.agGridTableToolbarContainer}
		        		>
		        			<Grid 
		        				container 
		        				spacing={16}
		        				direction="row"
							    justify="flex-end"
							    alignItems="center"
		        			>
		        				
							    <Grid item >
							    	<Typography 
			                        	variant="h6" 
			                        >
							        	{'จำนวนแถว: '}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	
			                    	<Select
							            value={this.state.limit}
							            onChange={this.onChangeLimit}
							            inputProps={{
							              	name: 'rowPerpage',
							              	id: 'tab-page-size',
							            }}
							        >
							            {this.state.rowPerPageArray.map(name => (
							              	<MenuItem 
							              		key={name} 
							              		value={name} 
							              	>
							                	{name}
							              	</MenuItem>
							            ))}
							        </Select>
							    </Grid>
							    <Grid item >
			                    	<Typography 
			                        	variant="body1" 
			                        >
			                        	
							        	{(this.state.limit * this.state.page) + 1}
							        	{'-'}
							        	{(this.state.limit * (this.state.page + 1))}
							        	{'   from total:  '}
							        	{this.state.rowTotal}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickPrevPage()} 
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledPrevPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronLeft}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />

			                        </Button>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickNextPage()}
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledNextPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronRight}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                        </Button>
							    </Grid>
							    <Grid item >
				        			<Button 
				        				onClick={() => this.onSaveEdit()} 
				        				variant={'contained'}
				        				color="primary"
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faSave}
		                                    className={
		                                    	classNames(
		                                    		classes.leftIcon, 
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                            {'Save'}
			                        </Button>
			                        
			                    </Grid>
		                    </Grid>
		        		</Grid>
        			</Grid>
		        	<div
						className={classNames(
							"ag-theme-balham",
		                    classes.agGridTable, 
		                )}
					>
						<AgGridReact
							reactNext={true}
							onGridReady={this.onGridReady}
							context={this.state.context}
							frameworkComponents={this.state.frameworkComponents}
							defaultColDef={this.state.defaultColDef}
							columnTypes={this.state.columnTypes}
							floatingFilter={true}
							columnDefs={this.state.columnDefs}
							rowData={this.state.rowData}
							onCellValueChanged={this.onEditOneCell}
						>
						</AgGridReact>
					</div>
					<CompanyVerifyDocumentDialog 
						ref={this.companyVerifyDocDialogRef}
						isVisible={this.state.isShowCompanyVerifyDialog}
						handleClose={this.onCloseCompanyVerifyDialog}
						companyId={this.state.viewDocCompanyId}
					/>
					<CompanyBankAccountDocumentDialog 
						ref={this.companyBankAccountDocDialogRef}
						isVisible={this.state.isShowCompanyBankAccountDialog}
						handleClose={this.onCloseCompanyBankAccountDialog}
						companyId={this.state.viewDocCompanyId}
					/>
					<CompanyBadgeEditDialog
						ref={this.companyBadgeEditDialogRef}
						isVisible={this.state.isShowCompanyBadgeEditDialog}
						handleClose={this.onCloseCompanyBadgeEditDialog}
						companyId={this.state.viewDocCompanyId}
						reloadAllDataFn={this.onPrefetch}
					/>
				</Grid>
			</Grid>
        )
    }

}






const mapStateToProps = (state) => ({
	companyError: state.company.error,

	companyList: state.company.companyList,
	companyListTotalNum: state.company.companyListTotalNum,

})


CompanyListTableContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(CompanyListTableContainers);

const connectedContainers = connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as CompanyListTableContainers };