
import { AUTH_ACTION_TYPES } from '../constants';
// import { AUTH_CONSTANTS } from '../constants';

// import { APP_CONSTANTS } from '../../../constants';

// import { SPINNER_ACTION_TYPES } from '../../spinner/constants';

// import { loginServices } from '../../../api/services';


// import { createErrorMessageFromJsonResponse } from '../../../utilities';

// import { spinnerActions } from '../../spinner/actions';

function requestLogout() { 
    return { 
        type: AUTH_ACTION_TYPES.LOGOUT_REQUEST
    } 
}

function successLogout() { 
    return { 
        type: AUTH_ACTION_TYPES.LOGOUT_SUCCESS
    } 
}

function failureLogout(error) { 
    return { 
        type: AUTH_ACTION_TYPES.LOGOUT_FAILURE, 
        error 
    } 
}

function logout(){
    return async (dispatch) => {

        await dispatch(requestLogout());

        try {
            await localStorage.clear();
            await dispatch(successLogout());
            return true;
        }
        catch(error){
            await dispatch(failureLogout(error));
            return false;
        }
    }
}




export const logoutActions = {
    requestLogout,
    successLogout,
    failureLogout,
    logout,
};