import { TRUCKS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// USER_STATUS,

} from '../../../constants';
import { TRUCKS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { truckTypeServices } from '../../../api/services';



function requestEditOneTruckTypeInfo(){
	return {
		type: TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_TYPE_INFO_REQUEST
	}
}

function successEditOneTruckTypeInfo(){
	return {
		type: TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_TYPE_INFO_SUCCESS,
	}
}



function failureEditOneTruckTypeInfo(error){
	return {
		type: TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_TYPE_INFO_FAILURE,
		error,
	}
}


function editOneTruckTypeInfo(oneRowData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneTruckTypeInfo());


    			let payload ={
		    		
			        truckTypeId: oneRowData._id,
			        typeName: oneRowData.typeName ? oneRowData.typeName : null,	
			        category: oneRowData.category ? oneRowData.category : null,
		    	}

		    	//## [DEBUG]
	    		// console.log('-----Truck Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await truckTypeServices
    			.putUpdateOneTruckTypeInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneTruckTypeInfo(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneTruckTypeInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneTruckTypeInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneTruckTypeInfo(
								TRUCKS_CONSTANTS.ERROR_FAILURE_EDIT_ONE_TRUCK_TYPE_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneTruckTypeInfo(
							TRUCKS_CONSTANTS.ERROR_FAILURE_EDIT_ONE_TRUCK_TYPE_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneTruckTypeInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneTruckTypeInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


export const editTruckTypeActions = {


	requestEditOneTruckTypeInfo,
	successEditOneTruckTypeInfo,
	failureEditOneTruckTypeInfo,
	editOneTruckTypeInfo,


}