import { NOTIF_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
} from '../../../constants';
import { NOTIF_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { notifServices } from '../../../api/services';



function requestGetPaginateNotifList(page,limit,sortBy){
	return {
		type: NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_REQUEST
	}
}

function successGetPaginateNotifList(notificationList,page,limit,sortBy){
	return {
		type: NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_SUCCESS,
		notificationList,
		page,
		limit,
		sortBy,
	}
}

function successGetPaginateNotifListAddMore(notificationList,page,limit,sortBy){
	return {
		type: NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_SUCCESS_ADD_MORE,
		notificationList,
		page,
		limit,
		sortBy,
	}
}

function failureGetPaginateNotifList(error){
	return {
		type: NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_FAILURE,
		error,
	}
}

function emptyGetPaginateNotifList(error){
	return {
		type: NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_EMPTY,
		error,
	}
}

function getPaginateNotifList(page, limit, sortBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestGetPaginateNotifList());

    			//## Get Only Job below available JOb
    			let filterBy = {
    				
    			}

    			let select = null;

    			if(!sortBy){
    				sortBy = APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY;
    			}

    			let payload = {
    				page: page,
    				limit: limit,
    				filterBy: filterBy,
    				sortBy: sortBy,
    				select: select,
    			}

    			let responseJson = await notifServices.getPaginateNotifList(
						    				token,
						    				payload
						    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							// if(page === 0){
							// 	await dispatch(
							// 		successGetPaginateNotifList(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }
							// else if(page > 0){
							// 	await dispatch(
							// 		successGetPaginateNotifListAddMore(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }

							await dispatch(
								successGetPaginateNotifList(
									responseJson.data,
									page,
									limit,
									sortBy,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyGetPaginateNotifList(
		                			NOTIF_CONSTANTS.ERROR_FAILURE_PAGINATE_NOTIF
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetPaginateNotifList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetPaginateNotifList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureGetPaginateNotifList(
								NOTIF_CONSTANTS.ERROR_FAILURE_PAGINATE_NOTIF
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureGetPaginateNotifList(
							NOTIF_CONSTANTS.ERROR_FAILURE_PAGINATE_NOTIF
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetPaginateNotifList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetPaginateNotifList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


//##-------------------------------------------------------------------------
function requestCountPaginateNotifList(page,limit,sortBy){
	return {
		type: NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_REQUEST
	}
}

function successCountPaginateNotifList(notifListTotalNum){
	return {
		type: NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_SUCCESS,
		notifListTotalNum,
	}
}

function failureCountPaginateNotifList(error){
	return {
		type: NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_FAILURE,
		error,
	}
}

function emptyCountPaginateNotifList(error){
	return {
		type: NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_EMPTY,
		error,
	}
}

function countPaginateNotifList(filterBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestCountPaginateNotifList());

    			//## Get Only Job below available JOb



    			let responseJson = await notifServices
    			.getCountTotalNotifNum(
					token,						    				
    				filterBy
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){

							await dispatch(
								successCountPaginateNotifList(
									responseJson.data
								)
							);

							return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyCountPaginateNotifList(
		                			NOTIF_CONSTANTS.ERROR_EMPTY_COUNT_PAGINATE_NOTIF
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureCountPaginateNotifList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureCountPaginateNotifList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureCountPaginateNotifList(
								NOTIF_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_NOTIF
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureCountPaginateNotifList(
							NOTIF_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_NOTIF
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(failureCountPaginateNotifList(error.message));
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureCountPaginateNotifList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


export const notifListActions = {

	requestGetPaginateNotifList,
	successGetPaginateNotifList,
	successGetPaginateNotifListAddMore,
	failureGetPaginateNotifList,
	emptyGetPaginateNotifList,
	getPaginateNotifList,


	requestCountPaginateNotifList,
	successCountPaginateNotifList,
	failureCountPaginateNotifList,
	emptyCountPaginateNotifList,
	countPaginateNotifList,


}