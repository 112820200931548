


function adminCompanyStatusRenderer(params){

	var fontColor = '#ffffff';
	var bgColor = '#bd83ce';
	var valueShow = params.value ? params.value : '?';

	//## [DEBUG]
	// console.log('companyTypeCellRenderer');
	// console.log(params);




	var ret = "<div style='width: 100%; height: 100%; text-align: center; "
	ret += "background-color:" + bgColor + "; "
	ret += "color: " + fontColor + ";' >";
	ret += valueShow + "</div>"


	return ret;

}

export { 

	adminCompanyStatusRenderer, 
};


