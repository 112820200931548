import { ALERT_ACTION_TYPES } from '../constants';



function showAlert(title,message,alertType) { 
    return { 
        type: ALERT_ACTION_TYPES.SHOW_ALERT,
        title,
        message,
        alertType
    } 
}

function hideAlert() { 
    return { 
        type: ALERT_ACTION_TYPES.HIDE_ALERT
    } 
}


export const alertActions = {
	showAlert,
	hideAlert
}