import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faEdit,
    faStream,

} from '@fortawesome/free-solid-svg-icons';

// import { spinnerActions } from '../../spinner/actions';
import { navActions } from '../../nav/actions';

// import { truckTypesActions } from '../../trucktypes/actions';
// import { spinnerActions } from '../../spinner/actions';
// import { alertActions } from '../../alert/actions';

import { layoutMuiStyles } from '../../../styles';
import { 
    DrawerContainers,
    AppbarContainers, 
} from '../../nav/containers';

import { 
    AdminCompanyStatusListTableContainers,
    AdminCompanyStatusNewFormContainers,

} from '../../admin/containers';


class AdminCompanyStatusListPage extends React.Component {

    constructor(props){
        super(props);

        this.onPageFocus = this.onPageFocus.bind(this);
        this.onClickCreateNew = this.onClickCreateNew.bind(this);
        // this.initTruckTypeList = this.initTruckTypeList.bind(this);
    }

    async componentWillMount(){

        //## Init truck type when load page
        // await this.initTruckTypeList();

        return Promise.resolve();
    }

    onPageFocus() {
        const { dispatch } = this.props;
        dispatch(navActions.focusAdminCompanyStatusListPage());
    }

    componentDidMount() {
        //## Navigation pass to redux store
        this.onPageFocus();
    }


    onClickCreateNew() {

    }

    //##---------------------------------------------------------------------
    

    
    render() {
        const { classes } = this.props;
        return (
            <div 
                className={classes.root}
            >
                <div 
                    className={classes.appFrame}
                >
                    <AppbarContainers />
                    <DrawerContainers />
                    <main 
                        className={classNames(
                            classes.content, 
                            this.props.isDrawerOpen && classes.contentShift
                            )
                        }
                    >
                        <div
                            className={classes.innerContainer}
                        >
                            <h2>
                                <FontAwesomeIcon 
                                    icon={faStream} 
                                /> 
                                {' สถานะบริษัทโดยแอดมิน (AdminCompanyStatus)'}
                            </h2>
                            <div className={classes.divider2} />
                            <Grid container spacing={8}>
                                <Grid 
                                    item 
                                    xs={12}
                                >
                                </Grid>
                                <Grid 
                                    item 
                                    xs={12}
                                    md={6} 
                                >
                                    <AdminCompanyStatusListTableContainers />
                                </Grid>
                                <Grid 
                                    item 
                                    xs={12}
                                    md={6} 
                                >
                                    <Paper className={classes.paperRoot}>
                                        <h2>
                                            <FontAwesomeIcon 
                                                icon={faEdit} 
                                            /> 
                                            {' สร้างใหม่'}
                                        </h2>
                                        <AdminCompanyStatusNewFormContainers
                                            mode={'new'}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}


AdminCompanyStatusListPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    isDrawerOpen: state.nav.isDrawerOpen,

    // trucktypesError: state.trucktypes.error,
})

const connectedPage = connect(mapStateToProps)(withStyles(
    layoutMuiStyles, 
    { withTheme: true }
)(AdminCompanyStatusListPage));
export { connectedPage  as AdminCompanyStatusListPage };