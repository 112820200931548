const list = [
  { "id": 1, "code": "10", "name": "กรุงเทพมหานคร", "region": "กลาง", "lat": 13.868 ,"lng": 100.606 },
  { "id": 2, "code": "11", "name": "สมุทรปราการ", "region": "กลาง", "lat": 13.593 ,"lng": 100.597 },
  { "id": 3, "code": "12", "name": "นนทบุรี", "region": "กลาง", "lat": 13.842 ,"lng": 100.494 },
  { "id": 4, "code": "13", "name": "ปทุมธานี", "region": "กลาง", "lat": 14.012 ,"lng": 100.528 },
  { "id": 5, "code": "14", "name": "อยุธยา", "region": "กลาง", "lat": 14.349 ,"lng": 100.551  },
  { "id": 6, "code": "15", "name": "อ่างทอง", "region": "กลาง", "lat": 14.349 ,"lng": 100.551 },
  { "id": 7, "code": "16", "name": "ลพบุรี", "region": "กลาง", "lat": 14.804 ,"lng": 100.634 },
  { "id": 8, "code": "17", "name": "สิงห์บุรี", "region": "กลาง", "lat": 14.890 ,"lng": 100.404 },
  { "id": 9, "code": "18", "name": "ชัยนาท", "region": "กลาง", "lat": 15.181 ,"lng": 100.127 },
  { "id": 10, "code": "19", "name": "สระบุรี", "region": "กลาง", "lat": 14.527 ,"lng": 100.915 },
  { "id": 11, "code": "20", "name": "ชลบุรี", "region": "ตะวันออก", "lat": 13.364 ,"lng": 100.987 },
  { "id": 12, "code": "21", "name": "ระยอง", "region": "ตะวันออก", "lat": 12.672 ,"lng": 101.266 },
  { "id": 13, "code": "22", "name": "จันทบุรี", "region": "ตะวันออก", "lat": 12.591 ,"lng": 102.087 },
  { "id": 14, "code": "23", "name": "ตราด", "region": "ตะวันออก", "lat": 12.216 ,"lng": 102.504 },
  { "id": 15, "code": "24", "name": "ฉะเชิงเทรา", "region": "ตะวันออก", "lat": 13.686 ,"lng": 101.066 },
  { "id": 16, "code": "25", "name": "ปราจีนบุรี", "region": "ตะวันออก", "lat": 14.057 ,"lng": 101.374 },
  { "id": 17, "code": "26", "name": "นครนายก", "region": "กลาง", "lat": 14.199 ,"lng": 101.222 },
  { "id": 18, "code": "27", "name": "สระแก้ว", "region": "ตะวันออก", "lat": 13.803 ,"lng": 102.077 },
  { "id": 19, "code": "30", "name": "นครราชสีมา", "region": "ตะวันออกเฉียงเหนือ", "lat": 14.906 ,"lng": 102.102 },
  { "id": 20, "code": "31", "name": "บุรีรัมย์", "region": "ตะวันออกเฉียงเหนือ", "lat": 14.999 ,"lng": 103.104 },
  { "id": 21, "code": "32", "name": "สุรินทร์", "region": "ตะวันออกเฉียงเหนือ", "lat": 14.884 ,"lng": 103.490 },
  { "id": 22, "code": "33", "name": "ศรีสะเกษ", "region": "ตะวันออกเฉียงเหนือ", "lat": 15.103 ,"lng": 104.336 },
  { "id": 23, "code": "34", "name": "อุบลราชธานี", "region": "ตะวันออกเฉียงเหนือ", "lat": 15.232 ,"lng": 104.859 },
  { "id": 24, "code": "35", "name": "ยโสธร", "region": "ตะวันออกเฉียงเหนือ", "lat": 15.799 ,"lng": 104.148 },
  { "id": 25, "code": "36", "name": "ชัยภูมิ", "region": "ตะวันออกเฉียงเหนือ", "lat": 15.804 ,"lng": 102.037 },
  { "id": 26, "code": "37", "name": "อำนาจเจริญ", "region": "ตะวันออกเฉียงเหนือ", "lat": 15.872 ,"lng": 104.635 },
  { "id": 27, "code": "39", "name": "หนองบัวลำภู", "region": "ตะวันออกเฉียงเหนือ", "lat": 17.168 ,"lng": 102.444 },
  { "id": 28, "code": "40", "name": "ขอนแก่น", "region": "ตะวันออกเฉียงเหนือ", "lat": 16.438 ,"lng": 102.837 },
  { "id": 29, "code": "41", "name": "อุดรธานี", "region": "ตะวันออกเฉียงเหนือ", "lat": 17.411 ,"lng": 102.792 },
  { "id": 30, "code": "42", "name": "เลย", "region": "ตะวันออกเฉียงเหนือ", "lat": 17.493 ,"lng": 101.732 },
  { "id": 31, "code": "43", "name": "หนองคาย", "region": "ตะวันออกเฉียงเหนือ", "lat": 17.890 ,"lng": 102.761 },
  { "id": 32, "code": "44", "name": "มหาสารคาม", "region": "ตะวันออกเฉียงเหนือ", "lat": 16.175 ,"lng": 103.319 },
  { "id": 33, "code": "45", "name": "ร้อยเอ็ด", "region": "ตะวันออกเฉียงเหนือ", "lat": 16.051 ,"lng": 103.653 },
  { "id": 34, "code": "46", "name": "กาฬสินธุ์", "region": "ตะวันออกเฉียงเหนือ", "lat": 16.437 ,"lng": 103.522 },
  { "id": 35, "code": "47", "name": "สกลนคร", "region": "ตะวันออกเฉียงเหนือ", "lat": 17.163 ,"lng": 104.162 },
  { "id": 36, "code": "48", "name": "นครพนม", "region": "ตะวันออกเฉียงเหนือ", "lat": 17.409 ,"lng": 104.781 },
  { "id": 37, "code": "49", "name": "มุกดาหาร", "region": "ตะวันออกเฉียงเหนือ", "lat": 16.558 ,"lng": 104.646 },
  { "id": 38, "code": "50", "name": "เชียงใหม่", "region": "เหนือ", "lat": 18.796 ,"lng": 98.985 },
  { "id": 39, "code": "51", "name": "ลำพูน", "region": "เหนือ", "lat": 18.573 ,"lng": 99.002 },
  { "id": 40, "code": "52", "name": "ลำปาง", "region": "เหนือ", "lat": 18.290 ,"lng": 99.492 },
  { "id": 41, "code": "53", "name": "อุตรดิตถ์", "region": "เหนือ", "lat": 17.613 ,"lng": 100.079 },
  { "id": 42, "code": "54", "name": "แพร่", "region": "เหนือ", "lat": 18.140 ,"lng": 100.142 },
  { "id": 43, "code": "55", "name": "น่าน", "region": "เหนือ", "lat": 18.793 ,"lng": 100.786 },
  { "id": 44, "code": "56", "name": "พะเยา", "region": "เหนือ", "lat": 19.172 ,"lng": 99.894 },
  { "id": 45, "code": "57", "name": "เชียงราย", "region": "เหนือ", "lat": 19.90 ,"lng": 99.832 },
  { "id": 46, "code": "58", "name": "แม่ฮ่องสอน", "region": "เหนือ", "lat": 19.302 ,"lng": 97.970 },
  { "id": 47, "code": "60", "name": "นครสวรรค์", "region": "กลาง", "lat": 15.703 ,"lng":100.128 },
  { "id": 48, "code": "61", "name": "อุทัยธานี", "region": "กลาง", "lat": 15.375 ,"lng":100.031 },
  { "id": 49, "code": "62", "name": "กำแพงเพชร", "region": "กลาง", "lat": 16.473 ,"lng":99.528 },
  { "id": 50, "code": "63", "name": "ตาก", "region": "ตะวันตก", "lat": 16.862 ,"lng":99.129 },
  { "id": 51, "code": "64", "name": "สุโขทัย", "region": "กลาง", "lat": 17.012 ,"lng":99.822 },
  { "id": 52, "code": "65", "name": "พิษณุโลก", "region": "กลาง", "lat": 16.815 ,"lng":100.260 },
  { "id": 53, "code": "66", "name": "พิจิตร", "region": "กลาง", "lat": 16.443 ,"lng":100.343 },
  { "id": 54, "code": "67", "name": "เพชรบูรณ์", "region": "กลาง", "lat": 16.420 ,"lng":101.156 },
  { "id": 55, "code": "70", "name": "ราชบุรี", "region": "ตะวันตก", "lat": 13.535 ,"lng":99.820 },
  { "id": 56, "code": "71", "name": "กาญจนบุรี", "region": "ตะวันตก", "lat": 14.039 ,"lng":99.551 },
  { "id": 57, "code": "72", "name": "สุพรรณบุรี", "region": "กลาง", "lat": 14.471 ,"lng":100.116 },
  { "id": 58, "code": "73", "name": "นครปฐม", "region": "กลาง", "lat": 13.819 ,"lng":100.050 },
  { "id": 59, "code": "74", "name": "สมุทรสาคร", "region": "กลาง", "lat": 13.552 ,"lng":100.280 },
  { "id": 60, "code": "75", "name": "สมุทรสงคราม", "region": "กลาง", "lat": 13.400 ,"lng":99.994 },
  { "id": 61, "code": "76", "name": "เพชรบุรี", "region": "ตะวันตก", "lat": 13.097 ,"lng":99.949 },
  { "id": 62, "code": "77", "name": "ประจวบคีรีขันธ์", "region": "ตะวันตก", "lat": 11.805 ,"lng":99.784 },
  { "id": 63, "code": "80", "name": "นครศรีธรรมราช", "region": "ใต้", "lat": 8.408 ,"lng":99.972 },
  { "id": 64, "code": "81", "name": "กระบี่", "region": "ใต้", "lat": 8.090 ,"lng":98.905 },
  { "id": 65, "code": "82", "name": "พังงา", "region": "ใต้", "lat": 8.446 ,"lng":98.524 },
  { "id": 66, "code": "83", "name": "ภูเก็ต", "region": "ใต้", "lat": 7.888 ,"lng":98.394 },
  { "id": 67, "code": "84", "name": "สุราษฎร์ธานี", "region": "ใต้", "lat": 9.137 ,"lng":99.320 },
  { "id": 68, "code": "85", "name": "ระนอง", "region": "ใต้", "lat": 9.960 ,"lng":98.621 },
  { "id": 69, "code": "86", "name": "ชุมพร", "region": "ใต้", "lat": 10.498 ,"lng":99.179 },
  { "id": 70, "code": "90", "name": "สงขลา", "region": "ใต้", "lat": 7.198 ,"lng":100.596 },
  { "id": 71, "code": "91", "name": "สตูล", "region": "ใต้", "lat": 6.615 ,"lng":100.071 },
  { "id": 72, "code": "92", "name": "ตรัง", "region": "ใต้", "lat": 7.559 ,"lng":99.616 },
  { "id": 73, "code": "93", "name": "พัทลุง", "region": "ใต้", "lat": 7.625 ,"lng":100.085 },
  { "id": 74, "code": "94", "name": "ปัตตานี", "region": "ใต้", "lat": 6.876 ,"lng":101.251 },
  { "id": 75, "code": "95", "name": "ยะลา", "region": "ใต้", "lat": 6.547 ,"lng":101.280 },
  { "id": 76, "code": "96", "name": "นราธิวาส", "region": "ใต้", "lat": 6.424 ,"lng":101.820 },
  { "id": 77, "code": "97", "name": "บึงกาฬ", "region": "ตะวันออกเฉียงเหนือ", "lat": 18.331 ,"lng":103.679 }
]

const PROVINCE = {



  list: list,
  
};

export { PROVINCE };