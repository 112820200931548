const COMPANY_BADGE_LIST = [
	{
		value:"Q Mark",
		code: "Q-MARK",
	},
	{
		value:"ISO",
		code: "ISO",
	},
	{
		value:"สหพันธ์การขนส่งทางบกแห่งประเทศไทย",
		code: "LFTF",
	},
	{
		value:"สมาคมขนส่งทางบกแห่งประเทศไทย นครสวรรค์",
		code: "LTA",
	},
	{
		value:"สมาคมขนส่งสินค้าเพื่อการนำเข้าและส่งออก",
		code: "IM-EX TA",
	},
	{
		value:"สมาคมขนส่งสินค้าและโลจิสติกส์ไทย",
		code: "TTLA",
	},
	{
		value:"สมาคมรถบรรทุกภาคตะวันตก",
		code: "WTA",
	},
	{
		value:"สมาคมขนส่งสินค้าจังหวัดตาก",
		code: "TTA",
	},
	{
		value:"สมาคมขนส่งสินค้าภาคอีสาน",
		code: "NTA",
	},
	{
		value:"สมาคมโลจิสติกส์และขนส่งภาคใต้",
		code: "ASLT",
	},
	{
		value:"สมาคมผู้ประกอบการขนส่งแหลมฉบังชลบุรี",
		code: "LCTA",
	},
	{
		value:"สมาคมการค้าโลจิสติกส์และผู้ค้าอาเซียน",
		code: "ASEANLP",
	},
	{
		value:"สมาพันธ์โลจิสติกส์ไทย ",
		code: "THAILOG",
	},
	{
		value:"สมาคมผู้รับจัดการขนส่งสินค้าระหว่างประเทศ ",
		code: "TIFFA",
	},
	{
		value:"สมาคมตัวแทนออกของรับอนุญาตไทย ",
		code: "TACBA",
	},
	{
		value:"สมาคมไทยโลจิสติกส์และการผลิต ",
		code: "TLAPS",
	},
	{
		value:"สมาคมชิปปิ้งแห่งประเทศไทย ",
		code: "CTAT",
	},
	{
		value:"สมาคมขนส่งสินค้าเร่งด่วนไทย",
		code: "TEXCA",
	},
	{
		value:"สมาคมขนส่งสินค้าภาคตะวันออก",
		code: "ETA",
	},
	{
		value:"ASEAN TRUCKING FEDERATION",
		code: "ATF",
	},
	{
		value:"สภาหอการค้าแห่งประเทศไทย",
		code: "TCH",
	},
	{
		value:"กรมพัฒนาธุรกิจการค้า กระทรวงพาณิชย์",
		code: "DBD",
	},
	{
		value:"กรมส่งเสริมการค้าระหว่างประเทศ กระทรวงพาณิชย์",
		code: "DITP",
	},
	{
		value:"กรมการขนส่งทางบก",
		code: "DLT",
	},
	{
		value:"กรมส่งเสริมอุตสาหกรรรม กระทรวงอุตสาหกรรม",
		code: "DIP",
	},

]

export { COMPANY_BADGE_LIST };