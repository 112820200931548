import { THEME } from '../styles';

import moment from 'moment';

require('moment/locale/th');
//## [INPUT] ISO date time format EX: 2018-06-27T13:50:46.720Z
const inputDateTimeFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSSZ';
//## [OUTPUT]
const outputDateTimeFormat = 'DD MMM YYYY, HH:mm';
const inputFilterDateFormat = 'MM/DD/YYYY';

const outputDateFormat = 'DD MMM YYYY';

function getDateTimeColumnType(){

    var ret = {
        // valueFormatter
        valueFormatter: function (params) {

            //## [DEBUG]
            // console.log('valueFormatter')
            // console.log(params);
            var dateTime = '-';
            if(params.value){

            	dateTime = moment(params.value,inputDateTimeFormat).format(outputDateTimeFormat);
            }
            
            // convert code to value
            return dateTime;
        },


        // specify we want to use the date filter
        filter: 'agDateColumnFilter',

        // add extra parameters for the date filter
        filterParams: {
            // provide comparator function
            comparator: function(filterLocalDateAtMidnight, cellValue) {
                // In the example application, dates are stored as dd/mm/yyyy
                // We create a Date object for comparison against the filter date
                if(cellValue !== '-'){
                    var cellDate = moment(cellValue,inputDateTimeFormat).startOf('day');
                    var filterDate = moment(filterLocalDateAtMidnight,inputFilterDateFormat);

                    //## [DEBUG]
                    // console.log('Date Column Filter Params')
                    // console.log(cellDate)
                    // console.log(filterDate)
                    // console.log(cellDate.isBefore(filterDate))

                    // Now that both parameters are Date objects, we can compare
                    if (cellDate.isBefore(filterDate)) {
                        return -1;
                    } else if (cellDate.isAfter(filterDate)) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
                else{
                    return -1;
                }
            },
            // browserDatePicker: true
        }
    }

    return ret;
}

function getEditableExpireDateColumnType(){

    var ret = {

        // [VIEW]valueFormatter
        valueFormatter: function (params) {

            //## [DEBUG]
            // console.log('valueFormatter')
            // console.log(params);
            var dateTime = '-';
            if(params.value){

                dateTime = moment(params.value,inputDateTimeFormat).format(outputDateFormat);
            }
            
            // convert code to value
            return dateTime;
        },

        //## [EDIT]
        valueParser: function(params){
            var dateTime = params.newValue;

            

            if(params.newValue){

                dateTime = moment(params.newValue,inputFilterDateFormat).format(inputDateTimeFormat);
            }

            if(dateTime === 'Invalid date'){
                dateTime = null;
            }

            // console.log('valueParser')
            // console.log('dateTime: ',dateTime)
            
            // convert code to value
            return dateTime;
        },

        //## Renderer
        cellRenderer: function(params){
            var fontColor = THEME.fontColor;

            var valueShow = '-'

            if(params.value){
                valueShow = moment(params.value,inputDateTimeFormat).format(outputDateFormat);
                //## [DEBUG]
                // console.log('companyStatusCellRenderer');
                // console.log(params);

                let inputValue = moment(params.value,inputDateTimeFormat);
                if(inputValue.isSameOrBefore()){
                    fontColor = THEME.errorFontColor;
                }
            }




            var ret = "<span style='"
            ret += "color: " + fontColor + ";' >";
            ret += valueShow + "</span>"


            return ret;
        },

        // specify we want to use the date filter
        filter: 'agDateColumnFilter',

        // add extra parameters for the date filter
        filterParams: {
            // provide comparator function
            comparator: function(filterLocalDateAtMidnight, cellValue) {
                // In the example application, dates are stored as dd/mm/yyyy
                // We create a Date object for comparison against the filter date
                if(cellValue !== '-'){
                    var cellDate = moment(cellValue,inputDateTimeFormat).startOf('day');
                    var filterDate = moment(filterLocalDateAtMidnight,inputFilterDateFormat);

                    //## [DEBUG]
                    // console.log('Date Column Filter Params')
                    // console.log(cellDate)
                    // console.log(filterDate)
                    // console.log(cellDate.isBefore(filterDate))

                    // Now that both parameters are Date objects, we can compare
                    if (cellDate.isBefore(filterDate)) {
                        return -1;
                    } else if (cellDate.isAfter(filterDate)) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
                else{
                    return -1;
                }
            },
            // browserDatePicker: true
        }
    }

    return ret;
}

export { 

    getDateTimeColumnType,
    getEditableExpireDateColumnType,
};