export const NOTIF_ACTION_TYPES = {

    SUBSCRIBE_NOTIFICATION: 'SUBSCRIBE_NOTIFICATION',
    UNSUBSCRIBE_NOTIFICATION: 'UNSUBSCRIBE_NOTIFICATION',

    SAVE_PLAYER_ID_REQUEST: 'SAVE_PLAYER_ID_REQUEST',
	SAVE_PLAYER_ID_SUCCESS: 'SAVE_PLAYER_ID_SUCCESS',
	SAVE_PLAYER_ID_FAILURE: 'SAVE_PLAYER_ID_FAILURE',

	GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_REQUEST: 'GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_REQUEST',
	GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_SUCCESS: 'GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_SUCCESS',
	GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_FAILURE: 'GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_FAILURE',
	GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_EMPTY: 'GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_EMPTY',


	SET_NOTIF_COUNTER: 'SET_NOTIF_COUNTER',
	RESET_NOTIF_COUNTER: 'RESET_NOTIF_COUNTER',

	REFRESH_JOB_LIST_NOTIFICATION_REQUEST: 'REFRESH_JOB_LIST_NOTIFICATION_REQUEST',
	REFRESH_JOB_LIST_NOTIFICATION_SUCCESS: 'REFRESH_JOB_LIST_NOTIFICATION_SUCCESS',
	
	SAVE_NOTIF_DATA_SUCCESS: 'SAVE_NOTIF_DATA_SUCCESS',
	SAVE_NOTIF_DATA_FAILURE: 'SAVE_NOTIF_DATA_FAILURE',


	GET_PAGINATE_NOTIF_LIST_REQUEST: 'GET_PAGINATE_NOTIF_LIST_REQUEST',
	GET_PAGINATE_NOTIF_LIST_SUCCESS: 'GET_PAGINATE_NOTIF_LIST_SUCCESS',
	GET_PAGINATE_NOTIF_LIST_SUCCESS_ADD_MORE: 'GET_PAGINATE_NOTIF_LIST_SUCCESS_ADD_MORE',
	GET_PAGINATE_NOTIF_LIST_FAILURE: 'GET_PAGINATE_NOTIF_LIST_FAILURE',
	GET_PAGINATE_NOTIF_LIST_EMPTY: 'GET_PAGINATE_NOTIF_LIST_EMPTY',

	COUNT_PAGINATE_NOTIF_LIST_REQUEST: 'COUNT_PAGINATE_NOTIF_LIST_REQUEST',
	COUNT_PAGINATE_NOTIF_LIST_SUCCESS: 'COUNT_PAGINATE_NOTIF_LIST_SUCCESS',
	COUNT_PAGINATE_NOTIF_LIST_FAILURE: 'COUNT_PAGINATE_NOTIF_LIST_FAILURE',
	COUNT_PAGINATE_NOTIF_LIST_EMPTY: 'COUNT_PAGINATE_NOTIF_LIST_EMPTY',


	UPDATE_ONE_NOTIF_INFO_REQUEST: 'UPDATE_ONE_NOTIF_INFO_REQUEST',
	UPDATE_ONE_NOTIF_INFO_SUCCESS: 'UPDATE_ONE_NOTIF_INFO_SUCCESS',
	UPDATE_ONE_NOTIF_INFO_FAILURE: 'UPDATE_ONE_NOTIF_INFO_FAILURE',
	
	UPDATE_ONE_NOTIF_STATUS_REQUEST: 'UPDATE_ONE_NOTIF_STATUS_REQUEST',
	UPDATE_ONE_NOTIF_STATUS_SUCCESS: 'UPDATE_ONE_NOTIF_STATUS_SUCCESS',
	UPDATE_ONE_NOTIF_STATUS_FAILURE: 'UPDATE_ONE_NOTIF_STATUS_FAILURE',

}