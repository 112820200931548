import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';



import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSave,
    faChevronRight,
    faChevronLeft,

} from '@fortawesome/free-solid-svg-icons';

import { 
	withMultipleStyles,
	// extractValues,
	lookupValue,
	// lookupKey,
	userStatusCellRenderer,
	// userTypeCellRenderer,
	getDateTimeColumnType,
	getEditableExpireDateColumnType,

	extractTruckTypeSelectInput,
	extractProvinceSelectInput,

} from '../../../utilities';
import { 
	layoutMuiStyles,

} from '../../../styles';
import { 
	APP_CONSTANTS,
	USER_STATUS,
	TRUCK_TYPE, 
	PROVINCE,
	// BANK_NAME,
} from '../../../constants';

import {
	TruckCargoInsuranceDocumentRenderer,
	TruckCargoInsuranceDocumentDialog,


} from '../containers';

import {
	truckListActions,
	editTruckActions,
} from '../actions';

import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';



class TruckListTableContainers extends React.Component {

	state = {

		isPrefetched: false,

		//##---------------- Table Pagination ---------------------------

		page: 0,
		limit: APP_CONSTANTS.DEFAULT_PAGINATION_PAGE_SIZE,
		sortBy: APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY,
		filterBy: {},

		//## Total Page possible to show
		totalPage: 0,
		rowPerPageArray: APP_CONSTANTS.DEFAULT_PAGINATION_ROW_PER_PAGE_ARRAY,

		disabledPrevPageBtn: true,
		disabledNextPageBtn: false,
		
		//##---------------- TruckTypeList ---------------------------
		truckTypeList: [],
		truckTypeListMapping: {},

		//##---------------- agGridReact ---------------------------

		context: { componentParent: this },
		frameworkComponents: {
			truckCargoInsuranceDocumentRenderer: TruckCargoInsuranceDocumentRenderer,
	    },



		defaultColDef: {
			// set every column width
	        width: 100,
	        // make every column editable
	        editable: true,
	        // make every column use 'text' filter by default
	        filter: 'agTextColumnFilter',
	        //## Column resizable
	        resizable: true,	
	        sortable: true,
		},
		columnTypes: {
			nonEditableColumn: {editable: false},
	        dateColumn: getDateTimeColumnType(),
	        editableDateColumn: getEditableExpireDateColumnType(),
		},

		columnDefs: [

			{
				field: "carrier.name",
				headerName: "ชื่อบริษัท",
				width: 150, 
				editable: false,
			},
			{
				field: "truckType.typeName",
				headerName: "ชนิดรถ", 
				width: 200,
				// editable: false,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
				    values: extractTruckTypeSelectInput(TRUCK_TYPE.list),
				    // cellRenderer: (params) => userStatusCellRenderer(params),
				},
			},
			{
				headerName: "ทะเบียนรถ",
        		children: [
					{
						field: "plateId",
						headerName: "ทะเบียน (แม่/หัว)",
						width: 120, 
					},
					{
						field: "plateIdProvince",
						headerName: "จังหวัดทะเบียน (แม่/หัว)",
						width: 150, 
						cellEditor: 'agSelectCellEditor',
						cellEditorParams: {
						    values: extractProvinceSelectInput(PROVINCE.list),
						    // cellRenderer: (params) => userStatusCellRenderer(params),
						},
					},
					{
						field: "childPlateId",
						headerName: "ทะเบียน (ลูก/หาง)",
						width: 120, 
					},
					{
						field: "childPlateIdProvince",
						headerName: "จังหวัดทะเบียน (ลูก/หาง)",
						width: 150,
						cellEditor: 'agSelectCellEditor',
						cellEditorParams: {
						    values: extractProvinceSelectInput(PROVINCE.list),
						    // cellRenderer: (params) => userStatusCellRenderer(params),
						}, 
					},
				]
			},

			{
				headerName: "พนักงานขับรถ",
        		children: [
					{
						field: "driver.name",
						headerName: "ชื่อคนขับ", 
						width: 200,
						editable: false,
					},
					{
						field: "driver.phoneNumber",
						headerName: "เบอร์โทรครขับ", 
						width: 200,
						editable: false,
					},
					{
						field: "driver.status",
						headerName: "สถานะคนขับ", 
						width: 200,
						editable: false,
						//## Cell Editor Mode
						// cellEditor: 'agSelectCellEditor',
						// cellEditorParams: {
						//     values: extractValues(USER_STATUS.mapping),
						//     cellRenderer: (params) => userStatusCellRenderer(params),
						// },
						
						
						//## See README.md part tutorial for more information
						
						//## Use a "valueFormatter" to Format Select Input 
						valueFormatter: function (params) {

							//## [DEBUG]
							// console.log('valueFormatter')
					    	// console.log(params);

					    	var code = parseInt(params.value,10);

					        // convert code to value
					        return lookupValue(USER_STATUS.mapping, code);
					    },

					    //## Convert String to int for save data
					    valueSetter: function (params) {

							//## [DEBUG]
							// console.log('valueSetter')
					    	// console.log(params);

					    	if (params.oldValue!==params.newValue) {
						        params.data['status'] = parseInt(params.newValue,10);
						        // get grid to refresh the cell
						        return true;
						    } else {
						        // no change, so no refresh needed
						        return false;
						    }
					    },
					    //## Use to render view output html 
					    cellRenderer: (params) => userStatusCellRenderer(params),

					    //# [Enterprise feature NEED]
					    // filter: 'agSetColumnFilter',
		            	// filterParams: {
		             //    	values: extractValues(USER_STATUS.mapping),
		             //    	cellRenderer: (params) => companyStatusCellRenderer(params)
		            	// },
					},
				]
			},
			{
				headerName: "ประกันสินค้าหลังกระบะ",
        		children: [
        			{
						field: "truckCargoInsuranceDocument",
						headerName: "เอกสารประกันสินค้า", 
						filter: false,
						resizable: false,
						sortable: false,
						editable: false,
						width: 200,
						cellRenderer: "truckCargoInsuranceDocumentRenderer",
					},
					// {
					// 	field: "haveCargoInsurance",
					// 	headerName: "มีประกันสินค้า", 
					// 	width: 100,
						
						
					// },
					{
						field: "cargoInsuranceCoverage",
						headerName: "วงเงินประกันสินค้า", 
						width: 200,

					},
					{
						field: "cargoInsuranceExpire",
						headerName: "วันหมดอายุใบประกันสิน้คา", 
						width: 300,
          				type: [
							'editableDateColumn', 
						],
					},
					
				]
			},


			

			{
				field: "createdAt",
				headerName: "วันที่สร้าง", 
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
			},
			{
				field: "updatedAt",
				headerName: "วันที่อัพเดต", 
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
				
			},

		],

		//## Raw data 
		rowData: [
			{_id: 555,phoneNumber: "Toyota",status: 0,authorizations: ['company-owner'], name: "Celica", nid: 35000, createdAt: '2019-05-12T01:29:15.647Z', updatedAt: '2019-05-23T01:29:15.647Z'},
			{_id: 666,phoneNumber: "Ford",status: 1,authorizations: ['company-owner','truck-truck-employee'], name: "Mondeo", nid: 32000},
			{_id: 777,phoneNumber: "Porsche",status: 2,authorizations: ['company-owner','truck-truck-employee'], name: "Boxter", nid: 72000}
		],

		//## Total data row in server database
		rowTotal: 0,

		editedData: [],

		//##------------------- Company Verify Document---------
		viewDocTruckId: null,

		isShowTruckCargoInsuranceDialog: false,

	}


    constructor(props) {
        super(props);

        this.onPrefetch = this.onPrefetch.bind(this);

        // this.agGridApi = this.agGridApi.bind(this);
        // this.agGridColumnApi = this.agGridColumnApi.bind(this);
        this.onEditOneCell = this.onEditOneCell.bind(this);
        this.onSaveEdit = this.onSaveEdit.bind(this);
        this.onResetEditedData = this.onResetEditedData.bind(this);

        this.onClickPrevPage = this.onClickPrevPage.bind(this);
		this.onClickNextPage = this.onClickNextPage.bind(this);
		this.onDisablePrevNextPageButton = this.onDisablePrevNextPageButton.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);

        this.truckCargoInsuranceDocDialogRef = React.createRef();
        this.onViewTruckCargoInsuranceDocument = this.onViewTruckCargoInsuranceDocument.bind(this);
		this.onCloseTruckCargoInsuranceDialog = this.onCloseTruckCargoInsuranceDialog.bind(this);
    }

    //##----------------------------------------------
    static getDerivedStateFromProps(nextProps, prevState){
    		
    	let retState = null

    	let {
    		truckTypeList,
    		truckList,
    		truckListTotalNum,
    	} = nextProps;



    	let newList = truckList;
    	let newListTotalNum = truckListTotalNum;

    	//## Update TruckType List to use internal table
    	if(truckTypeList){
    		if(truckTypeList.length > 0){
    			let newTruckTypeString = JSON.stringify(truckTypeList);
    			let oldTruckTypeString = JSON.stringify(prevState.truckTypeList);
    			if(newTruckTypeString !== oldTruckTypeString){
    				let newTruckTypeListMapping = {};
    				for(var i = 0; i < truckTypeList.length; i++){
    					newTruckTypeListMapping[truckTypeList[i]._id] = truckTypeList[i].typeName;
    				}



    				retState ={
    					...retState,
    					truckTypeList: truckTypeList,
    					truckTypeListMapping: newTruckTypeListMapping,


    				}
    			}
    		}
    	}


    	if(newList){
    		if(newList.length > 0){
    			let newString = JSON.stringify(newList);
    			let oldString = JSON.stringify(prevState.rowData);

    			if(newString !== oldString){
    				retState = {
    					...retState,
    					rowData: newList,
    				}
    			}
    		}
	    }

	    if(newListTotalNum !== prevState.rowTotal){
	    	let totalPage = 0;
	    	let limit = prevState.limit;


	    	let disabledPrevPageBtn = true;
	    	let disabledNextPageBtn = false;

	    	let totalRowInOnePage = prevState.limit;

	    	if(limit > 0){
	    		totalPage = Math.ceil(newListTotalNum / limit)
	    	}
	    	else{
	    		totalPage = 0;
	    	}

	    	if(newListTotalNum <= totalRowInOnePage){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= true;
	    	}

	    	retState = {
	    		...retState,
	    		rowTotal: newListTotalNum,
	    		totalPage: totalPage,
	    		disabledPrevPageBtn: disabledPrevPageBtn,
	    		disabledNextPageBtn: disabledNextPageBtn,
	    	}
	    }





    	return retState;
    }


    componentDidMount(){
    	this.onPrefetch();
    }

    componentDidUpdate(prevProps,prevState){
    	// if(JSON.stringify(prevState.rowData) !== JSON.stringify(this.state.rowData)){
    	// 	console.log('Component Did Update Row Data: ')
    	// 	console.log(this.state.rowData);
    	// }

    	//## Change Limit -> disable next/prev button of table
    	// if(prevState.limit !== this.state.limit){
    	// 	this.onDidUpdateLimit();
    	// }
    	
    }

    //##----------------------------------------------
    async onPrefetch(): Promise<void>{
    	const { dispatch } = this.props;

    	let {
    		page,
    		limit,
    		sortBy,
    		filterBy,
    	} = this.state;


    	await dispatch(spinnerActions.showSpinner());

    	let truckList = await dispatch(
    		truckListActions.getPaginateTruckList(
    			page,
    			limit,
    			sortBy,
    		)
    	);



    	let truckTotalNum = await dispatch(
    		truckListActions.countPaginateTruckList(
    			filterBy
    		)
    	);

    	if(this.state.isPrefetched === false){
    		await this.setState({
    			isPrefetched: true,
    		})
    	}

    	await dispatch(spinnerActions.hideSpinner());


    	if(!truckList || !truckTotalNum){
    		let errorMsg = this.props.truckError.join('\n');

    		await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )

    	}





    	return Promise.resolve();
    }


    //##----------------------------------------------
    onGridReady = (params) => {
    	this.agGridApi = params.api;
    	this.agGridColumnApi = params.columnApi;
    }

    onEditOneCell = (params) => {
    	// notice that the data always contains the keys rather than values after editing
	       
    	//## [DEBUG]
    	// console.log("onEditOneCell: ", params);

    	let oneRowData = {...params.data};
    	let rowId = oneRowData._id;
    	let editColumnName = params.column ? params.column.colId : null;

    	if(rowId && editColumnName){

    		let {
    			editedData
    		} = this.state;

    		//## [DEBUG]
    		// console.log("OneRowEditData: ", oneRowData);
    		// console.log("rowId: ", rowId);
    		// console.log("editColumnName: ", editColumnName);
    		// console.log("newValue: ", params.newValue);

    		if(editColumnName === 'truckType.typeName'){
    			let {
    				truckTypeList
    			} = this.props;

    			let newTruckTypeId = null;
    			if(truckTypeList){
    				if(truckTypeList.length > 0) {
    					for(var k = 0; k < truckTypeList.length; k++){
    						if(truckTypeList[k].typeName === params.newValue){
    							newTruckTypeId = truckTypeList[k]._id;
    							break;
    						}
    					}
    				}
    			}

    			if(newTruckTypeId){
    				oneRowData.newTruckTypeId = newTruckTypeId;
    			}


    			//## [DEBUG]
    			// console.log("newTruckTypeId: ", newTruckTypeId);
    			// console.log("oneRowData: ", oneRowData);
    		}

    		//## loop update edit Data
    		let isAlreadyEdited = false;
    		for(var i =0; i < editedData.length; i++){
    			if(editedData[i]._id === rowId){
    				editedData[i] = oneRowData;
    				isAlreadyEdited = true;
    				break;
    			}
    		}

    		if(!isAlreadyEdited){
    			editedData.push(oneRowData);
    		}

    		//## [DEBUG]
    		// console.log('editedData: ',editedData);

    		this.setState({
    			editedData: editedData,
    		})

    	}
    }

    onSaveEdit = async() => {
    	const { dispatch } = this.props;
    	let {
    		editedData
    	} = this.state;

    	if(editedData.length > 0){

	    	await dispatch(spinnerActions.showSpinner());

	    	const promises = editedData.map( async(oneRow) =>  {


	    		let isSuccess = await dispatch(
	    			editTruckActions.editOneTruckInfo(
	    				oneRow
	    			)
	    		)

	    		if(isSuccess){
	    			return null;
	    		}
	    		else{
	    			let errorMsg = this.props.truckError.join('\n');

	    			return errorMsg;
	    		}
	    	})

	    	let updatedResultArray = await Promise.all(promises);
	    	let updatedResultArrayFiltered = await updatedResultArray.filter(v => v !== null);

	    	if(updatedResultArrayFiltered){
	    		if(updatedResultArrayFiltered.length > 0){
	    			let allErrorMsg = "";
	    			for(var i = 0; i < updatedResultArrayFiltered.length; i++){
	    				allErrorMsg += updatedResultArrayFiltered + '\n';
	    			}


	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                        allErrorMsg,
	                        ALERT_CONSTANTS.ERROR_MODE
	                    )
	                )
	    		}
	    		else{
	    			await this.onResetEditedData();

	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.SUCCESS_DIALOG_TITLE,
	                        APP_CONSTANTS.SUCCESS_EDIT_MESSAGE,
	                        ALERT_CONSTANTS.SUCCESS_MODE
	                    )
	                )
	    		}
	    	}
	    
	    	await this.onPrefetch();

	    	await dispatch(spinnerActions.hideSpinner());
	    }
	    else{
	    	await dispatch(alertActions.showAlert(
	                APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                'ไม่มีข้อมูลให้แก้ไข',
	                ALERT_CONSTANTS.ERROR_MODE
	            )
	        )
	    }


    	return Promise.resolve();
    }

    onResetEditedData = async() => {

    	await this.setState({
    		editedData: [],
    	})

    	return Promise.resolve();
    }

    //##----------------------------------------------




    onClickPrevPage = async() => {
    	let {
    		page,


    	} = this.state;

    	let nextPage = page - 1;

    	if(nextPage <= 0){
    		nextPage = 0;
    	}


    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();

    	return Promise.resolve();
    }

    onClickNextPage = async() => {
    	let {
    		page,
    		totalPage,
    	} = this.state;

    	let nextPage = page + 1;

    	if(nextPage >= (totalPage-1)){
    		nextPage = totalPage-1;
    	}

    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();
    	

    	return Promise.resolve();
    }

    onDisablePrevNextPageButton = () => {
    	let {
    		page,
    		limit,
    		totalPage,
    		disabledPrevPageBtn,
    		disabledNextPageBtn,

    		rowTotal
    	} = this.state;

    	//## [DEBUG]
    	// console.log('--onDisablePrevNextPageButton:---')
    	// console.log('page: ', page);
    	// console.log('totalPage: ', totalPage);


    	if(rowTotal <= limit){
    		disabledPrevPageBtn = true;
			disabledNextPageBtn	= true;
    	}
    	else{
    		if(page <= 0){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= false;
	    	}
	    	else if(page >= (totalPage-1)){
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= true;
	    	}
	    	else{
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= false;
	    	}
    	}

    	//## [DEBUG]
    	// console.log('disabledPrevPageBtn: ', disabledPrevPageBtn)
    	// console.log('disabledNextPageBtn: ', disabledNextPageBtn)
		

    	this.setState({
    		disabledPrevPageBtn: disabledPrevPageBtn,
    		disabledNextPageBtn: disabledNextPageBtn,
    	})
    }

    onChangeLimit = async(event) => {

    	let {

    		page,
    		totalPage,
    		
    	} = this.state;

    	let newLimit = parseInt(event.target.value,10);

    	//## {DEBUG}

    	// console.log('newLimit: ' + newLimit);
    	// console.log('currentLimit: ' + this.state.limit);

    	if(newLimit !== this.state.limit){
    		//## Reset page when change new limit
    		page = 0;
	    	 
	    }

    	await this.setState({
    		page: page,
    		limit: newLimit,
    	})


    	await this.onPrefetch();


    	let {
    		rowTotal,
    	} = this.state;

    	//## Calculate new total page after update limit and rowTotal
    	if(newLimit > 0){
    		totalPage = Math.ceil(rowTotal / newLimit)
    	}
    	else{
    		totalPage = 0;
    	}

    	await this.setState({
    		totalPage: totalPage,
    	})


    	await this.onDisablePrevNextPageButton();



    	return Promise.resolve();
    }

    //##----------------------------------------------
    onViewTruckCargoInsuranceDocument = (truckId) => {
    	//## [DEBUG]
    	// console.log('onViewTruckCargoInsuranceDocument: '+ truckId);
    	this.setState({
    		isShowTruckCargoInsuranceDialog: true,
    		viewDocTruckId: truckId,
    	})
    }


    onCloseTruckCargoInsuranceDialog = () => {
    	this.setState({
    		isShowTruckCargoInsuranceDialog: false,
    		viewDocTruckId: null,
    	})
    }

    


    //##----------------------------------------------

    render() {
        const { classes } = this.props;

        return (
        	<Grid container spacing={0}>
        		<Grid 
        			item 
        			xs={12} 
        			className={classes.agGridContainer}
        		>
        			<Grid container spacing={0}>
        				<Grid 
		        			item 
		        			xs={12} 
		        			className={classes.agGridTableToolbarContainer}
		        		>
		        			<Grid 
		        				container 
		        				spacing={16}
		        				direction="row"
							    justify="flex-end"
							    alignItems="center"
		        			>
		        				
							    <Grid item >
							    	<Typography 
			                        	variant="h6" 
			                        >
							        	{'จำนวนแถว: '}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	
			                    	<Select
							            value={this.state.limit}
							            onChange={this.onChangeLimit}
							            inputProps={{
							              	name: 'rowPerpage',
							              	id: 'tab-page-size',
							            }}
							        >
							            {this.state.rowPerPageArray.map(name => (
							              	<MenuItem 
							              		key={name} 
							              		value={name} 
							              	>
							                	{name}
							              	</MenuItem>
							            ))}
							        </Select>
							    </Grid>
							    <Grid item >
			                    	<Typography 
			                        	variant="body1" 
			                        >
			                        	
							        	{(this.state.limit * this.state.page) + 1}
							        	{'-'}
							        	{(this.state.limit * (this.state.page + 1))}
							        	{'   from total:  '}
							        	{this.state.rowTotal}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickPrevPage()} 
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledPrevPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronLeft}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />

			                        </Button>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickNextPage()}
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledNextPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronRight}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                        </Button>
							    </Grid>
							    <Grid item >
				        			<Button 
				        				onClick={() => this.onSaveEdit()} 
				        				variant={'contained'}
				        				color="primary"
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faSave}
		                                    className={
		                                    	classNames(
		                                    		classes.leftIcon, 
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                            {'Save'}
			                        </Button>
			                        
			                    </Grid>
		                    </Grid>
		        		</Grid>
        			</Grid>
		        	<div
						className={classNames(
							"ag-theme-balham",
		                    classes.agGridTable, 
		                )}
					>
						<AgGridReact
							reactNext={true}
							onGridReady={this.onGridReady}
							context={this.state.context}
							frameworkComponents={this.state.frameworkComponents}
							defaultColDef={this.state.defaultColDef}
							columnTypes={this.state.columnTypes}
							floatingFilter={true}
							columnDefs={this.state.columnDefs}
							rowData={this.state.rowData}
							onCellValueChanged={this.onEditOneCell}
						>
						</AgGridReact>
					</div>
				
					<TruckCargoInsuranceDocumentDialog 
						ref={this.truckCargoInsuranceDocDialogRef}
						isVisible={this.state.isShowTruckCargoInsuranceDialog}
						handleClose={this.onCloseTruckCargoInsuranceDialog}
						truckId={this.state.viewDocTruckId}
					/>
					
				</Grid>
			</Grid>
        )
    }

}






const mapStateToProps = (state) => ({
	truckError: state.trucks.error,

	truckTypeList: state.trucks.truckTypeList,

	truckList: state.trucks.truckList,
	truckListTotalNum: state.trucks.truckListTotalNum,

})


TruckListTableContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(TruckListTableContainers);

const connectedContainers = connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as TruckListTableContainers };