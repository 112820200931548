import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';



import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    // faSave,
    faChevronRight,
    faChevronLeft,

} from '@fortawesome/free-solid-svg-icons';

import { 
	withMultipleStyles,
	extractValues,
	lookupValue,
	// lookupKey,
	notifStatusCellRenderer,
	// notifTypeCellRenderer,

	getDateTimeColumnType,
} from '../../../utilities';
import { 
	layoutMuiStyles,

} from '../../../styles';
import { 
	APP_CONSTANTS,
	NOTIF_STATUS,
	// NOTIF_TYPE,
} from '../../../constants';

// import {

// } from '../containers';

import {
	notifListActions,
	editNotifActions,
} from '../actions';



import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';



class NotifListTableContainers extends React.Component {

	state = {

		isPrefetched: false,

		//##---------------- Table Pagination ---------------------------

		page: 0,
		limit: APP_CONSTANTS.DEFAULT_PAGINATION_PAGE_SIZE,
		sortBy: APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY,
		filterBy: {},

		//## Total Page possible to show
		totalPage: 0,
		rowPerPageArray: APP_CONSTANTS.DEFAULT_PAGINATION_ROW_PER_PAGE_ARRAY,

		disabledPrevPageBtn: true,
		disabledNextPageBtn: false,
		

		//##---------------- agGridReact ---------------------------

		context: { componentParent: this },
		frameworkComponents: {

	    },

		defaultColDef: {
			// set every column width
	        width: 100,
	        // make every column editable
	        editable: true,
	        // make every column use 'text' filter by default
	        filter: 'agTextColumnFilter',
	        //## Column resizable
	        resizable: true,	
	        sortable: true,
		},
		columnTypes: {
			nonEditableColumn: {editable: false},
	        dateColumn: getDateTimeColumnType()
		},

		columnDefs: [

			{
				field: "_id",
				headerName: "Notif Id",
				width: 150, 
				editable: false,
				// pinned: 'left',
			},
			{
				field: "status",
				headerName: "สถานะ [0=new/1=read/2=finished]", 
				width: 200,

				//## Cell Editor Mode
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
				    values: extractValues(NOTIF_STATUS.mapping),
				    cellRenderer: (params) => notifStatusCellRenderer(params),
				},
				
				
				//## See README.md part tutorial for more information
				
				//## Use a "valueFormatter" to Format Select Input 
				valueFormatter: function (params) {

					//## [DEBUG]
					// console.log('valueFormatter')
			    	// console.log(params);

			    	var code = parseInt(params.value,10);

			        // convert code to value
			        return lookupValue(NOTIF_STATUS.mapping, code);
			    },

			    //## Convert String to int for save data
			    valueSetter: function (params) {

					//## [DEBUG]
					// console.log('valueSetter')
			    	// console.log(params);

			    	if (params.oldValue!==params.newValue) {
				        params.data['status'] = parseInt(params.newValue,10);
				        // get grid to refresh the cell
				        return true;
				    } else {
				        // no change, so no refresh needed
				        return false;
				    }
			    },
			    //## Use to render view output html 
			    cellRenderer: (params) => notifStatusCellRenderer(params),

			    //# [Enterprise feature NEED]
			    // filter: 'agSetColumnFilter',
            	// filterParams: {
             //    	values: extractValues(NOTIF_STATUS.mapping),
             //    	cellRenderer: (params) => notifStatusCellRenderer(params)
            	// },
			},
			// {
			// 	field: "read",
			// 	headerName: "อ่านแล้ว", 
			// 	width: 200,
			// 	//## Cell Editor Mode
			// 	cellEditor: 'agSelectCellEditor',
			// 	cellEditorParams: {
			// 	    values: extractValues(NOTIF_TYPE.mapping),
			// 	    cellRenderer: (params) => notifTypeCellRenderer(params),
			// 	},

			// 	//## Use to Format Select Input 
			// 	valueFormatter: function (params) {

			//         // convert code to value
			//         return lookupValue(NOTIF_TYPE.mapping, params.value);
			//     },
				
			// 	//## Use to render view output html 	
			//     cellRenderer: (params) => notifTypeCellRenderer(params),

			// },
			{
				field: "remark",
				headerName: "Remark", 
				width: 200,
				
			},
			{
				field: "handleBy",
				headerName: "จัดการโดย", 
				width: 200,
				
			},
			{
				field: "mode",
				headerName: "mode", 
				width: 200,
				editable: false,
			},
			{
				field: "title",
				headerName: "title", 
				width: 200,
				editable: false,
			},
			{
				field: "body",
				headerName: "body", 
				width: 500,
				editable: false,
			},
			// {
			// 	field: "data",
			// 	headerName: "data", 
			// 	width: 200,
			// 	editable: false,
			// },
			
			{
				field: "createdAt",
				headerName: "วันที่สร้าง", 
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
			},
			{
				field: "updatedAt",
				headerName: "วันที่อัพเดต", 
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
				
			},
		],

		//## Raw data 
		rowData: [
			
		],

		//## Total data row in server database
		rowTotal: 0,

		editedData: [],






	}


    constructor(props) {
        super(props);

        this.onPrefetch = this.onPrefetch.bind(this);

        // this.agGridApi = this.agGridApi.bind(this);
        // this.agGridColumnApi = this.agGridColumnApi.bind(this);
        this.onEditOneCell = this.onEditOneCell.bind(this);
        this.onSaveEdit = this.onSaveEdit.bind(this);
        this.onResetEditedData = this.onResetEditedData.bind(this);

        this.onClickPrevPage = this.onClickPrevPage.bind(this);
		this.onClickNextPage = this.onClickNextPage.bind(this);
		this.onDisablePrevNextPageButton = this.onDisablePrevNextPageButton.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);

    }

    //##----------------------------------------------
    static getDerivedStateFromProps(nextProps, prevState){
    		
    	let retState = null

    	let {
    		notifList,
    		notifListTotalNum,
    	} = nextProps;

    	let newList = notifList;
    	let newListTotalNum = notifListTotalNum;


    	if(newList){
    		if(newList.length > 0){
    			let newString = JSON.stringify(newList);
    			let oldString = JSON.stringify(prevState.rowData);

    			if(newString !== oldString){
    				retState = {
    					...retState,
    					rowData: newList,
    				}
    			}
    		}
	    }

	    if(newListTotalNum !== prevState.rowTotal){
	    	let totalPage = 0;
	    	let limit = prevState.limit;


	    	let disabledPrevPageBtn = true;
	    	let disabledNextPageBtn = false;

	    	let totalRowInOnePage = prevState.limit;

	    	if(limit > 0){
	    		totalPage = Math.ceil(newListTotalNum / limit)
	    	}
	    	else{
	    		totalPage = 0;
	    	}

	    	if(newListTotalNum <= totalRowInOnePage){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= true;
	    	}

	    	retState = {
	    		...retState,
	    		rowTotal: newListTotalNum,
	    		totalPage: totalPage,
	    		disabledPrevPageBtn: disabledPrevPageBtn,
	    		disabledNextPageBtn: disabledNextPageBtn,
	    	}
	    }





    	return retState;
    }


    componentDidMount(){
    	this.onPrefetch();
    }

    componentDidUpdate(prevProps,prevState){
    	// if(JSON.stringify(prevState.rowData) !== JSON.stringify(this.state.rowData)){
    	// 	console.log('Component Did Update Row Data: ')
    	// 	console.log(this.state.rowData);
    	// }

    	//## Change Limit -> disable next/prev button of table
    	// if(prevState.limit !== this.state.limit){
    	// 	this.onDidUpdateLimit();
    	// }
    	
    }

    //##----------------------------------------------
    async onPrefetch(){
    	const { dispatch } = this.props;

    	let {
    		page,
    		limit,
    		sortBy,
    		filterBy,
    	} = this.state;


    	await dispatch(spinnerActions.showSpinner());

    	let notifList = await dispatch(
    		notifListActions.getPaginateNotifList(
    			page,
    			limit,
    			sortBy,
    		)
    	);



    	let notifTotalNum = await dispatch(
    		notifListActions.countPaginateNotifList(
    			filterBy
    		)
    	);

    	if(this.state.isPrefetched === false){
    		await this.setState({
    			isPrefetched: true,
    		})
    	}

    	await dispatch(spinnerActions.hideSpinner());


    	if(!notifList || !notifTotalNum){
    		let errorMsg = this.props.notifError.join('\n');

    		await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )

    	}





    	return Promise.resolve();
    }


    //##----------------------------------------------
    onGridReady = (params) => {
    	this.agGridApi = params.api;
    	this.agGridColumnApi = params.columnApi;
    }

    onEditOneCell = async(params) => {
    	const { dispatch } = this.props;
    	// notice that the data always contains the keys rather than values after editing
	       
    	//## [DEBUG]
    	// console.log("onEditOneCell: ", params);

    	let oneRowData = {...params.data};
    	let rowId = oneRowData._id;
    	let editColumnName = params.column ? params.column.colId : null;

    	if(rowId && editColumnName){

    		// let {
    		// 	editedData
    		// } = this.state;

    		//## [DEBUG]
    		// console.log("params: ", params);
    		// console.log("OneRowEditData: ", oneRowData);
    		// console.log("rowId: ", rowId);
    		// console.log("editColumnName: ", editColumnName);


    		//## Auto save data
    		let isSuccess = false;
    		if(editColumnName === 'status'){
    			let statusData = {
	    				_id: rowId,
	    				status: params.newValue,
	    				read: true,
	    			}
    			isSuccess = await dispatch(
	    			editNotifActions.editOneNotifStatus(
	    				statusData
	    			)
	    		)
    		}
    		else{
    			let updateData = {
	    			_id: rowId,
	    			handleBy: oneRowData.handleBy,
	    			remark: oneRowData.remark,
	    		}


	    		isSuccess = await dispatch(
	    			editNotifActions.editOneNotifInfo(
	    				updateData
	    			)
	    		)
    		}

    		if(!isSuccess){

	    		let errorMsg = this.props.notifError.join('\n');

	    		await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )
	    		
    		}
    		else{

    			// await dispatch(spinnerActions.showSpinner());

    			// await this.onPrefetch();

    			// await dispatch(spinnerActions.hideSpinner());
    		}


    		//##---------------- Old Save Data ------------
    		// //## loop update edit Data
    		// let isAlreadyEdited = false;
    		// for(var i =0; i < editedData.length; i++){
    		// 	if(editedData[i]._id === rowId){
    		// 		editedData[i] = oneRowData;
    		// 		isAlreadyEdited = true;
    		// 		break;
    		// 	}
    		// }

    		// if(!isAlreadyEdited){
    		// 	editedData.push(oneRowData);
    		// }

    		// //## [DEBUG]
    		// // console.log('editedData: ',editedData);

    		// this.setState({
    		// 	editedData: editedData,
    		// })

    	}
    }

    onSaveEdit = async() => {
    	const { dispatch } = this.props;
    	let {
    		editedData,
    	} = this.state;


    	await dispatch(spinnerActions.showSpinner());

    	if(editedData.length > 0){


	    	const promises = editedData.map( async(oneRow) =>  {
	    		let isSuccess = false;
	    		if(typeof oneRow.status !== 'undefined'){
	    			let statusData = {
	    				_id: oneRow._id,
	    				status: oneRow.status,
	    				read: oneRow.read,
	    			}
	    			isSuccess = await dispatch(
		    			editNotifActions.editOneNotifStatus(
		    				statusData
		    			)
		    		)
	    		}

	    		let updateData = {
	    			_id: oneRow._id,
	    			handleBy: oneRow.handleBy,
	    			remark: oneRow.remark,
	    		}

	    		isSuccess = await dispatch(
	    			editNotifActions.editOneNotifInfo(
	    				updateData
	    			)
	    		)

	    		if(isSuccess){
	    			return null;
	    		}
	    		else{
	    			let errorMsg = this.props.notifError.join('\n');

	    			return errorMsg;
	    		}
	    	})

	    	let updatedResultArray = await Promise.all(promises);
	    	let updatedResultArrayFiltered = await updatedResultArray.filter(v => v !== null);

	    	if(updatedResultArrayFiltered){
	    		if(updatedResultArrayFiltered.length > 0){
	    			let allErrorMsg = "";
	    			for(var i = 0; i < updatedResultArrayFiltered.length; i++){
	    				allErrorMsg += updatedResultArrayFiltered + '\n';
	    			}


	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                        allErrorMsg,
	                        ALERT_CONSTANTS.ERROR_MODE
	                    )
	                )
	    		}
	    		else{

	    			await this.onResetEditedData();

	    			await dispatch(alertActions.showAlert(
	                        APP_CONSTANTS.SUCCESS_DIALOG_TITLE,
	                        APP_CONSTANTS.SUCCESS_EDIT_MESSAGE,
	                        ALERT_CONSTANTS.SUCCESS_MODE
	                    )
	                )
	    		}
	    	}

	    	await this.onPrefetch();

	    	
	    }
	    else{
	    	await dispatch(alertActions.showAlert(
	                APP_CONSTANTS.ERROR_DIALOG_TITLE,
	                'ไม่มีข้อมูลให้แก้ไข',
	                ALERT_CONSTANTS.ERROR_MODE
	            )
	        )
	    }



	    await dispatch(spinnerActions.hideSpinner());


    	return Promise.resolve();
    }

    onResetEditedData = async() => {

    	await this.setState({
    		editedData: [],
    	})

    	return Promise.resolve();
    }



    //##----------------------------------------------




    onClickPrevPage = async() => {
    	let {
    		page,


    	} = this.state;

    	let nextPage = page - 1;

    	if(nextPage <= 0){
    		nextPage = 0;
    	}


    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();

    	return Promise.resolve();
    }

    onClickNextPage = async() => {
    	let {
    		page,
    		totalPage,
    	} = this.state;

    	let nextPage = page + 1;

    	if(nextPage >= (totalPage-1)){
    		nextPage = totalPage-1;
    	}

    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();
    	

    	return Promise.resolve();
    }

    onDisablePrevNextPageButton = () => {
    	let {
    		page,
    		limit,
    		totalPage,
    		disabledPrevPageBtn,
    		disabledNextPageBtn,

    		rowTotal
    	} = this.state;

    	//## [DEBUG]
    	// console.log('--onDisablePrevNextPageButton:---')
    	// console.log('page: ', page);
    	// console.log('totalPage: ', totalPage);


    	if(rowTotal <= limit){
    		disabledPrevPageBtn = true;
			disabledNextPageBtn	= true;
    	}
    	else{
    		if(page <= 0){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= false;
	    	}
	    	else if(page >= (totalPage-1)){
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= true;
	    	}
	    	else{
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= false;
	    	}
    	}

    	//## [DEBUG]
    	// console.log('disabledPrevPageBtn: ', disabledPrevPageBtn)
    	// console.log('disabledNextPageBtn: ', disabledNextPageBtn)
		

    	this.setState({
    		disabledPrevPageBtn: disabledPrevPageBtn,
    		disabledNextPageBtn: disabledNextPageBtn,
    	})
    }

    onChangeLimit = async(event) => {

    	let {

    		page,
    		totalPage,
    		
    	} = this.state;

    	let newLimit = parseInt(event.target.value,10);

    	//## {DEBUG}

    	// console.log('newLimit: ' + newLimit);
    	// console.log('currentLimit: ' + this.state.limit);

    	if(newLimit !== this.state.limit){
    		//## Reset page when change new limit
    		page = 0;
	    	 
	    }

    	await this.setState({
    		page: page,
    		limit: newLimit,
    	})


    	await this.onPrefetch();


    	let {
    		rowTotal,
    	} = this.state;

    	//## Calculate new total page after update limit and rowTotal
    	if(newLimit > 0){
    		totalPage = Math.ceil(rowTotal / newLimit)
    	}
    	else{
    		totalPage = 0;
    	}

    	await this.setState({
    		totalPage: totalPage,
    	})


    	await this.onDisablePrevNextPageButton();



    	return Promise.resolve();
    }




    //##----------------------------------------------

    render() {
        const { classes } = this.props;

        return (
        	<Grid container spacing={0}>
        		<Grid 
        			item 
        			xs={12} 
        			className={classes.agGridContainer}
        		>
        			<Grid container spacing={0}>
        				<Grid 
		        			item 
		        			xs={12} 
		        			className={classes.agGridTableToolbarContainer}
		        		>
		        			<Grid 
		        				container 
		        				spacing={16}
		        				direction="row"
							    justify="flex-end"
							    alignItems="center"
		        			>
		        				
							    <Grid item >
							    	<Typography 
			                        	variant="h6" 
			                        >
							        	{'จำนวนแถว: '}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	
			                    	<Select
							            value={this.state.limit}
							            onChange={this.onChangeLimit}
							            inputProps={{
							              	name: 'rowPerpage',
							              	id: 'tab-page-size',
							            }}
							        >
							            {this.state.rowPerPageArray.map(name => (
							              	<MenuItem 
							              		key={name} 
							              		value={name} 
							              	>
							                	{name}
							              	</MenuItem>
							            ))}
							        </Select>
							    </Grid>
							    <Grid item >
			                    	<Typography 
			                        	variant="body1" 
			                        >
			                        	
							        	{(this.state.limit * this.state.page) + 1}
							        	{'-'}
							        	{(this.state.limit * (this.state.page + 1))}
							        	{'   from total:  '}
							        	{this.state.rowTotal}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickPrevPage()} 
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledPrevPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronLeft}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />

			                        </Button>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickNextPage()}
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledNextPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronRight}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                        </Button>
							    </Grid>
							    {/*
							    <Grid item >
				        			<Button 
				        				onClick={() => this.onSaveEdit()} 
				        				variant={'contained'}
				        				color="primary"
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faSave}
		                                    className={
		                                    	classNames(
		                                    		classes.leftIcon, 
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                            {'Save'}
			                        </Button>
			                        
			                    </Grid>
				                */}
		                    </Grid>
		        		</Grid>
        			</Grid>
		        	<div
						className={classNames(
							"ag-theme-balham",
		                    classes.agGridTable, 
		                )}
					>
						<AgGridReact
							reactNext={true}
							onGridReady={this.onGridReady}
							context={this.state.context}
							frameworkComponents={this.state.frameworkComponents}
							defaultColDef={this.state.defaultColDef}
							columnTypes={this.state.columnTypes}
							floatingFilter={true}
							columnDefs={this.state.columnDefs}
							rowData={this.state.rowData}
							onCellValueChanged={this.onEditOneCell}
						>
						</AgGridReact>
					</div>
					
				</Grid>
			</Grid>
        )
    }

}






const mapStateToProps = (state) => ({
	notifError: state.notif.error,

	notifList: state.notif.notificationList,
	notifListTotalNum: state.notif.notificationListTotalNum,

})


NotifListTableContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(NotifListTableContainers);

const connectedContainers = connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as NotifListTableContainers };