export const AUTH_ACTION_TYPES = {

	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_EMPTY: 'LOGIN_EMPTY',

	INIT_SAVE_PHONENUMBER: 'INIT_SAVE_PHONENUMBER',

	LOGOUT_REQUEST: 'LOGOUT_REQUEST',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	LOGOUT_FAILURE: 'LOGOUT_FAILURE',
}