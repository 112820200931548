const list = [
	{  
		"typeName" : "6 ล้อคอก 5.5 เมตร",
		"category" : "6ล้อ"
	},
	{  
		"typeName" : "6 ล้อคอก 6.5 เมตร",
		"category" : "6ล้อ"
	},
	{  
		"typeName" : "6 ล้อคอก 7 เมตร",
		"category" : "6ล้อ"
	},
	{  
		"typeName" : "6 ล้อตู้ทึบ 5.5 เมตร",
		"category" : "6ล้อ"
	},
	{  
		"typeName" : "6 ล้อตู้ทึบ 6.5 เมตร",
		"category" : "6ล้อ"
	},
	{  
		"typeName" : "6 ล้อตู้ทึบ 7 เมตร",
		"category" : "6ล้อ"
	},
	{  
		"typeName" : "6 ล้อพื้นเรียบ",
		"category" : "6ล้อ"
	},
	{  
		"typeName" : "10 ล้อคอกสูง",
		"category" : "10ล้อ"
	},
	{  
	    "typeName" : "10 ล้อคอกตํ่า",
		"category" : "10ล้อ"
	},
	{  
	    "typeName" : "10 ล้อคอกสูงดัมพ์",
		"category" : "10ล้อ"
	},
	{  
	    "typeName" : "10 ล้อคอกตํ่าดัมพ์",
		"category" : "10ล้อ"
	},
	{  
	    "typeName" : "10 ล้อตู้ทึบ 7 เมตร",
		"category" : "10ล้อ"
	},
	{  
	    "typeName" : "10 ล้อพื้นเรียบ",
		"category" : "10ล้อ"
	},
	{  
	    "typeName" : "พ่วงคอกสูง",
		"category" : "รถพ่วง"
	},
	{  
	    "typeName" : "พ่วงคอกตํ่า",
		"category" : "รถพ่วง"
	},
	{  
	    "typeName" : "พ่วงคอกสูงดัมพ์",
		"category" : "รถพ่วง"
	},
	{  
	    "typeName" : "พ่วงคอกตํ่าดัมพ์",
		"category" : "รถพ่วง"
	},
	{  
	    "typeName" : "พ่วงพื้นเรียบ",
		"category" : "รถพ่วง"
	},
	{  
	    "typeName" : "พ่วงตู้ทึบ",
		"category" : "รถพ่วง"
	},
	{  
	    "typeName" : "เทรลเลอร์คอก",
		"category" : "เทรลเลอร์"
	},
	{  
	    "typeName" : "เทรลเลอร์คอกดั๊มพ์",
		"category" : "เทรลเลอร์"
	},
	{  
	    "typeName" : "เทรลเลอร์พื้นเรียบ",
		"category" : "เทรลเลอร์"
	},
	{  
	    "typeName" : "เทรลเลอร์พื้นเรียบ low bed",
		"category" : "เทรลเลอร์"
	},
	{  
	    "typeName" : "เทรลเลอร์หางก้าง",
		"category" : "เทรลเลอร์"
	},
	{  
	    "typeName" : "เทรลเลอร์ตู้คอนเทนเนอร์ 20ft",
		"category" : "เทรลเลอร์"
	},
	{  
	    "typeName" : "เทรลเลอร์ตู้คอนเทนเนอร์ 40ft",
		"category" : "เทรลเลอร์"
	},
	{  
	    "typeName" : "เทรลเลอร์ม่านข้าง",
		"category" : "เทรลเลอร์"
	}
]


const TRUCK_TYPE = {



	list: list,
	
};

export { TRUCK_TYPE };