export const NOTIF_CONSTANTS = {

	ERROR_NO_SERVER_ERROR_MESSAGE: 'ERROR_NO_SERVER_ERROR_MESSAGE',
	ERROR_FAILURE_COUNT_PAGINATE_NOTIF: 'ERROR_FAILURE_COUNT_PAGINATE_NOTIF',
	ERROR_EMPTY_COUNT_PAGINATE_NOTIF: 'ERROR_EMPTY_COUNT_PAGINATE_NOTIF',
	ERROR_FAILURE_PAGINATE_NOTIF: 'ERROR_FAILURE_PAGINATE_NOTIF',

	ERROR_FAILURE_EDIT_ONE_NOTIF_INFO: 'ERROR_FAILURE_EDIT_ONE_NOTIF_INFO',
	ERROR_FAILURE_EDIT_ONE_NOTIF_STATUS: 'ERROR_FAILURE_EDIT_ONE_NOTIF_STATUS',

}