import axios from 'axios';
import { API_CONSTANTS } from '../../api';



/**
*   NAME: getAllAdminCompanyStatus
*   DESCRIPTION: get all company list
*   METHOD: POST
*/
async function getAllAdminCompanyStatus(token) {
    const requestOptions = {
        url: API_CONSTANTS.ADMIN_COMPANY_STATUS_GET_ALL_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        // data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getAllAdminCompanyStatus Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: createNewAdminCompanyStatus
*   DESCRIPTION: create new admin company status
*   METHOD: POST
*/
async function createNewAdminCompanyStatus(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.ADMIN_COMPANY_STATUS_CREATE_NEW_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("createNewAdminCompanyStatus Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: updateAdminCompanyStatus
*   DESCRIPTION: update admin company status
*   METHOD: PUT
*/
async function updateAdminCompanyStatus(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.ADMIN_COMPANY_STATUS_UPDATE_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("updateAdminCompanyStatus Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: deleteCompanyStatus
*   DESCRIPTION: delete admin company status
*   METHOD: DELETE
*/
async function deleteCompanyStatus(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.ADMIN_COMPANY_STATUS_DELETE_URL,
        method: 'DELETE',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("deleteCompanyStatus Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}



export const adminCompanyStatusServices = {
	getAllAdminCompanyStatus,
	createNewAdminCompanyStatus,
	updateAdminCompanyStatus,
	deleteCompanyStatus,

}