import { NOTIF_ACTION_TYPES } from '../constants';
import { NOTIF_CONSTANTS } from '../constants';

import { 
	// APP_CONSTANTS,
	GLOBAL_CONSTANTS,
} from '../../../constants';

import { notifServices } from '../../../api/services';

import { createErrorMessageFromJsonResponse } from '../../../utilities';


function subscribeNotification(playerId) { 
    return { 
        type: NOTIF_ACTION_TYPES.SUBSCRIBE_NOTIFICATION,
        playerId,
    } 
}

function unsubscribeNotification() { 
    return { 
        type: NOTIF_ACTION_TYPES.UNSUBSCRIBE_NOTIFICATION
    } 
}

//##-------------------------------------------------------------------
function requestSavePlayerId(playerId) { 
    return { 
        type: NOTIF_ACTION_TYPES.SAVE_PLAYER_ID_REQUEST,
        playerId
    } 
}

function successSavePlayerId() { 
    return { 
        type: NOTIF_ACTION_TYPES.SAVE_PLAYER_ID_SUCCESS, 

    } 
}

function failureSavePlayerId(error) { 
    return { 
        type: NOTIF_ACTION_TYPES.SAVE_PLAYER_ID_FAILURE, 
        error 
    } 
}



function savePlayerId(playerId){
    return async (dispatch,getState) => {
    	let { phoneNumber } = getState().auth;

        

        try {

        	await dispatch(requestSavePlayerId(playerId));

            //## {DEBUG}[MOCK]
            // if(!playerId){
            //     playerId = 'd357630d-614a-45c2-ad1a-252dec1a0dec';
            // }

            let responseJson = await notifServices
            .savePlayerId(
                phoneNumber,  
                playerId
            );

            //## [DEBUG]
            // console.log("SavePlayerId Response");
            // console.log(responseJson);

            

            if(responseJson){
                if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){

                    await dispatch(successSavePlayerId());
                    return true;
                }
                else if(responseJson.requestError) {
                    await dispatch(
                        failureSavePlayerId(
                            responseJson.requestError
                        )
                    );
                    return false;
                }
                else{

                    if(responseJson.message){
                        if(responseJson.error){
                            //## [DEBUG][SERVER-ERROR] Logging
                            console.log("[SERVER-ERROR] Response:");
                            console.log(responseJson.error);
                        }

                        var errMsg = createErrorMessageFromJsonResponse(responseJson);
                        await dispatch(
                            failureSavePlayerId(
                                errMsg
                            )
                        );
                    }
                    else{

                        await dispatch(
                            failureSavePlayerId(
                                NOTIF_CONSTANTS.ERROR_NO_SERVER_ERROR_MESSAGE
                            )
                        );
                    }
                    
                    return false;
                }
            }
            else{
                await dispatch(
                    failureSavePlayerId(
                        NOTIF_CONSTANTS.ERROR_NO_SERVER_ERROR_MESSAGE
                    )
                );
                return false;
            }
        }
        catch(error){

            await dispatch(
                failureSavePlayerId(
                    error.message
                )
            );
            return false;
        }
    }
}


//##--------------------------------------------------------------------------
//##--------------------------------------------------------------------------
function requestGetNotificationListFromLocalStorage(){
    return{
        type: NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_REQUEST,
    }
}

function successGetNotificationListFromLocalStorage(notificationList){
    return{
        type: NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_SUCCESS,
        notificationList
    }
}

function failureGetNotificationListFromLocalStorage(error){
    return{
        type: NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_FAILURE,
        error
    }
}

function emptyGetNotificationListFromLocalStorage(){
    return{
        type: NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_EMPTY,
    }
}

function getNotificationListFromLocalStorage(){
    return async (dispatch,getState) => {

        await dispatch(requestGetNotificationListFromLocalStorage());

        let oldNotificationList = null;
        try{
            //## SetItem
            // localStorage.setItem("users", JSON.stringify(users));

            oldNotificationList = await JSON.parse(localStorage.getItem('notificationlist'));

            //## [DEBUG]
            // console.log('oldNotificationList:');
            // console.log(oldNotificationList);

            if(oldNotificationList){
                if(oldNotificationList.length > 0){
                    await dispatch(successGetNotificationListFromLocalStorage(oldNotificationList));
                }
                else{
                    await dispatch(emptyGetNotificationListFromLocalStorage());
                }
            }
            else{
                await dispatch(emptyGetNotificationListFromLocalStorage());
            }

        }
        catch(errGetLocalStorage){
            oldNotificationList = null;

            await dispatch(failureGetNotificationListFromLocalStorage(errGetLocalStorage.message));

        }

        // let { notificationList } = getState().notif;
        return oldNotificationList;
    }
}


//##-------------------------------------------------------------------------------------------------------
function setNotificationCounter(newNotifCounter){
    return {
        type: NOTIF_ACTION_TYPES.SET_NOTIF_COUNTER,
        newNotifCounter
    }
}

function resetNotificationCounter(){
    return{
        type: NOTIF_ACTION_TYPES.RESET_NOTIF_COUNTER,
    }
}


function countUpNotificationCounter(){
    return async (dispatch,getState) => {
        let { notificationCounter } = getState().notif;

        if(!notificationCounter){
            notificationCounter = 0;
        }
        if(notificationCounter < 0) {
            notificationCounter = 0;
        }

        let newNotifCounter = notificationCounter + 1;

        await dispatch(setNotificationCounter(newNotifCounter));

        return newNotifCounter;

    }
}



//##--------------------------------------------------------------------------

function requestRefreshJobListNotification(){
    return {
        type: NOTIF_ACTION_TYPES.REFRESH_JOB_LIST_NOTIFICATION_REQUEST
    }
}

function successRefreshJobListNotification(){
    return {
        type: NOTIF_ACTION_TYPES.REFRESH_JOB_LIST_NOTIFICATION_SUCCESS
    }
}

//##--------------------------------------------------------------------------
function successSaveNotificationDataToList(notificationObj){
    return {
        type: NOTIF_ACTION_TYPES.SAVE_NOTIF_DATA_SUCCESS,
        notificationObj,
    }
}

function failureSaveNotificationDataToList(error){
    return {
        type: NOTIF_ACTION_TYPES.SAVE_NOTIF_DATA_FAILURE,
        error,
    }
}

function saveNotificationDataToList(notificationObj){
    return async (dispatch,getState) => {
        let { notificationList } = await getState().notif;

        
        try{
            await notificationList.unshift(notificationObj);

            // if(notificationList.length > 10){
            //     notificationList = notificationList.slice(0,10);
            // }
            
            //## [DEBUG]
            // console.log('notificationObj:');
            // console.log(notificationObj);
            // console.log('notificationList:');
            // console.log(notificationList);

            //## SetItem
            // await localStorage.setItem("notificationlist", JSON.stringify(notificationList));

            await dispatch(successSaveNotificationDataToList(notificationObj));
        }
        catch(errSaveLocalStorage){
            await dispatch(failureSaveNotificationDataToList(errSaveLocalStorage.message));
        }

        return notificationList;
    }
}




export const notifActions = {
	subscribeNotification,
	unsubscribeNotification,


	requestSavePlayerId,
	successSavePlayerId,
	failureSavePlayerId,
	savePlayerId,

    requestGetNotificationListFromLocalStorage,
    successGetNotificationListFromLocalStorage,
    failureGetNotificationListFromLocalStorage,
    emptyGetNotificationListFromLocalStorage,
    getNotificationListFromLocalStorage,

    setNotificationCounter,
    resetNotificationCounter,
    countUpNotificationCounter,

    requestRefreshJobListNotification,
    successRefreshJobListNotification,

    successSaveNotificationDataToList,
    failureSaveNotificationDataToList,
    saveNotificationDataToList,
}