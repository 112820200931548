import saveAs from 'file-saver';

import { COMPANY_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// COMPANY_STATUS,
	// COMPANY_TYPE, 
} from '../../../constants';
import { COMPANY_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { companyServices } from '../../../api/services';

//##-------------------------------------------------------------------------

function requestGetOneCompanyBankAccountDocList(companyId){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_REQUEST,
		companyId,
	}
}

function successGetOneCompanyBankAccountDocList(docList){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_SUCCESS,
		docList,
	}
}

function failureGetOneCompanyBankAccountDocList(error){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_FAILURE,
		error,
	}
}

function emptyGetOneCompanyBankAccountDocList(error){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_EMPTY,
		error,
	}
}

function getOneCompanyBankAccountDocList(companyId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestGetOneCompanyBankAccountDocList(
    					companyId
    				)
    			);

    			let responseJson = await companyServices
    			.getOneCompanyBankAccountDocList(
    				token,
    				companyId
    			)

    			if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							if(responseJson.data.length > 0){
								await dispatch(
									successGetOneCompanyBankAccountDocList(
										responseJson.data
									)
								);

								return responseJson.data;
							}
							else{
								await dispatch(
									emptyGetOneCompanyBankAccountDocList(
										COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_EMPTY
									)
								);

								return null;
							}
						}
						else{
							await dispatch(
								emptyGetOneCompanyBankAccountDocList(
									COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_EMPTY
								)
							);

							return null;
						}
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetOneCompanyBankAccountDocList(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetOneCompanyBankAccountDocList(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureGetOneCompanyBankAccountDocList(
								COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST
							)
						);
		                return null;
					}
				}
				else{
					await dispatch(
						failureGetOneCompanyBankAccountDocList(
							COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetOneCompanyBankAccountDocList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetOneCompanyBankAccountDocList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------
function requestDownloadOneCompanyBankAccountPdf(companyId,docId){
	return {
		type: COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_REQUEST,
		companyId,
		docId
	}
}

function successDownloadOneCompanyBankAccountPdf(){
	return {
		type: COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_SUCCESS,
	}
}

function failureDownloadOneCompanyBankAccountPdf(error){
	return {
		type: COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_FAILURE,
		error
	}
}


function downloadOneCompanyBankAccountPdf(companyId,docId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestDownloadOneCompanyBankAccountPdf(
    					companyId,
    					docId,
    				)
    			);

    			let response = await companyServices
    			.downloadOneCompanyBankAccountPdf(
    				token,
    				companyId,
    				docId,
    			)

    			if(response.status === GLOBAL_CONSTANTS.HTTP_STATUS_SUCCESS){
					try{
						let pdfData = response.data;


						let blob = new Blob([pdfData], { type: "application/pdf;charset=utf-8" });
						await saveAs(blob,docId + ".pdf")

						await dispatch(
							successDownloadOneCompanyBankAccountPdf(
	
							)
						);

						return true;
					}
					catch(errSaveAsFile){
						await dispatch(
							failureDownloadOneCompanyBankAccountPdf(
								errSaveAsFile.message
							)
						);
		            	return false;
					}
				}
				else{
						
					if(response.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(response.response.data);
	                        await dispatch(
	                        	failureDownloadOneCompanyBankAccountPdf(errMsg)
	                        );
	                    return false;
	                }
					else if(response.requestError) {
	                    await dispatch(
	                    	failureDownloadOneCompanyBankAccountPdf(
	                    		response.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureDownloadOneCompanyBankAccountPdf(
								COMPANY_CONSTANTS.ERROR_FAILURE_DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_PDF
							)
						);
		                return null;
					}
				}
    		}
    		catch(error){
    			await dispatch(
    				failureDownloadOneCompanyBankAccountPdf(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureDownloadOneCompanyBankAccountPdf(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------

export const companyBankAccountDocActions = {

	requestGetOneCompanyBankAccountDocList,
	successGetOneCompanyBankAccountDocList,
	failureGetOneCompanyBankAccountDocList,
	emptyGetOneCompanyBankAccountDocList,
	getOneCompanyBankAccountDocList,


	requestDownloadOneCompanyBankAccountPdf,
	successDownloadOneCompanyBankAccountPdf,
	failureDownloadOneCompanyBankAccountPdf,
	downloadOneCompanyBankAccountPdf,



}