import { NAV_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    starterUrl: null,
    isDrawerOpen: false,
    navigateToPage: null,
    focusPage: null,
    previousPage: null,
}

const nav = (state = INIT_STATE, action) => {
    switch(action.type) {
        case NAV_ACTION_TYPES.DRAWER_OPEN: 
            return {
                ...state,
                isDrawerOpen: true
            };
        case NAV_ACTION_TYPES.DRAWER_CLOSE: 
            return {
                ...state,
                isDrawerOpen: false
            };
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_ERROR_PAGE_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'ErrorPage'
            };
        case NAV_ACTION_TYPES.NAV_ERROR_PAGE_FOCUS: 
            return {
                ...state,
                focusPage: 'ErrorPage'
            };
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_LOGIN_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'LoginPage'
            };
        case NAV_ACTION_TYPES.NAV_LOGIN_FOCUS: 
            return {
                ...state,
                focusPage: 'LoginPage'
            };
        //##-----------------------------------------------------    
        case NAV_ACTION_TYPES.NAV_SAVE_STARTER_URL: 
            return {
                ...state,
                starterUrl: action.starterUrl
            };
        case NAV_ACTION_TYPES.NAV_ANY_PAGE_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: action.targetPage
            };
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_DASHBOARD_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'DashboardPage'
            };
        case NAV_ACTION_TYPES.NAV_DASHBOARD_FOCUS: 
            return {
                ...state,
                focusPage: 'DashboardPage'
            };
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_COMPANY_LIST_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'CompanyListPage'
            };
        case NAV_ACTION_TYPES.NAV_COMPANY_LIST_FOCUS: 
            return {
                ...state,
                focusPage: 'SupplierListPage'
            };
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_USER_LIST_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'UserListPage'
            };
        case NAV_ACTION_TYPES.NAV_USER_LIST_FOCUS: 
            return {
                ...state,
                focusPage: 'UserListPage'
            };
        
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_DRIVER_LIST_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'DriverListPage'
            };
        case NAV_ACTION_TYPES.NAV_DRIVER_LIST_FOCUS: 
            return {
                ...state,
                focusPage: 'DriverListPage'
            };
        
                
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_TRUCK_LIST_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'TruckListPage'
            };
        case NAV_ACTION_TYPES.NAV_TRUCK_LIST_FOCUS: 
            return {
                ...state,
                focusPage: 'TruckListPage'
            };
         
        
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_TRUCK_TYPE_LIST_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'TruckTypeListPage'
            };
        case NAV_ACTION_TYPES.NAV_TRUCK_TYPE_LIST_FOCUS: 
            return {
                ...state,
                focusPage: 'TruckTypeListPage'
            }; 

        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_JOB_LIST_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'JobListPage'
            };
        case NAV_ACTION_TYPES.NAV_JOB_LIST_FOCUS: 
            return {
                ...state,
                focusPage: 'JobListPage'
            };
        //##-----------------------------------------------------
        case NAV_ACTION_TYPES.NAV_ADMIN_COMPANY_STATUS_LIST_START: 
            return {
                ...state,
                previousPage: action.currentPage,
                navigateToPage: 'AdminCompanyStatusPage'
            };
        case NAV_ACTION_TYPES.NAV_ADMIN_COMPANY_STATUS_LIST_FOCUS: 
            return {
                ...state,
                focusPage: 'AdminCompanyStatusPage'
            };


            
        //##-----------------------------------------------------
        default: 
            return state;
    }

}

export { nav };