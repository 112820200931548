import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import { withMultipleStyles } from '../../../utilities';
import { THEME } from '../../../styles';
import { spinnerMuiStyles } from '../styles';

class SpinnerScreenContainers extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            this.props.isShowSpinner && 
            <div 
                className={classes.overlay}
            >
                <div 
                    className={classes.container}
                >
                    <div 
                        className={classes.inidicatorContainer}
                    >
                        <Spinner 
                            size={THEME.spinnerIndicatorSize}
                            color={THEME.spinnerIndicatorColor}
                        />
                        <br />
                        <span 
                            className={classes.indicatorText}
                        >
                            {'กำลังโหลด...'}
                        </span>
                    </div>
                    
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    isShowSpinner: state.spinner.isShowSpinner
})


SpinnerScreenContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    spinnerMuiStyles
)(SpinnerScreenContainers);

const connectedContainers = connect(mapStateToProps)(
    injectedStylesContainers
);
export { connectedContainers  as SpinnerScreenContainers };