
var endpoint = "http://localhost:8080";

if(process.env.REACT_APP_API_URL){
    endpoint = process.env.REACT_APP_API_URL;
}
 
const API_CONSTANTS = {
	//## [Login]
	LOGIN_URL: endpoint + "/api/admin/login",

	OSG_SAVE_PLAYER_ID: endpoint + "/api/admin/save/playerid",

	//## [COMPANY]
	COMPANY_LIST_URL: endpoint + "/api/admin/company/all",
	COMPANY_COUNT_TOTAL_NUM_URL: endpoint + "/api/admin/company/count",
	COMPANY_UPDATE_INFO_URL: endpoint + "/api/admin/company/update",
	COMPANY_UPDATE_BADGE_INFO_URL: endpoint + "/api/admin/company/update/badge",

	COMPANY_GET_ALL_VERIFY_DOC_URL: endpoint + "/api/admin/company/verify_doc/all",
	COMPANY_GET_ONE_VERIFY_DOC_PDF_URL: endpoint + "/api/admin/company/verify_doc/pdf",

	COMPANY_GET_ALL_BANK_ACCOUNT_DOC_URL: endpoint + "/api/admin/company/bank_doc/all",
	COMPANY_GET_ONE_BANK_ACCOUNT_DOC_PDF_URL: endpoint + "/api/admin/company/bank_doc/pdf",

	COMPANY_DELETE_ONE_INFO_URL: endpoint + "/api/admin/company/delete",


	//## [DRIVER]
	DRIVER_LIST_URL: endpoint + "/api/admin/driver/paginate/list",
	DRIVER_COUNT_TOTAL_NUM_URL: endpoint + "/api/admin/driver/count/total/number",
	DRIVER_UPDATE_INFO_URL: endpoint + "/api/admin/driver/edit",

	DRIVER_GET_ALL_LICENSE_DOC_URL: endpoint + "/api/admin/driver/license_doc/all",
	DRIVER_GET_ONE_LICENSE_DOC_PDF_URL: endpoint + "/api/admin/driver/license_doc/pdf",


	//## [TRUCK]
	TRUCK_LIST_URL: endpoint + "/api/admin/truck/paginate/list",
	TRUCK_COUNT_TOTAL_NUM_URL: endpoint + "/api/admin/truck/count/total/number",
	TRUCK_UPDATE_INFO_URL: endpoint + "/api/admin/truck/edit",

	TRUCK_GET_ALL_CARGO_INSURANCE_DOC_URL: endpoint + "/api/admin/truck/cargo_insurance/all",
	TRUCK_GET_ONE_CARGO_INSURANCE_DOC_PDF_URL: endpoint + "/api/admin/truck/cargo_insurance/pdf",


	//## [TRUCK_TYPE]
	TRUCK_TYPE_ALL_LIST_URL: endpoint + "/api/admin/view/all/truck/type/list",    

	TRUCK_TYPE_UPDATE_INFO_URL: endpoint + "/api/admin/truck/type/edit",


    
	//## [USER-> Owner/Dispatcher/Operator]
	USER_LIST_URL: endpoint + "/api/admin/user/paginate/list",
	USER_COUNT_TOTAL_NUM_URL: endpoint + "/api/admin/user/count/total/number",
	USER_CREATE_NEW_URL: endpoint + "/api/admin/create/new/user",
	USER_EDIT_COMPANY_DATA_URL: endpoint + "/api/admin/edit/user/company",
	USER_EDIT_COMPANY_DATA_BY_EMAIL_URL: endpoint + "/api/admin/edit/user/company/by/email",
	USER_EDIT_CONTACT_NAME_DATA_URL: endpoint + "/api/admin/edit/user/contact/name",


	USER_DELETE_ONE_INFO_URL: endpoint + "/api/admin/user/delete",

	//## [JOB]
	JOB_LIST_URL: endpoint + "/api/admin/job/paginate/list",
	JOB_COUNT_TOTAL_NUM_URL: endpoint + "/api/admin/job/count/total/number",


	//## [NOTIF]
	NOTIF_LIST_URL: endpoint + "/api/admin/notification/paginate/list",
	NOTIF_COUNT_TOTAL_NUM_URL: endpoint + "/api/admin/notification/count/total/number",

	NOTIF_UPDATE_STATUS_URL: endpoint + "/api/admin/notification/update/status",
	NOTIF_UPDATE_INFO_URL: endpoint + "/api/admin/notification/update/info",


	//## [ADMIN_COMPANY_STAUS]
	ADMIN_COMPANY_STATUS_GET_ALL_URL: endpoint + "/api/admin/admin/company/status/all",
	ADMIN_COMPANY_STATUS_CREATE_NEW_URL: endpoint + "/api/admin/admin/company/status/create/new",
	ADMIN_COMPANY_STATUS_UPDATE_URL: endpoint + "/api/admin/admin/company/status/update",
	ADMIN_COMPANY_STATUS_DELETE_URL: endpoint + "/api/admin/admin/company/status/delete",
		


}

export { API_CONSTANTS };
