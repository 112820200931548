import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 

    faTrash,


} from '@fortawesome/free-solid-svg-icons';

import { 
	withMultipleStyles,

} from '../../../utilities';

import { 
	layoutMuiStyles,

} from '../../../styles';
import { 
	APP_CONSTANTS,

} from '../../../constants';



import {
	adminCompanyStatusActions,

} from '../actions';



import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';



class AdminCompanyStatusListTableContainers extends React.Component {

	state = {

		isPrefetched: false,

		adminCompanyStatusList: [],

	}

	constructor(props) {
        super(props);

        this.onPrefetch = this.onPrefetch.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }


    //##----------------------------------------------
    static getDerivedStateFromProps(nextProps, prevState){
    	let retState = null;

    	let {
    		adminCompanyStatusList,
    	} = nextProps;

    	let newList = adminCompanyStatusList;




    	if(newList){
    		if(newList.length > 0){
    			let newString = JSON.stringify(newList);
    			let oldString = JSON.stringify(prevState.adminCompanyStatusList);

    			if(newString !== oldString){
    				retState = {
    					...retState,
    					adminCompanyStatusList: newList,
    				}
    			}
    		}
	    }

        // console.log(retState);

    	return retState;
    }

    componentDidMount(){
    	this.onPrefetch();
    }


    componentDidUpdate(prevProps,prevState){

    }


    async onPrefetch(){
    	const { dispatch } = this.props;

    	await dispatch(spinnerActions.showSpinner());

    	let adminCompanyStatusList = await dispatch(
    		adminCompanyStatusActions.getAllAdminCompanyStatusList(
    		)
    	);

        if(adminCompanyStatusList){
            await this.setState({
                adminCompanyStatusList: adminCompanyStatusList,
            })
        }

    	await dispatch(spinnerActions.hideSpinner());
    }

    onDelete = async(adminCompanyStatusId) => {
        const { dispatch } = this.props;

        await dispatch(spinnerActions.showSpinner());

        let isSuccess = await dispatch(
            adminCompanyStatusActions.deleteAdminCompanyStatus(
                adminCompanyStatusId
            )
        );

        if(isSuccess){
            await this.onPrefetch();
        }
        else{
            let errorMsg = this.props.adminError.join('\n');

            await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )
        }



        await dispatch(spinnerActions.hideSpinner());        
    }

    render() {
        const { classes } = this.props;

        let {
        	adminCompanyStatusList
        } = this.state;

        return (
        	<Paper className={classes.paperRoot}>
				<Table className={classes.table}>
			        <TableHead>
			          	<TableRow>
			            	<TableCell>
			            		id
			            	</TableCell>
			            	<TableCell align="right">ชื่อ</TableCell>
			            	<TableCell align="right">หมายเหตุ</TableCell>
			            	<TableCell align="right">ลบ</TableCell>
			          	</TableRow>
			        </TableHead>
			        <TableBody>
			          	{
			          		adminCompanyStatusList.map(row => {
                                return(
				            	<TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {row._id}
                                    </TableCell>
				              		<TableCell align="right">
				                		{row.name}
				              		</TableCell>
				              		<TableCell align="right">{row.remark}</TableCell>
				              		<TableCell align="right">
                                        <Button 
                                            onClick={() => this.onDelete(row._id)} 
                                            variant={'contained'}
                                            color="secondary"
                                        >
                                            <FontAwesomeIcon 
                                                icon={faTrash}
                                                className={
                                                    classNames(
                                                        classes.leftIcon, 
                                                        classes.iconSmall
                                                    )
                                                }
                                            />
                                            {'Del'}
                                        </Button>
                                    </TableCell>
				            	</TableRow>
                                );
			          		})
			          	}
			        </TableBody>
			    </Table>
			</Paper>
       	);
    }

}



const mapStateToProps = (state) => ({
	adminError: state.admin.error,

	adminCompanyStatusList: state.admin.adminCompanyStatusList,


})


AdminCompanyStatusListTableContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(AdminCompanyStatusListTableContainers);

const connectedContainers = connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as AdminCompanyStatusListTableContainers };