
import { NAV_ACTION_TYPES } from '../constants';
// import { NAV_CONSTANTS } from '../constants';
// import { APP_CONSTANTS } from '../../../constants';

// import { SPINNER_ACTION_TYPES } from '../../spinner/constants';

// import { loginServices } from '../../../api/services';

// import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { history } from '../../../history';

function openDrawer(){
	return {
		type: NAV_ACTION_TYPES.DRAWER_OPEN
	}
}

function closeDrawer(){
	return {
		type: NAV_ACTION_TYPES.DRAWER_CLOSE
	}
}

//##-------------------------------------------------------------------------
function startNavigateErrorPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_ERROR_PAGE_START,
		currentPage
	}
}

function focusErrorPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_ERROR_PAGE_FOCUS
	}
}
function navigateToErrorPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateErrorPage(focusPage));
		history.push('/error');
	}
}




//##-------------------------------------------------------------------------
function startNavigateLoginPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_LOGIN_START,
		currentPage
	}
}

function focusLoginPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_LOGIN_FOCUS
	}
}
function navigateToLoginPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateLoginPage(focusPage));
		history.push('/login');
	}
}


//##-------------------------------------------------------------------------
function saveStarterUrl(starterUrl){
	return {
		type: NAV_ACTION_TYPES.NAV_SAVE_STARTER_URL,
		starterUrl
	}
}

//##-------------------------------------------------------------------------

function startNavigateAnyPage(currentPage,targetPage){
	return {
		type: NAV_ACTION_TYPES.NAV_ANY_PAGE_START,
		currentPage,
		targetPage
	}
}


function navigateToAnyPage(pathName){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateAnyPage(focusPage,pathName));
		history.push(pathName);
	}
}



//##-------------------------------------------------------------------------
function startNavigateDashboardPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_DASHBOARD_START,
		currentPage
	}
}

function focusDashboardPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_DASHBOARD_FOCUS
	}
}
function navigateToDashboardPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateDashboardPage(focusPage));
		history.push('/dashboard');
	}
}




//##-------------------------------------------------------------------------
function startNavigateCompanyListPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_COMPANY_LIST_START,
		currentPage
	}
}

function focusCompanyListPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_COMPANY_LIST_FOCUS
	}
}

function navigateToCompanyListPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateCompanyListPage(focusPage));
		history.push('/companies');
	}
}

//##-------------------------------------------------------------------------
function startNavigateUserListPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_USER_LIST_START,
		currentPage
	}
}

function focusUserListPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_USER_LIST_FOCUS
	}
}

function navigateToUserListPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateUserListPage(focusPage));
		history.push('/users');
	}
}




//##-------------------------------------------------------------------------
function startNavigateDriverListPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_DRIVER_LIST_START,
		currentPage
	}
}

function focusDriverListPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_DRIVER_LIST_FOCUS
	}
}

function navigateToDriverListPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateDriverListPage(focusPage));
		history.push('/drivers');
	}
}

//##-------------------------------------------------------------------------
function startNavigateTruckListPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_TRUCK_LIST_START,
		currentPage
	}
}

function focusTruckListPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_TRUCK_LIST_FOCUS
	}
}

function navigateToTruckListPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateTruckListPage(focusPage));
		history.push('/trucks');
	}
}

//##-------------------------------------------------------------------------
function startNavigateTruckTypeListPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_TRUCK_TYPE_LIST_START,
		currentPage
	}
}

function focusTruckTypeListPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_TRUCK_TYPE_LIST_FOCUS
	}
}
function navigateToTruckTypeListPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateTruckTypeListPage(focusPage));
		history.push('/truck/types');
	}
}

//##-------------------------------------------------------------------------
function startNavigateAdminCompanyStatusListPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_ADMIN_COMPANY_STATUS_LIST_START,
		currentPage
	}
}

function focusAdminCompanyStatusListPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_ADMIN_COMPANY_STATUS_LIST_FOCUS
	}
}
function navigateToAdminCompanyStatusPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateAdminCompanyStatusListPage(focusPage));
		history.push('/admin/company/status/setting');
	}
}

//##-------------------------------------------------------------------------


//##-------------------------------------------------------------------------
function startNavigateJobListPage(currentPage){
	return {
		type: NAV_ACTION_TYPES.NAV_JOB_LIST_START,
		currentPage
	}
}

function focusJobListPage(){
	return {
		type: NAV_ACTION_TYPES.NAV_JOB_LIST_FOCUS
	}
}

function navigateToJobListPage(){
	return (dispatch,getState) => {
		const { focusPage } = getState().nav;
		dispatch(startNavigateJobListPage(focusPage));
		history.push('/jobs');
	}
}

//##-------------------------------------------------------------------------
export const navActions = {
    openDrawer,
    closeDrawer,

    startNavigateErrorPage,
	focusErrorPage,
	navigateToErrorPage,


    startNavigateLoginPage,
    focusLoginPage,
    navigateToLoginPage,

    saveStarterUrl,
	startNavigateAnyPage,
	navigateToAnyPage,


    startNavigateDashboardPage,
    focusDashboardPage,
    navigateToDashboardPage,

    startNavigateCompanyListPage,
	focusCompanyListPage,
	navigateToCompanyListPage,

    startNavigateUserListPage,
    focusUserListPage,
    navigateToUserListPage,



	startNavigateDriverListPage,
	focusDriverListPage,
	navigateToDriverListPage,

	startNavigateTruckListPage,
	focusTruckListPage,
	navigateToTruckListPage,


	startNavigateTruckTypeListPage,
    focusTruckTypeListPage,
    navigateToTruckTypeListPage,


    startNavigateAdminCompanyStatusListPage,
    focusAdminCompanyStatusListPage,
    navigateToAdminCompanyStatusPage,


	startNavigateJobListPage,
    focusJobListPage,
    navigateToJobListPage,
	
};