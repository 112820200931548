import { ALERT_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    isShowAlert: false,
    title: null,
    message: null,
    alertType: null,
}

const alert = (state = INIT_STATE, action) => {
    switch(action.type) {
        case ALERT_ACTION_TYPES.SHOW_ALERT: 
            return {
                ...state,
                isShowAlert: true,
                title: action.title,
                message: action.message,
                alertType: action.alertType,
            };
        case ALERT_ACTION_TYPES.HIDE_ALERT: 
            return {
                ...state,
                isShowAlert: false,
                title: null,
                message: null,
                alertType: null,
            };
        default: 
            return state;
    }

}

export { alert };