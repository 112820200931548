import { overlaySpacing } from '../../../styles';

const loginMuiStyles = theme => ({
    loginFrame: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: theme.color.grey.m0.color,
        paddingLeft: overlaySpacing,
        paddingRight: overlaySpacing,
        paddingTop: theme.spacing.unit * 10,
    },

    loginTitle:{
        fontSize: theme.typography.h4.fontSize,
        color: theme.typography.h4.color,
        textAlign: 'center',
        marginBottom: 20,
    },
    loginSubtitle:{

        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.color.grey.m500.color,
        textAlign: 'center',
    },

});


export { loginMuiStyles };