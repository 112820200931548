import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Toolbar from '@material-ui/core/Toolbar';
import NotificationsIcon from '@material-ui/icons/Notifications';

import MenuIcon from '@material-ui/icons/Menu';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';

// import { Link } from 'react-router-dom';
import { layoutMuiStyles } from '../../../styles';
import { navActions } from '../../nav/actions';
// import { notifActions } from '../../notif/actions';

import { 
    APP_CONSTANTS,

} from '../../../constants';

import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';

import {
    notifListActions,
} from '../../notif/actions';

class AppbarContainers extends React.Component {

    state = {
        open: false,
        contentPage: 'UserListPage',
        badgeNotifCounter: 0,
        openNotif: false,
    };

    constructor(props){
        super(props);

        //## binding
        this.onDrawerOpen = this.onDrawerOpen.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);


        this.onClickNotificationIcon = this.onClickNotificationIcon.bind(this);
        this.onPrefetchNotificationData = this.onPrefetchNotificationData.bind(this);
        this.onClickOneNotification = this.onClickOneNotification.bind(this);
    }

    componentWillMount(){
        
    }

    componentDidMount(){

    }

    onDrawerOpen = () => {
        //## Drawer Action open
        const { dispatch } = this.props;
        dispatch(navActions.openDrawer());
    };

    onDrawerClose = () => {
        //## Drawer Action close
        const { dispatch } = this.props;
        dispatch(navActions.closeDrawer());
    };

    onClickNotificationIcon = async() => {
        // const { dispatch } = this.props;

        // await dispatch(notifActions.resetNotificationCounter());
        await this.onPrefetchNotificationData();

        await this.setState(state => ({ openNotif: !state.openNotif }));    
    }

    async onPrefetchNotificationData(){
        const { dispatch } = this.props;

        let {
            page,
            limit,
            sortBy,
            filterBy,
        } = this.state;


        await dispatch(spinnerActions.showSpinner());

        let notifList = await dispatch(
            notifListActions.getPaginateNotifList(
                page,
                limit,
                sortBy,
            )
        );



        let notifTotalNum = await dispatch(
            notifListActions.countPaginateNotifList(
                filterBy
            )
        );



        await dispatch(spinnerActions.hideSpinner());


        if(!notifList || !notifTotalNum){
            let errorMsg = this.props.notifError.join('\n');

            await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )

        }





        return Promise.resolve();
    }

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
          return;
        }

        this.setState({ openNotif: false });
      };

    onClickOneNotification = async(mode) => {
        const { dispatch } = this.props;

        // if (this.anchorEl.contains(event.target)) {
        //     return;
        // }

        this.setState({ openNotif: false });


        let currentLocation = window.location.pathname;

        if(
            mode === 'newCompany'
            || mode === 'newCompanyVerifyDoc'
            || mode === 'newCompanyBankAccDoc'
        ){
            if(currentLocation === '/companies'){
                // await dispatch(notifActions.requestRefreshJobListNotification());
            }
            else{
               await dispatch(navActions.navigateToCompanyListPage()); 
            }
        }
        else if(
            mode === 'newTruck'
            || mode === 'newTruckCargoInsuranceDoc'
        ){
            if(currentLocation === '/trucks'){
                // await dispatch(notifActions.requestRefreshJobListNotification());
            }
            else{
               await dispatch(navActions.navigateToTruckListPage()); 
            }
        }
        else if(
            mode === 'newDriverLicenseDoc'
        ){
            if(currentLocation === '/drivers'){
                // await dispatch(notifActions.requestRefreshJobListNotification());
            }
            else{
               await dispatch(navActions.navigateToDriverListPage()); 
            }
        }
        else{
            if(currentLocation === '/dashboard'){
                // await dispatch(jobsActions.requestRefreshJobListNotification());
            }
            else{
               await dispatch(navActions.navigateToDashboardPage()); 
            }
        }
    }

    // componentDidUpdate(){
    //     this.setState(
    //         badgeNotifCounter: this.props.jobNotificationCounter
    //     )
    // }


    render() {
        const { classes } = this.props;

        return (
            <div className={classes.appBarContainer}>
                <AppBar className={classNames(classes.appBar, this.props.isDrawerOpen && classes.appBarShift)}>
                    <Toolbar disableGutters={!this.props.isDrawerOpen}>
                        <IconButton
                            color="default"
                            aria-label="open drawer"
                            onClick={this.onDrawerOpen}
                            className={classNames(classes.menuButton, this.props.isDrawerOpen && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography type="title" color="inherit" noWrap>
                            ผู้ดูแลระบบ Logisty
                        </Typography>
                        <div className={classes.appBarRightIconContainer}>
                            <IconButton
                                onClick={this.onClickNotificationIcon}
                                color="inherit"
                                buttonRef={node => {
                                  this.anchorEl = node;
                                }}
                            >
                                {
                                    this.props.notificationCounter > 0 &&
                                    <Badge 
                                        badgeContent={this.props.notificationCounter} 
                                        color="secondary"
                                    >
                                        <NotificationsIcon />
                                    </Badge>
                                }
                                {
                                    this.props.notificationCounter <= 0 &&
                                    <NotificationsIcon />
                                }
                            </IconButton>
                            <Popper 
                                open={this.state.openNotif} 
                                anchorEl={this.anchorEl} 
                                transition 
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{ 
                                            transformOrigin: placement === 'bottom' 
                                            ? 
                                            'center top' 
                                            : 
                                            'center bottom' 
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <List 
                                                    component="nav"
                                                    className={classes.listNotification}
                                                >
                                                    {
                                                        this.props.notificationList &&
                                                        this.props.notificationList.length > 0 &&
                                                        this.props.notificationList.map( (row,index) => (
                                                            <ListItem 
                                                                key={index} 
                                                                dense 
                                                                button 
                                                                className={classes.listNotificationItem}
                                                                onClick={(event) => this.onClickOneNotification(row.mode)}
                                                            >
                                                                <ListItemText 
                                                                    primary={"".concat("[",row.mode,"] ",row.title)} 
                                                                    secondary={"".concat(row.body)}
                                                                />
                                                                
                                                            </ListItem>
                                                            
                                                        ))
                                                    }
                                                </List>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}


AppbarContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    // isLogin: state.auth.isLogin
    isDrawerOpen: state.nav.isDrawerOpen,
    focusPage: state.nav.focusPage,

    notifError: state.notif.error,
    notificationCounter: state.notif.notificationCounter,
    notificationList: state.notif.notificationList,
});

const connectedAppbarContainers = connect(mapStateToProps)(withStyles(
    layoutMuiStyles, 
    { withTheme: true }
)(AppbarContainers));
export { connectedAppbarContainers as AppbarContainers };