const waitingApprove = { 
	code: 0, 
	name: "ยังไม่ได้รับการยืนยัน",
	bgColor: '#ffff00',
	fontColor: '#000000',
};
const approved = { 
	code: 1, 
	name: "ยืนยันตัวตนแล้ว",
	bgColor: '#008000',
	fontColor: '#ffffff',
};
const banned = { 
	code: 2, 
	name: "ถูกแบน",
	bgColor: '#ff0000',
	fontColor: '#ffffff',
};

const mapping = {
	0: waitingApprove.name,
	1: approved.name,
	2: banned.name,
}



const COMPANY_STATUS = {
	waitingApprove: waitingApprove,
	approved: approved,
	banned: banned,

	mapping: mapping,
};

export { COMPANY_STATUS };