export const JOBS_ACTION_TYPES = {
	
	GET_PAGINATE_JOB_LIST_REQUEST: 'GET_PAGINATE_JOB_LIST_REQUEST',
	GET_PAGINATE_JOB_LIST_SUCCESS: 'GET_PAGINATE_JOB_LIST_SUCCESS',
	GET_PAGINATE_JOB_LIST_SUCCESS_ADD_MORE: 'GET_PAGINATE_JOB_LIST_SUCCESS_ADD_MORE',
	GET_PAGINATE_JOB_LIST_FAILURE: 'GET_PAGINATE_JOB_LIST_FAILURE',
	GET_PAGINATE_JOB_LIST_EMPTY: 'GET_PAGINATE_JOB_LIST_EMPTY',


	COUNT_PAGINATE_JOB_LIST_REQUEST: 'COUNT_PAGINATE_JOB_LIST_REQUEST',
	COUNT_PAGINATE_JOB_LIST_SUCCESS: 'COUNT_PAGINATE_JOB_LIST_SUCCESS',
	COUNT_PAGINATE_JOB_LIST_FAILURE: 'COUNT_PAGINATE_JOB_LIST_FAILURE',
	COUNT_PAGINATE_JOB_LIST_EMPTY: 'COUNT_PAGINATE_JOB_LIST_EMPTY',

	

}
