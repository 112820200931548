import { AUTH_ACTION_TYPES } from '../constants';
// import { AUTH_CONSTANTS } from '../constants';
// import { GLOBAL_CONSTANTS } from '../../../constants';


function initSavePhoneNumber(phoneNumber) { 
    return { 
        type: AUTH_ACTION_TYPES.INIT_SAVE_PHONENUMBER,
        phoneNumber
    } 
}



export const initActions = {
    initSavePhoneNumber,
};