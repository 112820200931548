import { DRIVERS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
} from '../../../constants';
import { DRIVERS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { driverServices } from '../../../api/services';



function requestGetPaginateDriverList(page,limit,sortBy){
	return {
		type: DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_REQUEST
	}
}

function successGetPaginateDriverList(driverList,page,limit,sortBy){
	return {
		type: DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_SUCCESS,
		driverList,
		page,
		limit,
		sortBy,
	}
}

function successGetPaginateDriverListAddMore(driverList,page,limit,sortBy){
	return {
		type: DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_SUCCESS_ADD_MORE,
		driverList,
		page,
		limit,
		sortBy,
	}
}

function failureGetPaginateDriverList(error){
	return {
		type: DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_FAILURE,
		error,
	}
}

function emptyGetPaginateDriverList(error){
	return {
		type: DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_EMPTY,
		error,
	}
}

function getPaginateDriverList(page, limit, sortBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestGetPaginateDriverList());

    			//## Get Only Job below available JOb
    			let filterBy = {
    				
    			}

    			let select = null;

    			if(!sortBy){
    				sortBy = APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY;
    			}

    			let payload = {
    				page: page,
    				limit: limit,
    				filterBy: filterBy,
    				sortBy: sortBy,
    				select: select,
    			}

    			let responseJson = await driverServices.getPaginateDriverList(
						    				token,
						    				payload
						    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							// if(page === 0){
							// 	await dispatch(
							// 		successGetPaginateDriverList(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }
							// else if(page > 0){
							// 	await dispatch(
							// 		successGetPaginateDriverListAddMore(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }

							await dispatch(
								successGetPaginateDriverList(
									responseJson.data,
									page,
									limit,
									sortBy,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyGetPaginateDriverList(
		                			DRIVERS_CONSTANTS.ERROR_FAILURE_PAGINATE_DRIVER
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetPaginateDriverList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetPaginateDriverList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureGetPaginateDriverList(
								DRIVERS_CONSTANTS.ERROR_FAILURE_PAGINATE_DRIVER
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureGetPaginateDriverList(
							DRIVERS_CONSTANTS.ERROR_FAILURE_PAGINATE_DRIVER
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetPaginateDriverList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetPaginateDriverList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


//##-------------------------------------------------------------------------
function requestCountPaginateDriverList(page,limit,sortBy){
	return {
		type: DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_REQUEST
	}
}

function successCountPaginateDriverList(driverListTotalNum){
	return {
		type: DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_SUCCESS,
		driverListTotalNum,
	}
}

function failureCountPaginateDriverList(error){
	return {
		type: DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_FAILURE,
		error,
	}
}

function emptyCountPaginateDriverList(error){
	return {
		type: DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_EMPTY,
		error,
	}
}

function countPaginateDriverList(filterBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestCountPaginateDriverList());

    			//## Get Only Job below available JOb



    			let responseJson = await driverServices
    			.getCountTotalDriverNum(
					token,						    				
    				filterBy
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){

							await dispatch(
								successCountPaginateDriverList(
									responseJson.data
								)
							);

							return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyCountPaginateDriverList(
		                			DRIVERS_CONSTANTS.ERROR_EMPTY_COUNT_PAGINATE_DRIVER
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureCountPaginateDriverList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureCountPaginateDriverList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureCountPaginateDriverList(
								DRIVERS_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_DRIVER
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureCountPaginateDriverList(
							DRIVERS_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_DRIVER
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(failureCountPaginateDriverList(error.message));
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureCountPaginateDriverList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


export const driverListActions = {

	requestGetPaginateDriverList,
	successGetPaginateDriverList,
	successGetPaginateDriverListAddMore,
	failureGetPaginateDriverList,
	emptyGetPaginateDriverList,
	getPaginateDriverList,


	requestCountPaginateDriverList,
	successCountPaginateDriverList,
	failureCountPaginateDriverList,
	emptyCountPaginateDriverList,
	countPaginateDriverList,


}