const waitingApprove = { 
	code: 0, 
	name: "ยังไม่ได้รับการยืนยัน",
	bgColor: '#ffff00',
	fontColor: '#000000',
};
const approved = { 
	code: 1, 
	name: "ยืนยันตัวตนแล้ว",
	bgColor: '#008000',
	fontColor: '#ffffff',
};
const banned = { 
	code: 2, 
	name: "ถูกแบน",
	bgColor: '#ff0000',
	fontColor: '#ffffff',
};

const quiting = { 
	code: 3, 
	name: "ลาออกจากบริษัท",
	bgColor: '#dddddd',
	fontColor: '#000000',
};

const mapping = {
	0: waitingApprove.name,
	1: approved.name,
	2: banned.name,
	3: quiting.name
}


const USER_STATUS = {
	waitingApprove: waitingApprove,
	approved: approved,
	banned: banned,
	quiting: quiting,

	mapping: mapping,
};

export { USER_STATUS };