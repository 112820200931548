import saveAs from 'file-saver';

import { DRIVERS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// COMPANY_STATUS,
	// COMPANY_TYPE, 
} from '../../../constants';
import { DRIVERS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { driverServices } from '../../../api/services';

//##-------------------------------------------------------------------------

function requestGetOneDriverLicenseDocList(driverId){
	return {
		type: DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_REQUEST,
		driverId,
	}
}

function successGetOneDriverLicenseDocList(docList){
	return {
		type: DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_SUCCESS,
		docList,
	}
}

function failureGetOneDriverLicenseDocList(error){
	return {
		type: DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_FAILURE,
		error,
	}
}

function emptyGetOneDriverLicenseDocList(error){
	return {
		type: DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_EMPTY,
		error,
	}
}

function getOneDriverLicenseDocList(driverId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestGetOneDriverLicenseDocList(
    					driverId
    				)
    			);

    			let responseJson = await driverServices
    			.getOneDriverLicenseDocList(
    				token,
    				driverId
    			)

    			if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							if(responseJson.data.length > 0){
								await dispatch(
									successGetOneDriverLicenseDocList(
										responseJson.data
									)
								);

								return responseJson.data;
							}
							else{
								await dispatch(
									emptyGetOneDriverLicenseDocList(
										DRIVERS_CONSTANTS.ERROR_FAILURE_GET_ONE_DRIVER_LICENSE_DOC_LIST_EMPTY
									)
								);

								return null;
							}
						}
						else{
							await dispatch(
								emptyGetOneDriverLicenseDocList(
									DRIVERS_CONSTANTS.ERROR_FAILURE_GET_ONE_DRIVER_LICENSE_DOC_LIST_EMPTY
								)
							);

							return null;
						}
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetOneDriverLicenseDocList(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetOneDriverLicenseDocList(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureGetOneDriverLicenseDocList(
								DRIVERS_CONSTANTS.ERROR_FAILURE_GET_ONE_DRIVER_LICENSE_DOC_LIST
							)
						);
		                return null;
					}
				}
				else{
					await dispatch(
						failureGetOneDriverLicenseDocList(
							DRIVERS_CONSTANTS.ERROR_FAILURE_GET_ONE_DRIVER_LICENSE_DOC_LIST
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetOneDriverLicenseDocList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetOneDriverLicenseDocList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------
function requestDownloadOneDriverLicensePdf(driverId,docId){
	return {
		type: DRIVERS_ACTION_TYPES.DOWNLOAD_ONE_DRIVER_LICENSE_DOC_PDF_REQUEST,
		driverId,
		docId
	}
}

function successDownloadOneDriverLicensePdf(){
	return {
		type: DRIVERS_ACTION_TYPES.DOWNLOAD_ONE_DRIVER_LICENSE_DOC_PDF_SUCCESS,
	}
}

function failureDownloadOneDriverLicensePdf(error){
	return {
		type: DRIVERS_ACTION_TYPES.DOWNLOAD_ONE_DRIVER_LICENSE_DOC_PDF_FAILURE,
		error
	}
}


function downloadOneDriverLicensePdf(driverId,docId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestDownloadOneDriverLicensePdf(
    					driverId,
    					docId,
    				)
    			);

    			let response = await driverServices
    			.downloadOneDriverLicensePdf(
    				token,
    				driverId,
    				docId,
    			)

    			if(response.status === GLOBAL_CONSTANTS.HTTP_STATUS_SUCCESS){
					try{
						let pdfData = response.data;


						let blob = new Blob([pdfData], { type: "application/pdf;charset=utf-8" });
						await saveAs(blob,docId + ".pdf")

						await dispatch(
							successDownloadOneDriverLicensePdf(
	
							)
						);

						return true;
					}
					catch(errSaveAsFile){
						await dispatch(
							failureDownloadOneDriverLicensePdf(
								errSaveAsFile.message
							)
						);
		            	return false;
					}
				}
				else{
						
					if(response.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(response.response.data);
	                        await dispatch(
	                        	failureDownloadOneDriverLicensePdf(errMsg)
	                        );
	                    return false;
	                }
					else if(response.requestError) {
	                    await dispatch(
	                    	failureDownloadOneDriverLicensePdf(
	                    		response.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureDownloadOneDriverLicensePdf(
								DRIVERS_CONSTANTS.ERROR_FAILURE_DOWNLOAD_ONE_DRIVER_LICENSE_PDF
							)
						);
		                return null;
					}
				}
    		}
    		catch(error){
    			await dispatch(
    				failureDownloadOneDriverLicensePdf(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureDownloadOneDriverLicensePdf(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------

export const driverLicenseDocActions = {

	requestGetOneDriverLicenseDocList,
	successGetOneDriverLicenseDocList,
	failureGetOneDriverLicenseDocList,
	emptyGetOneDriverLicenseDocList,
	getOneDriverLicenseDocList,


	requestDownloadOneDriverLicensePdf,
	successDownloadOneDriverLicensePdf,
	failureDownloadOneDriverLicensePdf,
	downloadOneDriverLicensePdf,



}