
/**
*	NAME: createErrorMessageFromJsonResponse
*	DESCRIPTION: create error message from json response
*	ARGUMENT: responseJson -> type: json
*	RETURN: string
*/
function createErrorMessageFromJsonResponse(responseJson){

	var errMsg = "";

	// var errMsg = "[CODE:";
 //    if(responseJson.code){
 //        errMsg += responseJson.code;
 //    }
 //    errMsg += "] ";
    if(responseJson.message){
	    errMsg += responseJson.message;
	}

    return errMsg;
}

export { createErrorMessageFromJsonResponse };


