import { SPINNER_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    isShowSpinner: false
}

const spinner = (state = INIT_STATE, action) => {
    switch(action.type) {
        case SPINNER_ACTION_TYPES.SHOW_SPINNER: 
            return {
                ...state,
                isShowSpinner: true
            };
        case SPINNER_ACTION_TYPES.HIDE_SPINNER: 
            return {
                ...state,
                isShowSpinner: false
            };
        default: 
            return state;
    }

}

export { spinner };