import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';


// import { Helmet } from "react-helmet";
import { 
    OSG_APP_KEY,
} from '../../../onesignal';

// import { 
//     APP_CONSTANTS,
// } from '../../../constants';

import { 
    themeMuiStyles 
} from '../../../styles';

import { initActions } from '../../auth/actions';
import { 
    notifActions,
    notifListActions, 
} from '../../notif/actions';
// import { spinnerActions } from '../../spinner/actions';

import { AlertScreenContainers } from '../../alert/containers';
import { SpinnerScreenContainers } from '../../spinner/containers';
import { LightBoxContainers } from '../../lightbox/containers';


import { MainNavigationPage } from '../../nav/pages';

// const OneSignal = window.OneSignal;

class RootPage extends React.Component {

    state = {
        isLoading: false,
        oneSignalPlayerId: null,
    }

    constructor(props){
        super(props);


        this.handleOneSignalInit = this.handleOneSignalInit.bind(this);
        this.onChangeOneSignalPlayerId = this.onChangeOneSignalPlayerId.bind(this);
        this.onSaveOneSignalPlayerIdOnServer = this.onSaveOneSignalPlayerIdOnServer.bind(this);
        this.onReceivedOneSignalOneNotification = this.onReceivedOneSignalOneNotification.bind(this);
    }

    async componentDidMount() {
        const { dispatch } = this.props;
        let phoneNumber = await localStorage.getItem('phoneNumber');

        if(phoneNumber){
            await dispatch(initActions.initSavePhoneNumber(phoneNumber));
        }

        //## [DEBUG]
        // console.warn('Root Page did mount')

        //## [INIT] One Signal Notification prompt
        await this.handleOneSignalInit();

        //## Load Cookies onesignal
        // await dispatch(notifActions.getNotificationListFromLocalStorage());
    }


    componentDidUpdate(prevProps,prevState){
        if(prevProps.oneSignalPlayerId !== this.props.oneSignalPlayerId){
            if(this.props.oneSignalPlayerId){
                this.onChangeOneSignalPlayerId();
            }
        }

        if(
            prevProps.playerId !== this.props.playerId
            || prevProps.phoneNumber !== this.props.phoneNumber
        ){
            this.onSaveOneSignalPlayerIdOnServer();
        }

        if(prevProps.newNotificationReceivedEvent !== this.props.newNotificationReceivedEvent){
            this.onReceivedOneSignalOneNotification();
        }
    }

    //##----------------------------------------------------------------

    handleOneSignalInit(){
        
        //## [INIT] init onesignal web push sdk outside react

        //## [DEBUG]
        // console.log('handleOneSignalInit');

        // var oneSignalPlayerId = null;
        var OneSignal = window.OneSignal || [];

        OneSignal.push(function() {
            OneSignal.init({
                appId: OSG_APP_KEY,
                autoResubscribe: true,
                persistNotification: true,
                //## Subscription bell
                notifyButton: {
                    enable: false,
                },
                // notificationClickHandlerMatch: 'origin', /* See above documentation: 'origin' relaxes tab matching requirements, 'exact' is default */
                // notificationClickHandlerAction: 'navigate',
                // promptOptions: {
                    /* These prompt options values configure both the HTTP prompt and the HTTP popup. */
                    /* actionMessage limited to 90 characters */
                    // actionMessage: APP_CONSTANTS.OSG_NOTIF_PROMPT_ACTION_MESSAGE,
                    /* acceptButtonText limited to 15 characters */
                    // acceptButtonText: APP_CONSTANTS.OSG_NOTIF_PROMPT_ACCEPT_BTN_TEXT,
                    /* cancelButtonText limited to 15 characters */
                    // cancelButtonText: APP_CONSTANTS.OSG_NOTIF_PROMPT_CANCEL_BTN_TEXT,
                // }
            });
            // OneSignal.showSlidedownPrompt();
            OneSignal.showNativePrompt();
            OneSignal.getUserId(function(userId) {
                // console.log("OneSignal User ID:", userId);
                // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316    

                //## [CallBack] ReactApp to set internal state of react
                if(window.reactApp){
                    window.reactApp.setOneSignalPlayerId(userId)
                }
            });
            OneSignal.on('subscriptionChange', function (isSubscribed) {
                console.log("The user's subscription state is now:", isSubscribed);

                if(isSubscribed === true){
                    OneSignal.getUserId(function(userId) {
                        // console.log("OneSignal User ID After Subscribed:", userId);
                        // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316    
                        
                        //## [CallBack] ReactApp to set internal state of react
                        if(window.reactApp){
                            window.reactApp.setOneSignalPlayerId(userId)
                        }
                    });
                }
            });

            OneSignal.on('notificationDisplay', function(event) {
                // console.warn('OneSignal notification displayed:', event);

                if(window.reactApp){
                    window.reactApp.onReceivedOneSignalOneNotification(event)
                }
            });

            // OneSignal.on('addListenerForNotificationOpened', function(data) {
            //     // console.warn('OneSignal notification displayed:', event);

            //     // if(window.reactApp){
            //         // window.reactApp.onReceivedOneSignalOneNotification(event)
            //         console.log("Received NotificationOpened:");
            //         console.log(data);
            //     // }
            // });
        });
    }

    onChangeOneSignalPlayerId(){
        //## Handle change of one signal player Id in reactApp

        const { dispatch } = this.props;

        //## {DEBUG}
        // console.log('Props oneSignalPlayerId change:')
        // console.log('onChangeOneSignalPlayerId:')
        // console.log(this.props.oneSignalPlayerId)



        this.setState({
            oneSignalPlayerId: this.props.oneSignalPlayerId,
            isLoading: false,
        })

        dispatch(
            notifActions.subscribeNotification(
                this.props.oneSignalPlayerId
            )
        );
    }

    onSaveOneSignalPlayerIdOnServer() {
        const { dispatch } = this.props;
        let {
            phoneNumber,
            playerId,
        } = this.props;

        if(phoneNumber && playerId){
            dispatch(
                notifActions.savePlayerId(
                    playerId
                )
            )
        }
    }

    async onReceivedOneSignalOneNotification() {
        const { dispatch } = this.props;
        // let { newNotificationReceivedEvent } = this.props;
        // let newNotifEvent = newNotificationReceivedEvent;

        //## [DEBUG]
        // console.log('onReceivedOneSignalOneNotification')
        // console.log(newNotificationReceivedEvent);

        // let notifObj = {
        //     title: newNotifEvent.heading,
        //     body: newNotifEvent.content,
        //     mode: newNotifEvent.data ? newNotifEvent.data.mode: null,
        //     data: newNotifEvent.data,
        // }

        // await dispatch(
        //     notifActions.saveNotificationDataToList(
        //         notifObj
        //     )
        // );

        let page = 0;
        let limit = this.props.notificationListPaginateLimit;
        let sortBy = this.props.notificationListPaginateSortBy;
        let filterBy = this.props.notificationListPaginateFilterBy;

        await dispatch(
            notifListActions.getPaginateNotifList(
                page,
                limit,
                sortBy,
            )
        );



        await dispatch(
            notifListActions.countPaginateNotifList(
                filterBy
            )
        );

        await dispatch(
            notifActions.countUpNotificationCounter(
            )
        );
    }


    //##-------------------------------------------------------------------


    render() {
        return (
            <MuiThemeProvider theme={themeMuiStyles}>
                <div id="root_main">
                    <MainNavigationPage />
                    <SpinnerScreenContainers />
                    <AlertScreenContainers />
                    <LightBoxContainers />
                </div>
            </MuiThemeProvider>
        );
    }
}

RootPage.propTypes = {
    oneSignalPlayerId: PropTypes.string,
}

const mapStateToProps = (state) => ({
    playerId: state.notif.playerId,
    phoneNumber: state.auth.phoneNumber,

    notificationListPaginatePage: state.notif.notificationListPaginatePage,
    notificationListPaginateLimit: state.notif.notificationListPaginateLimit,
    notificationListPaginateSortBy: state.notif.notificationListPaginateSortBy,
    notificationListPaginateFilterBy: state.notif.notificationListPaginateFilterBy,
});

const connectedRootPage = connect(mapStateToProps)(RootPage);
export { connectedRootPage as RootPage };
