




function hasDocRenderer(params){

	var fontColor = '#000000';
	var bgColor = '#dddddd';
	var valueShow = 'ไม่มี';

	//## [DEBUG]
	// console.log('companyTypeCellRenderer');
	// console.log(params);
	if(params.value){
		if(params.value.length > 0){
			fontColor = '#ffffff';
			bgColor = '#008000';
			valueShow = 'ส่งแล้ว';
		}
	}




	var ret = "<div style='width: 100%; height: 100%; text-align: center; "
	ret += "background-color:" + bgColor + "; "
	ret += "color: " + fontColor + ";' >";
	ret += valueShow + "</div>"


	return ret;

}

export { 

	hasDocRenderer, 
};


