import { COMPANY_TYPE,JOB_STATUS_ARRAY } from '../constants';

/**
*	NAME: convertShipperStatus
*	DESCRIPTION: convert shipper status to object to display
*	ARGUMENT: number
*	RETURN: object
*/
function convertJobStatus(statusNum){

	var ret = "";


	let statusObject = JOB_STATUS_ARRAY.filter((oneRow)=> 
		oneRow.code === statusNum
	);

	if(statusObject.length > 0){
		ret = statusObject[0].name;
	}

	

    return ret;
}

function jobStatusCellRenderer(params){

	var fontColor = '#000000';
	var bgColor = '#ffffff';
	var valueShow = '?';

	//## [DEBUG]
	// console.log('jobStatusCellRenderer');
	// console.log(jobStatusCellRenderer);
	

	let statusObject = JOB_STATUS_ARRAY.filter((oneRow)=> 
		oneRow.code === params.value
	);

	if(statusObject.length > 0){
		fontColor = statusObject[0].fontColor;
		bgColor = statusObject[0].bgColor;
		valueShow = statusObject[0].name;
	}

	//## [DEBUG]
	// console.log('statusObject');
	// console.log(statusObject);


	var ret = "<div style='width: 100%; height: 100%; text-align: center; "
	ret += "background-color:" + bgColor + "; "
	ret += "color: " + fontColor + ";' >";
	ret += valueShow + "</div>"


	return ret;

}

function jobPrivateCellRenderer(params){

	var fontColor = '#000000';
	var bgColor = '#ffffff';
	var valueShow = '?';

	//## [DEBUG]
	// console.log('jobStatusCellRenderer');
	// console.log(jobStatusCellRenderer);
	

	if(params.value === true){
		
		fontColor = COMPANY_TYPE.company.fontColor;
		bgColor = COMPANY_TYPE.company.bgColor;
		valueShow = "private";
	}
	else if(params.value === false){
		fontColor = COMPANY_TYPE.individual.fontColor;
		bgColor = COMPANY_TYPE.individual.bgColor;
		valueShow = "public";
	}

	//## [DEBUG]
	// console.log('statusObject');
	// console.log(statusObject);


	var ret = "<div style='width: 100%; height: 100%; text-align: center; "
	ret += "background-color:" + bgColor + "; "
	ret += "color: " + fontColor + ";' >";
	ret += valueShow + "</div>"


	return ret;

}

export { 
	convertJobStatus,
	jobStatusCellRenderer,
	jobPrivateCellRenderer,
};


