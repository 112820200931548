import axios from 'axios';
import { API_CONSTANTS } from '../../api';


/**
*   NAME: getPaginateTruckList
*   DESCRIPTION: get paginate company list
*   METHOD: POST
*/
async function getPaginateTruckList(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.TRUCK_LIST_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getPaginateTruckList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: getCountTotalTruckNum
*   DESCRIPTION: count total company list
*   METHOD: POST
*/
async function getCountTotalTruckNum(token,filterBy) {
    const requestOptions = {
        url: API_CONSTANTS.TRUCK_COUNT_TOTAL_NUM_URL,
        method: 'POST',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            filterBy: filterBy,
        }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getCountTotalTruckNum Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: putUpdateOneTruckInfo
*   DESCRIPTION: get paginate company list
*   METHOD: PUT
*/
async function putUpdateOneTruckInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.TRUCK_UPDATE_INFO_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateOneTruckInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: getOneTruckCargoInsuranceDocList
*   DESCRIPTION: get One Truck Verify list
*   METHOD: GET
*/
async function getOneTruckCargoInsuranceDocList(token,truckId) {
    const requestOptions = {
        url: API_CONSTANTS.TRUCK_GET_ALL_CARGO_INSURANCE_DOC_URL + "/" + truckId,
        method: 'GET',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        // data: JSON.stringify({
        //     filterBy: filterBy,
        // }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getOneTruckCargoInsuranceDocList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: getOneTruckCargoInsuranceDocList
*   DESCRIPTION: get One Truck Verify list
*   METHOD: GET
*/
async function downloadOneTruckCargoInsurancePdf(token,truckId,docId) {
    const requestOptions = {
        url: API_CONSTANTS.TRUCK_GET_ONE_CARGO_INSURANCE_DOC_PDF_URL,
        method: 'POST',
        responseType: 'blob',
        headers: { 
            'Accept': 'application/pdf',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            truckId: truckId,
            docId: docId,
        }),
    };


    try {

        let response = await axios.request(requestOptions);

        return response;
    }
    catch (error){
        //## [DEBUG]
        console.error("downloadOneTruckCargoInsurancePdf Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}





export const truckServices = {
    getPaginateTruckList,
    getCountTotalTruckNum,
    putUpdateOneTruckInfo,

    getOneTruckCargoInsuranceDocList,
    downloadOneTruckCargoInsurancePdf,

};