//## Undefineable in theme.mui.style or usage as JS outside theme
import red from '@material-ui/core/colors/red';

const fontColor = '#000000';


export const THEME = {
	fontColor: fontColor,
	errorFontColor: red[500],

	spinnerIndicatorSize: 48,
	spinnerIndicatorColor: '#ffffff',

	dialogMinWidth: 100,

	spinnerZIndex: 3000,
	dialogZIndex: 4000,
	dialogTextFieldZIndex: 4003,
	dialogMenuItemZIndex: 4005,
	lightBoxZIndex: 4100,
}