import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import FaceIcon from '@material-ui/icons/Face';
// import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
// import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
// import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import StoreIcon from '@material-ui/icons/Store';
// import LocalShippingIcon from '@material-ui/icons/LocalShipping';
// import PlaceIcon from '@material-ui/icons/Place';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import StarsIcon from '@material-ui/icons/Stars';
// import CommuteIcon from '@material-ui/icons/Commute';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
	faBuilding,
	faUsers,
	faTruck,
	faStream,

} from '@fortawesome/free-solid-svg-icons';

import { 
	faSteeringWheel,
	faCarMechanic,

} from '@fortawesome/pro-solid-svg-icons';



// import { Link } from 'react-router-dom';
// import { ownersActions } from '../../owners/actions';
// import { suppliersActions } from '../../suppliers/actions';
// import { driversActions } from '../../drivers/actions';
// import { trucksActions } from '../../trucks/actions';
// import { placesActions } from '../../places/actions';

// import { usersActions } from '../../users/actions';
// import { jobsActions } from '../../jobs/actions';
// import { customersActions } from '../../customers/actions';
// import { truckTypesActions } from '../../trucktypes/actions';
// import { lineBotGroupsActions } from '../../lineBotGroups/actions';
// import { shippersActions } from '../../shippers/actions';


import { layoutMuiStyles } from '../../../styles';
import { navActions } from '../../nav/actions';
import { logoutActions } from '../../auth/actions';


class DrawerContainers extends React.Component {

	state = {
    	open: false,
    	contentPage: 'UserListPage'
  	};

  	constructor(props){
        super(props);

        //## binding
        this.onDrawerOpen = this.onDrawerOpen.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);

        this.gotoCompanyListPage = this.gotoCompanyListPage.bind(this);
        this.gotoUserListPage = this.gotoUserListPage.bind(this);
        this.gotoDriverListPage = this.gotoDriverListPage.bind(this); 
        this.gotoTruckListPage = this.gotoTruckListPage.bind(this);
        this.gotoJobListPage = this.gotoJobListPage.bind(this);
        this.gotoTruckTypePage = this.gotoTruckTypePage.bind(this);
        this.gotoAdminCompanyStatusPage = this.gotoAdminCompanyStatusPage.bind(this);


        this.gotoLogoutPage = this.gotoLogoutPage.bind(this);

    }

    componentWillMount(){
        
    }

    componentDidMount(){

    }

  	onDrawerOpen = () => {
    	//## Drawer Action open
    	const { dispatch } = this.props;
    	dispatch(navActions.openDrawer());
  	};

  	onDrawerClose = () => {
    	//## Drawer Action close
    	const { dispatch } = this.props;
    	dispatch(navActions.closeDrawer());
  	};

  	gotoDashboardPage = () => {
  		//## Navigate action to page
  		const { dispatch } = this.props;
  		this.onDrawerClose();
    	dispatch(navActions.navigateToDashboardPage());	

	}

	gotoCompanyListPage = () => {
		const { dispatch } = this.props;

  		// dispatch(driversActions.viewAllDriverListTablePage());
  		this.onDrawerClose();

  		// dispatch(navActions.navigateToUserListPage());	
  		let currentLocation = window.location.pathname;
    	if(currentLocation === '/companies'){
  			window.location.reload();
  		}
  		else{
  			dispatch(navActions.navigateToCompanyListPage());	
  		}
	}

	gotoUserListPage = () => {
  		//## Navigate action to page
  		const { dispatch } = this.props;

  		this.onDrawerClose();
  		// dispatch(usersActions.viewAllTruckDriverListTablePage());

  		// dispatch(navActions.navigateToUserListPage());	
  		let currentLocation = window.location.pathname;
    	if(currentLocation === '/users'){
  			window.location.reload();
  		}
  		else{
  			dispatch(navActions.navigateToUserListPage());	
  		}
    	

	}




	gotoDriverListPage = () => {
  		//## Navigate action to page
  		const { dispatch } = this.props;

  		this.onDrawerClose();
  		// dispatch(driversActions.viewAllDriverListTablePage());

  		// dispatch(navActions.navigateToUserListPage());	
  		let currentLocation = window.location.pathname;
    	if(currentLocation === '/drivers'){
  			window.location.reload();
  		}
  		else{
  			dispatch(navActions.navigateToDriverListPage());	
  		}
    	

	}

	gotoTruckListPage = () => {
  		//## Navigate action to page
  		const { dispatch } = this.props;

  		this.onDrawerClose();
  		// dispatch(trucksActions.viewAllTruckListTablePage());

  		// dispatch(navActions.navigateToUserListPage());	
  		let currentLocation = window.location.pathname;
    	if(currentLocation === '/trucks'){
  			window.location.reload();
  		}
  		else{
  			dispatch(navActions.navigateToTruckListPage());	
  		}
    	

	}

	gotoTruckTypePage = () => {
	    //## Navigate action to page
  		const { dispatch } = this.props;

  		this.onDrawerClose();
  		// dispatch(truckTypesActions.viewAllTruckTypeListTablePage());

  		// dispatch(navActions.navigateToTruckTypeListPage());	

  		let currentLocation = window.location.pathname;
    	if(currentLocation === '/truck/types'){
  			window.location.reload();
  		}
  		else{
  			dispatch(navActions.navigateToTruckTypeListPage());	
  		}
  		
	}


	gotoAdminCompanyStatusPage = () => {
	    //## Navigate action to page
  		const { dispatch } = this.props;

  		this.onDrawerClose();
  		// dispatch(truckTypesActions.viewAllTruckTypeListTablePage());

  		// dispatch(navActions.navigateToTruckTypeListPage());	

  		let currentLocation = window.location.pathname;
    	if(currentLocation === '/admin/company/status/setting'){
  			window.location.reload();
  		}
  		else{
  			dispatch(navActions.navigateToAdminCompanyStatusPage());	
  		}
	}
  	

	gotoJobListPage = () => {
		//## Navigate action to page
  		const { dispatch } = this.props;

  		this.onDrawerClose();
    	// dispatch(jobsActions.viewAllJobListTablePage());

  		// dispatch(navActions.navigateToJobListPage());	

  		let currentLocation = window.location.pathname;
    	if(currentLocation === '/jobs'){
  			window.location.reload();
  		}
  		else{
  			dispatch(navActions.navigateToJobListPage());	
  		}
  		
	}

	


	gotoLogoutPage = () => {
		//## Navigate action to page
  		const { dispatch } = this.props;

  		this.onDrawerClose();
  		dispatch(logoutActions.logout());
    	dispatch(navActions.navigateToLoginPage());
	}



	render() {
	    const { classes, theme } = this.props;

	    return (
	    	<Drawer
		    	variant="persistent"
		    	classes={{
		      		paper: classes.drawerPaper,
		    	}}
		    	open={this.props.isDrawerOpen}
		  	>
		    	<div className={classes.drawerInner}>
		          	<div className={classes.drawerHeader}>
		            	<IconButton onClick={this.onDrawerClose}>
		              		{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
		            	</IconButton>
		          	</div>
		          	<Divider />
		          	<div>
		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'DashboardPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoDashboardPage}
		          		>
		            		<ListItemIcon>
		              			<DashboardIcon />
		            		</ListItemIcon>
		            		<ListItemText primary="หน้าหลัก" />
		          		</ListItem>

		          		
		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'CompanyListPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoCompanyListPage}
		          		>
		            		<ListItemIcon>
		              			<FontAwesomeIcon 
                                    icon={faBuilding} 
                                    className={classes.drawerIcon}
                                />
		            		</ListItemIcon>
		            		<ListItemText primary="รายชื่อบริษัท" />
		          		</ListItem>
		          		
		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'CompanyListPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoUserListPage}
		          		>
		            		<ListItemIcon>
		              			<FontAwesomeIcon 
                                    icon={faUsers} 
                                    className={classes.drawerIcon}
                                />
		            		</ListItemIcon>
		            		<ListItemText primary="รายชื่อ user" />
		          		</ListItem>
		          		
		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'DriverListPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoDriverListPage}
		          		>
		            		<ListItemIcon>
		              			<FontAwesomeIcon 
                                    icon={faSteeringWheel} 
                                    className={classes.drawerIcon}
                                />
		            		</ListItemIcon>
		            		<ListItemText primary="รายชื่อคนขับรถขนส่ง" />
		          		</ListItem>
		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'TruckListPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoTruckListPage}
		          		>
		            		<ListItemIcon>
		              			<FontAwesomeIcon 
                                    icon={faTruck} 
                                    className={classes.drawerIcon}
                                />
		            		</ListItemIcon>
		            		<ListItemText primary="รายชื่อรถบรรทุก" />
		          		</ListItem>
		          		
		          		{/*
		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'UserListPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoUserListPage}
		          		>
		            		<ListItemIcon>
		              			<FaceIcon />
		            		</ListItemIcon>
		            		<ListItemText primary="รายชื่อคนขับรถบรรทุก" />
		          		</ListItem>
						*/}
		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'JobListPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoJobListPage}
		          		>
		            		<ListItemIcon>
		            			<AssignmentIcon />
		            		</ListItemIcon>
		            		<ListItemText primary="งานขนส่งในระบบ" />
		          		</ListItem>


		          		<div className={classes.spacing4} />
		          		<div className={classes.hr} />
		          		<div className={classes.spacing4} />

		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'TruckTypeListPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoTruckTypePage}
		          		>
		                	<ListItemIcon>
		                		<FontAwesomeIcon 
                                    icon={faCarMechanic} 
                                    className={classes.drawerIcon}
                                />
		                	</ListItemIcon>
		                	<ListItemText primary="ชนิดรถขนส่ง" />
		          		</ListItem>

		          		<ListItem 
		          			style={{
		          				backgroundColor: this.props.focusPage === 'AdminCompanyStatusPage' ? '#DDDDDD' : 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoAdminCompanyStatusPage}
		          		>
		                	<ListItemIcon>
		                		<FontAwesomeIcon 
                                    icon={faStream} 
                                    className={classes.drawerIcon}
                                />
		                	</ListItemIcon>
		                	<ListItemText primary="ตั้งค่าสถานะบริษัทจากแอดมิน" />
		          		</ListItem>

		          		<ListItem 
		          			style={{
		          				backgroundColor: 'transparent'
		          			}}
		          			button 
		          			onClick={this.gotoLogoutPage}
		          		>
		                	<ListItemIcon>
		                		<PowerSettingsNewIcon />
		                	</ListItemIcon>
		                	<ListItemText primary="ออกจากระบบ" />
		          		</ListItem>
		          	</div>
		    	</div>
		  	</Drawer>
	    );
	}
}


DrawerContainers.propTypes = {
	classes: PropTypes.object.isRequired,
  	theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    // isLogin: state.auth.isLogin
    isDrawerOpen: state.nav.isDrawerOpen,
    focusPage: state.nav.focusPage
});

const connectedDrawerContainers = connect(mapStateToProps)(withStyles(
	layoutMuiStyles, 
	{ withTheme: true }
)(DrawerContainers));
export { connectedDrawerContainers as DrawerContainers };