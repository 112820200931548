import axios from 'axios';
import { API_CONSTANTS } from '../../api';
import { ADMIN_JWT_KEY } from '../../api';

/**
*	NAME: login
*	DESCRIPTION: login admin to system
*	METHOD: POST
*/
async function login(phoneNumber, password, playerId) {
	const requestOptions = {
		url: API_CONSTANTS.LOGIN_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
        		   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + ADMIN_JWT_KEY,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify({
                phoneNumber: phoneNumber,
                password: password,
                playerId: playerId
        })
    };


    try {

    	let responseJson = await axios.request(requestOptions);

    	return responseJson.data;
    }
    catch (error){
    	//## [DEBUG]
    	console.error("Login Services Request Error:");
    	console.error(error);

    	var data = {requestError: error.message};
        return data;
    }
}




export const loginServices = {
    login,

};