export const USERS_ACTION_TYPES = {
	
	GET_PAGINATE_USER_LIST_REQUEST: 'GET_PAGINATE_USER_LIST_REQUEST',
	GET_PAGINATE_USER_LIST_SUCCESS: 'GET_PAGINATE_USER_LIST_SUCCESS',
	GET_PAGINATE_USER_LIST_SUCCESS_ADD_MORE: 'GET_PAGINATE_USER_LIST_SUCCESS_ADD_MORE',
	GET_PAGINATE_USER_LIST_FAILURE: 'GET_PAGINATE_USER_LIST_FAILURE',
	GET_PAGINATE_USER_LIST_EMPTY: 'GET_PAGINATE_USER_LIST_EMPTY',


	COUNT_PAGINATE_USER_LIST_REQUEST: 'COUNT_PAGINATE_USER_LIST_REQUEST',
	COUNT_PAGINATE_USER_LIST_SUCCESS: 'COUNT_PAGINATE_USER_LIST_SUCCESS',
	COUNT_PAGINATE_USER_LIST_FAILURE: 'COUNT_PAGINATE_USER_LIST_FAILURE',
	COUNT_PAGINATE_USER_LIST_EMPTY: 'COUNT_PAGINATE_USER_LIST_EMPTY',

	UPDATE_ONE_USER_INFO_REQUEST: 'UPDATE_ONE_USER_INFO_REQUEST',
	UPDATE_ONE_USER_INFO_SUCCESS: 'UPDATE_ONE_USER_INFO_SUCCESS',
	UPDATE_ONE_USER_INFO_FAILURE: 'UPDATE_ONE_USER_INFO_FAILURE',

	UPDATE_ONE_USER_COMPANY_INFO_REQUEST: 'UPDATE_ONE_USER_COMPANY_INFO_REQUEST',
	UPDATE_ONE_USER_COMPANY_INFO_SUCCESS: 'UPDATE_ONE_USER_COMPANY_INFO_SUCCESS',
	UPDATE_ONE_USER_COMPANY_INFO_FAILURE: 'UPDATE_ONE_USER_COMPANY_INFO_FAILURE',

	CREATE_NEW_USER_INFO_REQUEST: 'CREATE_NEW_USER_INFO_REQUEST',
	CREATE_NEW_USER_INFO_SUCCESS: 'CREATE_NEW_USER_INFO_SUCCESS',
	CREATE_NEW_USER_INFO_FAILURE: 'CREATE_NEW_USER_INFO_FAILURE',

	UPDATE_ONE_USER_CONTACT_NAME_REQUEST: 'UPDATE_ONE_USER_CONTACT_NAME_REQUEST',
	UPDATE_ONE_USER_CONTACT_NAME_SUCCESS: 'UPDATE_ONE_USER_CONTACT_NAME_SUCCESS',
	UPDATE_ONE_USER_CONTACT_NAME_FAILURE: 'UPDATE_ONE_USER_CONTACT_NAME_FAILURE',
	

	DELETE_ONE_USER_INFO_REQUEST: 'DELETE_ONE_USER_INFO_REQUEST',
	DELETE_ONE_USER_INFO_SUCCESS: 'DELETE_ONE_USER_INFO_SUCCESS',
	DELETE_ONE_USER_INFO_FAILURE: 'DELETE_ONE_USER_INFO_FAILURE',
}
