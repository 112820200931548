import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell';
// import TableBody from '@material-ui/core/TableBody';

import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSave,


} from '@fortawesome/free-solid-svg-icons';

import { 
	withMultipleStyles,

} from '../../../utilities';

import { 
	layoutMuiStyles,

} from '../../../styles';
import { 
	APP_CONSTANTS,

} from '../../../constants';



import {
	adminCompanyStatusActions,

} from '../actions';



import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';



class AdminCompanyStatusNewFormContainers extends React.Component {

	state = {

		isPrefetched: false,

        propName: '',
        propRemark: '',
        propCategory: '',


		name: '',
        remark: '',
        category: '',

	}

	constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);

    }


    //##----------------------------------------------
    static getDerivedStateFromProps(nextProps, prevState){
    	let retState = null;

    	let {
    		name,
            remark,
            category,
    	} = nextProps;



    	if(name){
    		
    		let newString = JSON.stringify(name);
    		let oldString = JSON.stringify(prevState.propName);

    		if(newString !== oldString){
    			retState = {
    				...retState,
                    propName: name,
    				name: name,
    			}
    		}
    		
	    }

        if(remark){
            
            let newString = JSON.stringify(remark);
            let oldString = JSON.stringify(prevState.propRemark);

            if(newString !== oldString){
                retState = {
                    ...retState,
                    propRemark: remark,
                    remark: remark,
                }
            }
            
        }

        if(category){
            
            let newString = JSON.stringify(category);
            let oldString = JSON.stringify(prevState.propCategory);

            if(newString !== oldString){
                retState = {
                    ...retState,
                    propCategory: category,
                    category: category,
                }
            }
            
        }

    	return retState;
    }

    componentDidMount(){

    }


    componentDidUpdate(prevProps,prevState){

    }



    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };


    async onSave(){
        const { dispatch } = this.props;
        let {
            name,
            remark,
        } = this.state;

        let payload = {
            name: name,
            remark: remark,
        }

        await dispatch(spinnerActions.showSpinner());

        let saved = await dispatch(
            adminCompanyStatusActions.createNewAdminCompanyStatus(
                payload
            )
        )

        if(!saved){
            let errorMsg = this.props.adminError.join('\n');

            await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )
        }
        else{
            await dispatch(
                adminCompanyStatusActions.getAllAdminCompanyStatusList(
                )
            );
        }

        await dispatch(spinnerActions.hideSpinner());
    }


    render() {
        const { classes } = this.props;



        return (
			<Grid container spacing={8}>
                <Grid 
                    item 
                    xs={12}
                >
                    <TextField
                        id="admin-company-status-name"
                        label="Name"
                        className={classes.textField}
                        value={this.state.name}
                        onChange={this.handleChange('name')}
                        margin="normal"
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12}
                >
                    <TextField
                        id="admin-company-status-remark"
                        label="Remark"
                        className={classes.textField}
                        value={this.state.remark}
                        onChange={this.handleChange('remark')}
                        margin="normal"
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12}
                >
                    <Button 
                        onClick={() => this.onSave()} 
                        variant={'contained'}
                        color="primary"
                    >
                        <FontAwesomeIcon 
                            icon={faSave}
                            className={
                                classNames(
                                    classes.leftIcon, 
                                    classes.iconSmall
                                )
                            }
                        />
                        {'Save'}
                    </Button>
                </Grid>
                {/*
                <Grid 
                    item 
                    xs={12}
                >
                    <TextField
                        id="admin-company-status-category"
                        label="Name"
                        className={classes.textField}
                        value={this.state.category}
                        onChange={this.handleChange('category')}
                        margin="normal"
                    />
                </Grid>
                */}
            </Grid>
       	);
    }

}



const mapStateToProps = (state) => ({
	adminError: state.admin.error,

	adminCompanyStatusList: state.admin.adminCompanyStatusList,


})


AdminCompanyStatusNewFormContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,

    mode: PropTypes.string.isRequired
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(AdminCompanyStatusNewFormContainers);

const connectedContainers = connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as AdminCompanyStatusNewFormContainers };