import axios from 'axios';
import { API_CONSTANTS } from '../../api';


/**
*   NAME: getAllTruckTypeList
*   DESCRIPTION: get paginate company list
*   METHOD: POST
*/
async function getAllTruckTypeList(token) {
    const requestOptions = {
        url: API_CONSTANTS.TRUCK_TYPE_ALL_LIST_URL,
        method: 'GET',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        // data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getAllTruckTypeList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: putUpdateOneTruckTypeInfo
*   DESCRIPTION: get paginate company list
*   METHOD: PUT
*/
async function putUpdateOneTruckTypeInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.TRUCK_TYPE_UPDATE_INFO_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateOneTruckTypeInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

export const truckTypeServices = {
    getAllTruckTypeList,
    putUpdateOneTruckTypeInfo,

};