import { USERS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// COMPANY_STATUS,
	// COMPANY_TYPE, 
} from '../../../constants';
import { USERS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { userServices } from '../../../api/services';



function requestDeleteOneUser(){
	return {
		type: USERS_ACTION_TYPES.DELETE_ONE_USER_INFO_REQUEST
	}
}

function successDeleteOneUser(){
	return {
		type: USERS_ACTION_TYPES.DELETE_ONE_USER_INFO_SUCCESS,
	}
}



function failureDeleteOneUser(error){
	return {
		type: USERS_ACTION_TYPES.DELETE_ONE_USER_INFO_FAILURE,
		error,
	}
}


function deleteOneUser(userId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestDeleteOneUser());


    			let payload ={
		    		userId: userId,
			        
		    	}



		    	//## [DEBUG]
	    		// console.log('-----Company Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await userServices
    			.deleteOneUserInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successDeleteOneUser(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureDeleteOneUser(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureDeleteOneUser(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureDeleteOneUser(
								USERS_CONSTANTS.ERROR_FAILURE_DELETE_ONE_USER_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureDeleteOneUser(
							USERS_CONSTANTS.ERROR_FAILURE_DELETE_ONE_USER_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureDeleteOneUser(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureDeleteOneUser(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


//###----------------------------------------------------------------------


export const deleteUserActions = {


	requestDeleteOneUser,
	successDeleteOneUser,
	failureDeleteOneUser,
	deleteOneUser,



}