import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../nav/components';

import { history } from '../../../history';

// import { TestPage } from '../../auth/containers';
// import { TestApiPage } from '../../auth/containers';


import { ErrorPage } from '../../root/pages';
import { LoginPage } from '../../auth/pages';
import { DashboardPage } from '../../nav/pages';
import { CompanyListPage } from '../../company/pages';
import { UserListPage } from '../../users/pages';
import { DriverListPage } from '../../drivers/pages';
import { TruckListPage } from '../../trucks/pages';
import { TruckTypeListPage } from '../../trucks/pages';
import { AdminCompanyStatusListPage } from '../../admin/pages';

import { JobListPage } from '../../jobs/pages';



class MainNavigationPage extends React.Component {

    render() {
        return (

            <Router history={history}>
                <Switch>
                    {/* Private Route */}
                    {/*<PrivateRoute path="/splash" component={SplashPage} /> */}
                    <PrivateRoute path="/admin/company/status/setting" component={AdminCompanyStatusListPage} />
                    <PrivateRoute path="/truck/types" component={TruckTypeListPage} />
                    <PrivateRoute path="/jobs" component={JobListPage} />
                    <PrivateRoute path="/trucks" component={TruckListPage} />
                    <PrivateRoute path="/drivers" component={DriverListPage} />
                    <PrivateRoute path="/users" component={UserListPage} />
                    <PrivateRoute path="/companies" component={CompanyListPage} />
                    <PrivateRoute path="/dashboard" component={DashboardPage} />                    
                    {/* Normal Route */}
                    <Route path="/login" component={LoginPage} />
                    <Route path="/error" component={ErrorPage} />
                    <Route path="*" component={LoginPage}/>
                </Switch>
            </Router> 
        )
    }

}

const mapStateToProps = (state) => ({
    // isLogin: state.auth.isLogin
});

const connectedMainNavigationPage = connect(mapStateToProps)(MainNavigationPage);
export { connectedMainNavigationPage as MainNavigationPage };