import { createMuiTheme } from '@material-ui/core/styles';
// import pink from '@material-ui/core/colors/pink';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';

import { THEME } from '../styles';

const themeMuiStyles = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            // main: '#37966f',
            main: '#96375e',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: deepOrange[500],
            main: deepOrange[700],
            dark: deepOrange[900],
            // contrastText: '#000000',
        },
        error: red,
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        useNextVariants: true,
    },
    color: {
        brand: {
            m5: {
                text:  '#58c296',
                color: '#dbf2e8',
            },
            m8: {
                text:  '#58c296',
                color: '#a3dec4',
            },
            m50: {
                text: '#ffffff',
                color: '#58c296',
            },
            m100: {
                text: '#ffffff',
                color: '#45bb8b',
            },
            m200: {
                text: '#ffffff',
                color: '#3ea97d',
            },
            m300: {
                text: '#ffffff',
                color: '#37966f',
            },
            m400: {
                text: '#ffffff',
                color: '#308361',
            },
            m500: {
                text: '#ffffff',
                color: '#297153',
            },
        },
        line: {
            basic: {
                text: '#ffffff',
                color: '#00C300',
            },
            hover: {
                text: '#ffffff',
                color: '#00E000',
            },
            active: {
                text: '#ffffff',
                color: '#00B300',
            },
            disabled: {
                text: '#ffffff',
                color: '#C6C6C6',
            },
        },
        error: {
            m50: {
                text: '#ffffff',
                color: red[400],
            },
            m100: {
                text: '#ffffff',
                color: red[500],
            },
            m200: {
                text: '#ffffff',
                color: red[600],
            },
            m300: {
                text: '#ffffff',
                color: red[700],
            },
            m400: {
                text: '#ffffff',
                color: red[800],
            },
            m500: {
                text: '#ffffff',
                color: red[900],
            },
        },
        indigo: {
            m50: {
                text: '#ffffff',
                color: indigo[400],
            },
            m100: {
                text: '#ffffff',
                color: indigo[500],
            },
            m200: {
                text: '#ffffff',
                color: indigo[600],
            },
            m300: {
                text: '#ffffff',
                color: indigo[700],
            },
            m400: {
                text: '#ffffff',
                color: indigo[800],
            },
            m500: {
                text: '#ffffff',
                color: indigo[900],
            },
        },
        bg: {
            m50: {
                text: '#ffffff',
                color: '#31749c',
            },
            m100: {
                text: '#ffffff',
                color: '#2b6689',
            },
            m200: {
                text: '#ffffff',
                color: '#255775',
            },
            //## main Background color
            m300: {
                text: '#ffffff',
                color: '#1f4962',
            },
            m400: {
                text: '#ffffff',
                color: '#193b4f',
            },
            m500: {
                text: '#ffffff',
                color: '#132c3b',
            },
        },
        success: {
            basic: {
                color: green['A400']
            }
        },
        grey: {
            m0: {
                text: '#000000',
                color: '#ffffff',
            },
            m50: {
                text: '#000000',
                color: grey[50],
            },
            m100: {
                text: '#000000',
                color: grey[100],
            },
            m200: {
                text: '#000000',
                color: grey[200],
            },
            //## main Background color
            m300: {
                text: '#000000',
                color: grey[300],
            },
            m400: {
                text: '#000000',
                color: grey[400],
            },
            m500: {
                text: '#000000',
                color: grey[500],
            },
        },
        orange: {
            m50: {
                text: '#000000',
                color: amber[50],
            },
            m100: {
                text: '#000000',
                color: amber[100],
            },
            m200: {
                text: '#000000',
                color: amber[200],
            },
            //## main Background color
            m300: {
                text: '#000000',
                color: amber[300],
            },
            m400: {
                text: '#000000',
                color: amber[400],
            },
            m500: {
                text: '#000000',
                color: amber[500],
            },
            ma100:{
                text: '#000000',
                color: amber['A100'],
            },
            ma200:{
                text: '#000000',
                color: amber['A200'],
            },
            ma400:{
                text: '#000000',
                color: amber['A400'],
            },
            ma700:{
                text: '#000000',
                color: amber['A700'],
            },
        },
        purple: {
            m50: {
                text: '#000000',
                color: deepPurple[50],
            },
            m100: {
                text: '#000000',
                color: deepPurple[100],
            },
            m200: {
                text: '#000000',
                color: deepPurple[200],
            },
            //## main Background color
            m300: {
                text: '#000000',
                color: deepPurple[300],
            },
            m400: {
                text: '#000000',
                color: deepPurple[400],
            },
            m500: {
                text: '#000000',
                color: deepPurple[500],
            },
            ma100:{
                text: '#000000',
                color: deepPurple['A100'],
            },
            ma200:{
                text: '#000000',
                color: deepPurple['A200'],
            },
            ma400:{
                text: '#000000',
                color: deepPurple['A400'],
            },
            ma700:{
                text: '#000000',
                color: deepPurple['A700'],
            },
        },
    },
    text: {
        loadingIcon: {
            fontSize: 48,
            color: '#000000',
        }
    },
    textFieldTheme: {
        light: {
            color: '#000000',
            borderColor: '#000000',
        },
        dark: {
            color: '#000000',
            borderColor: '#000000',
        }
    },
    button: {
        amber: {
            backgroundColor: amber[500],
            color: '#000000',

        },
        outline: {
            darkTheme: {
                color: '#000000',
                borderColor: '#000000',
                borderStyle: 'dashed',
            },
            lightTheme: {
                color: '#333333',
                borderColor: '#333333',
                borderStyle: 'dashed',
            },
        }
    },
    container: {
        jobStep: {
            height: '3rem'
        }
    },
    zIndex: {
        mobileStepper: 1000,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
        spinner: THEME.spinnerZIndex,
        alert: THEME.dialogZIndex,
        lightBox: THEME.lightBoxZIndex,
    },
    //## Main Override default themes
  	overrides: {
    	//## Name of the component ⚛️ / style sheet
        MuiTypography: {
            h1: {
                color: '#000000',
            },
            h2: {
                color: '#000000',
            },
            h3: {
                color: '#000000',
            },
            h4: {
                color: '#000000',
            },
            h5: {
                color: '#000000',
            },
            h6: {
                color: '#000000',
            },
            subtitle1: {
                color: '#000000',
            },
            subtitle2: {
                color: '#000000',
            },
            body1: {
                color: '#000000',
            },
            body2: {
                color: '#000000',
            },
            button: {
                color: '#000000',
            },
            caption: {
                color: grey[500],
            },
            subheading: {
                color: grey[300],
            },
            overline: {
                color: '#000000',
            }
        },
        MuiButton: {
            root: {
                fontSize: '1.125rem',
                lineHeight: '2.25rem',
                "&$disabled": {
                    "backgroundColor": "#aaaaaa",
                    "color": "#000000"
                }
            },
            contained: {
                "&$disabled": {
                    "backgroundColor": "#aaaaaa",
                    "color": "#000000"
                }
            },
            outlined: {
                "&$disabled": {
                    "backgroundColor": "#555555",
                    "color": "#333333",
                    "borderColor": "#333333",
                    '&:hover': {
                        "backgroundColor": "#444444",
                    },
                }
            }
        },
    	MuiInputBase: {
    		input: {
	    		fontSize: '1.125rem',
	    		lineHeight: '2.25rem',
	    	}
    	},
        MuiFormControl: {
            root: {
                color: '#000000'
            },
        },
        MuiFormHelperText: {
            root: {
                color: '#000000',
            },
        },
        MuiInputLabel: {
            root: {
                color: '#000000',
                "&$focused": {
                  color: "#dddddd"
                }
            },
            focused: {},    
        },
        MuiInput: {
            root: {
                color: '#000000',
            },
            underline: {
                color: '#000000',//input color focus of not  
                // backgroundColor: "grey",//background color of whole input 
                '&:before': {
                    borderBottomColor: '#aaaaaa',// when input is not touched
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: `2px solid #000000`,
                },
                '&:hover:not($disabled):after': {
                    borderBottomColor: '#000000',//its when its hover and input is focused 
                },
                '&:hover:not($disabled):before': {
                    borderBottomColor: '#000000',//its when you hover and input is not foucused 
                },
                '&:after': {
                    borderBottomColor: '#000000',//when input is focused, Its just for example. Its better to set this one using primary color
                },
                
            },
        },
        MuiInputAdornment: {
            root: {
                color: '#dddddd',

            },
            positionEnd: {
                color: '#dddddd',
            }
        },
    	MuiCardContent:{
            root: {
                color: '#000000',
            }
        },
        MuiNativeSelect: {
            root: {
                color: '#000000',
            },
            select: {
                height: '2.25rem',
                color: '#000000',
            },
            selectMenu: {
                backgroundColor: '#000000',
                color: '#000000',
            },
            icon: {
                fill: '#000000',
            },
        },
        // MuiMenuItem: {
        //     selectedTextColor: '#000000',
        // },
        MuiExpansionPanel: {
            root: {
                color: '#000000',
                backgroundColor: '#1f4962',
                borderColor: '#000000',
                borderWidth: 3,
                borderStyle: 'solid',
            },
        },
        MuiExpansionPanelSummary: {
            expandIcon: {
                color: '#000000',
            }
        },
        MuiCheckbox: {
            root: {
                color: '#000000',
                '&$checked': {
                    color: '#000000',
                },
            }
        },
        MuiDialog: {
            root: {
                color: '#000000',
                zIndex: THEME.dialogZIndex,
            }
        },
        MuiDialogTitle: {
            root: {
                color: '#000000',
            }
        },
        MuiDialogTitleContent: {
            root: {
                color: '#000000',
            }
        },
        MuiDialogTitleContentText: {
            root: {
                color: '#000000',
            }
        },
        MuiDialogActions: {
            root: {
                color: '#000000',
            }
        },
  	},
});

export { themeMuiStyles };