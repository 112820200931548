import { AUTH_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],
    isLogin: false,
    phoneNumber: null,
    token: null,
    
}


const auth = (state = INIT_STATE, action) => {
    switch(action.type) {
        case AUTH_ACTION_TYPES.LOGIN_REQUEST: 
            return {
                ...state,
                error: [],
                isLogin: false,
                token: null,
            };
        case AUTH_ACTION_TYPES.LOGIN_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                isLogin: false,
                token: null,
            };
        case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
            return {
                ...state,
                error: [],
                isLogin: true,
                phoneNumber: action.phoneNumber,
                token: action.token,
            };        
        case AUTH_ACTION_TYPES.LOGIN_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                isLogin: false,
                token: null,
                truckId: null,
            };
        
        //##------------------------------------------------------------------

        case AUTH_ACTION_TYPES.INIT_SAVE_PHONENUMBER: 
            return {
                ...state,
                phoneNumber: action.phoneNumber,

            };
            

        //##------------------------------------------------------------------
        case AUTH_ACTION_TYPES.LOGOUT_REQUEST: 
            return {
                ...state,
                error: [],

            };
        case AUTH_ACTION_TYPES.LOGOUT_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        case AUTH_ACTION_TYPES.LOGOUT_SUCCESS:
            return {
                ...state,
                error: [],
                isLogin: false,
                token: null
            };
        //##------------------------------------------------------------------
        default: 
            return state;
    }
}


export { auth };