import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';


import { store } from './store';

import { RootPage } from './features/root/pages';



class App extends React.Component {

    state = {
        oneSignalPlayerId: null,
        newNotificationReceivedEvent: null,
    }

    constructor(props) {
        super(props);
        
        this.setOneSignalPlayerId = this.setOneSignalPlayerId.bind(this);
        this.onReceivedOneSignalOneNotification = this.onReceivedOneSignalOneNotification.bind(this);
    }

    setOneSignalPlayerId(playerId){

        //## [DEBUG]
        // console.log('setOneSignalPlayerId Fired !!')

        this.setState({
            oneSignalPlayerId: playerId,
        })
    }

    onReceivedOneSignalOneNotification(event){
        this.setState({
            newNotificationReceivedEvent: event,
        })
    }


    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <Provider store={store}>
                    <RootPage 
                        oneSignalPlayerId={this.state.oneSignalPlayerId}
                        newNotificationReceivedEvent={this.state.newNotificationReceivedEvent}
                    />
                </Provider>
            </React.Fragment>
        );
    }
}

export default App;