import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { connect } from 'react-redux';

// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';

// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { withMultipleStyles } from '../../../utilities';

import { layoutMuiStyles } from '../../../styles';
import { errorMuiStyles } from '../styles';

// import { 
//     DrawerContainers,
//     AppbarContainers, 
// } from '../../nav/containers';


// import { spinnerActions } from '../../spinner/actions';
// import { loginActions } from '../../auth/actions';
// import { navActions } from '../../nav/actions';


class ErrorPage extends React.Component {

    constructor(props){
        super(props);

        //## Initial State
        this.state = {
            errorMsg: null
        }

        //## Binding
        this.onPageFocus = this.onPageFocus.bind(this);

    }


    onPageFocus() {
        // const { dispatch } = this.props;
        // dispatch(navActions.focusErrorPage());
    }

    componentDidMount() {
        //## Navigation pass to redux store
        this.onPageFocus();

        //## error Message
        let errorMsg = this.props.error.join("\n");

        this.setState({
            errorMsg: errorMsg,
        })
    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.errorRoot}>
                <div className={classes.errorFrame}>
                    <Typography 
                        variant="h3" 
                        gutterBottom
                        color="error"
                        classes={{
                            root: classes.errorText,
                        }}
                    >
                        Error Page
                    </Typography>
                    <Typography 
                        variant="h6" 
                        gutterBottom
                        color="default"
                        classes={{
                            root: classes.errorText,
                        }}
                    >
                        กรุณาปิดแล้วเปิดแอพใหม่ค่ะ
                    </Typography>
                    <Typography 
                        variant="body2" 
                        gutterBottom
                        classes={{
                            root: classes.errorText,
                        }}
                    >
                        {this.state.errorMsg}
                    </Typography>
                    {/*

                        <Typography 
                            variant="body2" 
                            gutterBottom
                            classes={{
                                root: classes.errorText,
                            }}
                        >
                            {"token:"}
                            <br />
                            {this.props.token}
                        </Typography>
                    */}
                    {/*
                        <Typography 
                            variant="body2" 
                            gutterBottom
                            classes={{
                                root: classes.errorText,
                            }}
                        >

                            {"isLogin:"}
                            <br />
                            {
                                this.props.isLogin &&
                                'true'
                            }
                        </Typography>
                    */}

                </div>
            </div>   
        );
    }
}

ErrorPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({


    token: state.auth.token,
    isLogin: state.auth.isLogin,

    // lineUserId: state.line.userId,

    error: state.root.error,
})

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles,
    errorMuiStyles
)(ErrorPage);


const connectedTestPageContainers = connect(mapStateToProps)(
    injectedStylesContainers
);
export { connectedTestPageContainers  as ErrorPage };