

function extractValues(mappings) {
    return Object.keys(mappings);
}

function lookupValue(mappings, key) {
    return mappings[key];
}

function lookupKey(mappings, name) {
    for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
            if (name === mappings[key]) {
                return key;
            }
        }
    }
}

function extractObjectByKey(arrayObject, key) {
    return arrayObject.map((row) => row[key]);
}



export { 
	extractValues, 
	lookupValue, 
	lookupKey,

    extractObjectByKey,

};