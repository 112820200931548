const created = { 
	code: 0, 
	name: "ใหม่",
	bgColor: '#ffff00',
	fontColor: '#000000',
};
const read = { 
	code: 1, 
	name: "อ่านแล้ว",
	bgColor: '#0000FF',
	fontColor: '#ffffff',
};
const finished = { 
	code: 2, 
	name: "ตรวจสอบแล้ว",
	bgColor: '#008000',
	fontColor: '#ffffff',
};

const mapping = {
	0: created.name,
	1: read.name,
	2: finished.name,
}



const NOTIF_STATUS = {
	created: created,
	read: read,
	finished: finished,

	mapping: mapping,
};

export { NOTIF_STATUS };