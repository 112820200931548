import saveAs from 'file-saver';

import { COMPANY_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// COMPANY_STATUS,
	// COMPANY_TYPE, 
} from '../../../constants';
import { COMPANY_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { companyServices } from '../../../api/services';

//##-------------------------------------------------------------------------

function requestGetOneCompanyVerifyDocList(companyId){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_REQUEST,
		companyId,
	}
}

function successGetOneCompanyVerifyDocList(docList){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_SUCCESS,
		docList,
	}
}

function failureGetOneCompanyVerifyDocList(error){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_FAILURE,
		error,
	}
}

function emptyGetOneCompanyVerifyDocList(error){
	return {
		type: COMPANY_ACTION_TYPES.GET_ONE_COMPANY_VERIFY_DOC_LIST_EMPTY,
		error,
	}
}

function getOneCompanyVerifyDocList(companyId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestGetOneCompanyVerifyDocList(
    					companyId
    				)
    			);

    			let responseJson = await companyServices
    			.getOneCompanyVerifyDocList(
    				token,
    				companyId
    			)

    			if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							if(responseJson.data.length > 0){
								await dispatch(
									successGetOneCompanyVerifyDocList(
										responseJson.data
									)
								);

								return responseJson.data;
							}
							else{
								await dispatch(
									emptyGetOneCompanyVerifyDocList(
										COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_VERIFY_DOC_LIST_EMPTY
									)
								);

								return null;
							}
						}
						else{
							await dispatch(
								emptyGetOneCompanyVerifyDocList(
									COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_VERIFY_DOC_LIST_EMPTY
								)
							);

							return null;
						}
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetOneCompanyVerifyDocList(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetOneCompanyVerifyDocList(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureGetOneCompanyVerifyDocList(
								COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_VERIFY_DOC_LIST
							)
						);
		                return null;
					}
				}
				else{
					await dispatch(
						failureGetOneCompanyVerifyDocList(
							COMPANY_CONSTANTS.ERROR_FAILURE_GET_ONE_COMPANY_VERIFY_DOC_LIST
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetOneCompanyVerifyDocList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetOneCompanyVerifyDocList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------
function requestDownloadOneCompanyVerifyPdf(companyId,docId){
	return {
		type: COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_REQUEST,
		companyId,
		docId
	}
}

function successDownloadOneCompanyVerifyPdf(){
	return {
		type: COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_SUCCESS,
	}
}

function failureDownloadOneCompanyVerifyPdf(error){
	return {
		type: COMPANY_ACTION_TYPES.DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_FAILURE,
		error
	}
}


function downloadOneCompanyVerifyPdf(companyId,docId){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}

    	if(token){
    		try{
    			await dispatch(
    				requestDownloadOneCompanyVerifyPdf(
    					companyId,
    					docId,
    				)
    			);

    			let response = await companyServices
    			.downloadOneCompanyVerifyPdf(
    				token,
    				companyId,
    				docId,
    			)

    			if(response.status === GLOBAL_CONSTANTS.HTTP_STATUS_SUCCESS){
					try{
						let pdfData = response.data;


						let blob = new Blob([pdfData], { type: "application/pdf;charset=utf-8" });
						await saveAs(blob,docId + ".pdf")

						await dispatch(
							successDownloadOneCompanyVerifyPdf(
	
							)
						);

						return true;
					}
					catch(errSaveAsFile){
						await dispatch(
							failureDownloadOneCompanyVerifyPdf(
								errSaveAsFile.message
							)
						);
		            	return false;
					}
				}
				else{
						
					if(response.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(response.response.data);
	                        await dispatch(
	                        	failureDownloadOneCompanyVerifyPdf(errMsg)
	                        );
	                    return false;
	                }
					else if(response.requestError) {
	                    await dispatch(
	                    	failureDownloadOneCompanyVerifyPdf(
	                    		response.requestError
	                    	)
						);
	                    return false;
	                }
					else{
						await dispatch(
							failureDownloadOneCompanyVerifyPdf(
								COMPANY_CONSTANTS.ERROR_FAILURE_DOWNLOAD_ONE_COMPANY_VERIFY_PDF
							)
						);
		                return null;
					}
				}
    		}
    		catch(error){
    			await dispatch(
    				failureDownloadOneCompanyVerifyPdf(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureDownloadOneCompanyVerifyPdf(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}

//##---------------------------------------------------------------------------

export const companyVerifyDocActions = {

	requestGetOneCompanyVerifyDocList,
	successGetOneCompanyVerifyDocList,
	failureGetOneCompanyVerifyDocList,
	emptyGetOneCompanyVerifyDocList,
	getOneCompanyVerifyDocList,


	requestDownloadOneCompanyVerifyPdf,
	successDownloadOneCompanyVerifyPdf,
	failureDownloadOneCompanyVerifyPdf,
	downloadOneCompanyVerifyPdf,



}