
function extractTruckTypeSelectInput(truckTypeList) {
	let ret = [];
	if(truckTypeList){
		if(truckTypeList.length > 0){
			for (var i = 0; i < truckTypeList.length; i++){
				if(truckTypeList[i].typeName){
					ret.push(truckTypeList[i].typeName);
				}
			}
		}
	}
    return ret;
}

function extractTruckTypeCategorySelectInput(truckTypeList) {
	let ret = [];
	if(truckTypeList){
		if(truckTypeList.length > 0){
			for (var i = 0; i < truckTypeList.length; i++){
				if(truckTypeList[i].category){
					ret.push(truckTypeList[i].category);
				}
			}
		}
	}

	let newRet = ret.filter((value, index, self) => {
		return self.indexOf(value) === index;
	})


    return newRet;
}

// function truckTypeLookupValue(truckTypeList, id) {
// 	let ret = null;
// 	if(truckTypeList){
// 		if(truckTypeList.length > 0){
// 			for (var i = 0; i < truckTypeList.length; i++){
// 				if(truckTypeList[i]._id === id){
// 					ret = truckTypeList[i].typeName;
// 				}
// 			}
// 		}
// 	}
//     return ret;
// }

// function truckTypeLookupKey(truckTypeList, typeName) {
//     let ret = null;
// 	if(truckTypeList){
// 		if(truckTypeList.length > 0){
// 			for (var i = 0; i < truckTypeList.length; i++){
// 				if(truckTypeList[i].typeName === typeName){
// 					ret = truckTypeList[i]._id;
// 				}
// 			}
// 		}
// 	}
//     return ret;
// }


export { 
	extractTruckTypeSelectInput, 
	extractTruckTypeCategorySelectInput,
	// truckTypeLookupValue,
	// truckTypeLookupKey,

};