import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

// import { spinnerActions } from '../../spinner/actions';
import { navActions } from '../../nav/actions';

// import { truckTypesActions } from '../../trucktypes/actions';
// import { spinnerActions } from '../../spinner/actions';
// import { alertActions } from '../../alert/actions';

import { layoutMuiStyles } from '../../../styles';
import { 
    DrawerContainers,
    AppbarContainers, 
} from '../../nav/containers';

import { 
    NotifListTableContainers,

} from '../../notif/containers';

class DashboardPage extends React.Component {

    constructor(props){
        super(props);

        this.onPageFocus = this.onPageFocus.bind(this);
        // this.initTruckTypeList = this.initTruckTypeList.bind(this);
    }

    async componentWillMount(): Promise<void>{

        //## Init truck type when load page
        // await this.initTruckTypeList();

        return Promise.resolve();
    }

    onPageFocus() {
        const { dispatch } = this.props;
        dispatch(navActions.focusDashboardPage());
    }

    componentDidMount() {
        //## Navigation pass to redux store
        this.onPageFocus();
    }

    //##---------------------------------------------------------------------
    async initTruckTypeList(){
        // const { dispatch } = this.props;

        // await dispatch(spinnerActions.showSpinner());

        // let truckTypeList = await dispatch(truckTypesActions.getAllTruckTypeList());

        // await dispatch(spinnerActions.hideSpinner());

        // if(!truckTypeList){
        //     let errorMsg = this.props.trucktypesError.join("\n");
        //     await dispatch(alertActions.showAlert('Init Truck Type List',errorMsg,'error'));
        // }
        // else{
            
        // }
        
        return Promise.resolve();
    }

    
    render() {
        const { classes } = this.props;
        return (
            <div 
                className={classes.root}
            >
                <div 
                    className={classes.appFrame}
                >
                    <AppbarContainers />
                    <DrawerContainers />
                    <main 
                        className={classNames(
                            classes.content, 
                            this.props.isDrawerOpen && classes.contentShift
                            )
                        }
                    >
                        <div
                            className={classes.innerContainer}
                        >
                            <h2>
                                {' การแจ้งเตือน'}
                            </h2>
                            <NotifListTableContainers />

                            <div className={classes.divider2} />
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}


DashboardPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    isDrawerOpen: state.nav.isDrawerOpen,

    // trucktypesError: state.trucktypes.error,
})

const connectedDashboardPage = connect(mapStateToProps)(withStyles(
    layoutMuiStyles, 
    { withTheme: true }
)(DashboardPage));
export { connectedDashboardPage  as DashboardPage };