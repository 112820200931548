import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 

    faTruck,

} from '@fortawesome/free-solid-svg-icons';

// import { spinnerActions } from '../../spinner/actions';
import { navActions } from '../../nav/actions';

import { truckTypeListActions } from '../../trucks/actions';
import { spinnerActions } from '../../spinner/actions';
// import { alertActions } from '../../alert/actions';

import { layoutMuiStyles } from '../../../styles';
import { 
    DrawerContainers,
    AppbarContainers, 
} from '../../nav/containers';

import { 
    TruckListTableContainers,

} from '../../trucks/containers';


class TruckListPage extends React.Component {

    constructor(props){
        super(props);

        this.onPageFocus = this.onPageFocus.bind(this);
        this.initTruckTypeList = this.initTruckTypeList.bind(this);
    }

    async componentWillMount(): Promise<void>{

        //## Init truck type when load page
        await this.initTruckTypeList();

        return Promise.resolve();
    }


    onPageFocus() {
        const { dispatch } = this.props;
        dispatch(navActions.focusTruckListPage());
    }

    componentDidMount() {
        //## Navigation pass to redux store
        this.onPageFocus();
    }

    //##---------------------------------------------------------------------
    async initTruckTypeList(): Promise<void>{
        const { dispatch } = this.props;

        await dispatch(spinnerActions.showSpinner());

        //## Init truck type when load page
        await dispatch(truckTypeListActions.getAllTruckTypeList());


        await dispatch(spinnerActions.hideSpinner());

        return Promise.resolve();
    }

    
    render() {
        const { classes } = this.props;
        return (
            <div 
                className={classes.root}
            >
                <div 
                    className={classes.appFrame}
                >
                    <AppbarContainers />
                    <DrawerContainers />
                    <main 
                        className={classNames(
                            classes.content, 
                            this.props.isDrawerOpen && classes.contentShift
                            )
                        }
                    >
                        <div
                            className={classes.innerContainer}
                        >
                            <h2>
                                <FontAwesomeIcon 
                                    icon={faTruck} 
                                /> 
                                {' รายชื่อ รถบรรทุก'}
                            </h2>
                            <TruckListTableContainers />

                            <div className={classes.divider2} />
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}


TruckListPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    isDrawerOpen: state.nav.isDrawerOpen,

    // trucktypesError: state.trucktypes.error,
})

const connectedPage = connect(mapStateToProps)(withStyles(
    layoutMuiStyles, 
    { withTheme: true }
)(TruckListPage));
export { connectedPage  as TruckListPage };