import { THEME } from '../../../styles';

const spinnerMuiStyles = theme => ({
    overlay:{
        position:'absolute', 
        left:0, 
        right:0, 
        top:0, 
        bottom: 0, 
        zIndex: theme.zIndex.spinner,
        display:'flex', 
        justifyContent:'center', 
        flexDirection:'column',
        alignItems: 'center', 
    },
    container: {
        position:'absolute', 
        left:0, 
        right:0, 
        top:0, 
        bottom: 0,
        backgroundColor:'black', 
        opacity:0.7
    },
    inidicatorContainer:{
        backgroundColor:'transparent', 
        display:'flex',
        height: "100%",
        flex: 1, 
        justifyContent:'center', 
        flexDirection:'column',
        alignItems: 'center',
    },
    indicatorIcon:{
        color: THEME.spinnerIndicatorColor,
        fontSize: THEME.spinnerIndicatorSize,
    },
    indicatorText:{
        color:'#ffffff',
        fontSize: 24
    }




});

export { 

    spinnerMuiStyles,
};