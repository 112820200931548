export const COMPANY_CONSTANTS = {
	ERROR_FAILURE_PAGINATE_COMPANY: 'ERROR_FAILURE_PAGINATE_COMPANY',
	ERROR_FAILURE_COUNT_PAGINATE_COMPANY: 'ERROR_FAILURE_COUNT_PAGINATE_COMPANY',
	ERROR_FAILURE_EDIT_ONE_COMPANY_INFO: 'ERROR_FAILURE_EDIT_ONE_COMPANY_INFO',
	
	ERROR_FAILURE_GET_ONE_COMPANY_VERIFY_DOC_LIST: 'ERROR_FAILURE_GET_ONE_COMPANY_VERIFY_DOC_LIST',
	ERROR_FAILURE_GET_ONE_COMPANY_VERIFY_DOC_LIST_EMPTY: 'ไม่มีเอกสาร',

	ERROR_FAILURE_DOWNLOAD_ONE_COMPANY_VERIFY_PDF: 'ERROR_FAILURE_DOWNLOAD_ONE_COMPANY_VERIFY_PDF',


	ERROR_FAILURE_GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST: 'ERROR_FAILURE_GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST',
	ERROR_FAILURE_GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_EMPTY: 'ไม่มีเอกสาร',

	ERROR_FAILURE_DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_PDF: 'ERROR_FAILURE_DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_PDF',

	ERROR_FAILURE_DELETE_ONE_COMPANY_INFO: 'ERROR_FAILURE_DELETE_ONE_COMPANY_INFO',
	
}