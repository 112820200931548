import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSave,

} from '@fortawesome/free-solid-svg-icons';

import { 
    APP_CONSTANTS,
} from '../../../constants';

// import { 
//     IMAGES,
// } from '../../../assets/images';

import { 
    withMultipleStyles,
} from '../../../utilities';

import { 
    layoutMuiStyles,
    // THEME, 
} from '../../../styles';

import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
// import { spinnerActions } from '../../spinner/actions';
// import { lightboxActions } from '../../lightbox/actions';

import {
    editUserActions,
} from '../actions';

class CreateNewUserDialog extends React.Component {

    state = {

        phoneNumber: '',
        email: '',
        name: '',
        companyRefId: '',

        isValidSave: false,

    }


    constructor(props) {
        super(props);

        this.onChangeTextInput = this.onChangeTextInput.bind(this);
        this.onCheckValidSave = this.onCheckValidSave.bind(this);
        this.onSaveCreateNewUser = this.onSaveCreateNewUser.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let retState = null;

        


        return retState;
    }


    componentDidMount(){

    }


    componentDidUpdate(prevProps,prevState){
        if(prevState.phoneNumber !== this.state.phoneNumber){
            this.onCheckValidSave();
        }
    }


    onCloseDialog = async() => {
        await this.setState({
            phoneNumber: '',
            email: '',
            name: '',
            companyRefId: '',    
        })

        if(this.props.handleClose){
            await this.props.handleClose();
        }
    }


    onChangeTextInput = (name) => (event) => {
        this.setState({ [name]: event.target.value });
    }

    // onCheckValidSave = () => {
    //     let {
    //         phoneNumber
    //     } = this.state;

    //     let pureText = phoneNumber.replace(/-/g, '');

    //     let regex = new RegExp('0[0-9]{9}');

    //     if(regex.test(pureText) && pureText.length === 10){
    //         this.setState({
    //             isValidSave: true
    //         })
    //     }
    //     else{
    //         this.setState({
    //             isValidSave: false
    //         })    
    //     }
    // }

     onCheckValidSave = () => {
        let {
            email
        } = this.state;



        if(email){
            this.setState({
                isValidSave: true
            })
        }
        else{
            this.setState({
                isValidSave: false
            })    
        }
    }

    onSaveCreateNewUser = async() => {
        const { dispatch } = this.props;

        let {
            name,
            email,
            phoneNumber,
            companyRefId,
        } = this.state;

        let payload = {
            name: name,
            email: email ? email : null,
            phoneNumber: phoneNumber ? phoneNumber.replace(/-/g, '') : null,
            companyRefId: companyRefId,
        }

        let isSuccess = await dispatch(editUserActions.createNewUserInfo(
                payload
            )
        )

        if(isSuccess){
            await dispatch(alertActions.showAlert(
                    APP_CONSTANTS.SUCCESS_DIALOG_TITLE,
                    APP_CONSTANTS.SUCCESS_EDIT_MESSAGE,
                    ALERT_CONSTANTS.SUCCESS_MODE
                )
            )

            if(this.props.reloadAllDataFn){
                await this.props.reloadAllDataFn();
            }

            await this.onCloseDialog();
        }
        else{
            let errorMsg = this.props.userError.join('\n');

            await dispatch(alertActions.showAlert(
                    APP_CONSTANTS.ERROR_DIALOG_TITLE,
                    errorMsg,
                    ALERT_CONSTANTS.ERROR_MODE
                )
            )
        }
    }


    render() {
        const { classes } = this.props;
        // let { 
        //     docList, 

        // } = this.state;


        return (
            <div>
                <Dialog
                    open={this.props.isVisible}
                    onClose={this.onCloseDialog}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="cnu-dialog-title"
                    aria-describedby="cnu-dialog-description"
                >
                    <DialogTitle id="cnu-dialog-title">
                        {"เพิ่ม User ใหม่"}
                    </DialogTitle>
                    <DialogContent>
                        <Grid 
                            container 
                            spacing={8}
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <TextField
                                    id="cnu-email"
                                    label="Email *"
                                    className={classes.textField}
                                    value={this.state.email}
                                    onChange={this.onChangeTextInput('email')}
                                    margin="normal"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="cnu-phoneNumber"
                                    label="Phone Number"
                                    className={classes.textField}
                                    value={this.state.phoneNumber}
                                    onChange={this.onChangeTextInput('phoneNumber')}
                                    margin="normal"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="cnu-name"
                                    label="Name"
                                    className={classes.textField}
                                    value={this.state.name}
                                    onChange={this.onChangeTextInput('name')}
                                    margin="normal"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="cnu-company-ref-id"
                                    label="Company Id"
                                    className={classes.textField}
                                    value={this.state.companyRefId}
                                    onChange={this.onChangeTextInput('companyRefId')}
                                    margin="normal"
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={this.onCloseDialog} 
                            color="primary" 
                            autoFocus
                        >
                            {'ปิด'}
                        </Button>
                        <Button 
                            onClick={() => this.onSaveCreateNewUser()} 
                            color="primary" 
                            autoFocus
                            disabled={!this.state.isValidSave}
                        >
                            <FontAwesomeIcon 
                                icon={faSave}
                                className={
                                    classNames(
                                        classes.leftIcon, 
                                        classes.iconSmall
                                    )
                                }
                            />
                            {'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};



const mapStateToProps = (state) => ({
    userError: state.users.error,

})


CreateNewUserDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,

    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    // userId: PropTypes.string,

    reloadAllDataFn: PropTypes.func,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(CreateNewUserDialog);


const connectedContainers = connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as CreateNewUserDialog };