export const COMPANY_ACTION_TYPES = {
	
	GET_PAGINATE_COMPANY_LIST_REQUEST: 'GET_PAGINATE_COMPANY_LIST_REQUEST',
	GET_PAGINATE_COMPANY_LIST_SUCCESS: 'GET_PAGINATE_COMPANY_LIST_SUCCESS',
	GET_PAGINATE_COMPANY_LIST_SUCCESS_ADD_MORE: 'GET_PAGINATE_COMPANY_LIST_SUCCESS_ADD_MORE',
	GET_PAGINATE_COMPANY_LIST_FAILURE: 'GET_PAGINATE_COMPANY_LIST_FAILURE',
	GET_PAGINATE_COMPANY_LIST_EMPTY: 'GET_PAGINATE_COMPANY_LIST_EMPTY',


	COUNT_PAGINATE_COMPANY_LIST_REQUEST: 'COUNT_PAGINATE_COMPANY_LIST_REQUEST',
	COUNT_PAGINATE_COMPANY_LIST_SUCCESS: 'COUNT_PAGINATE_COMPANY_LIST_SUCCESS',
	COUNT_PAGINATE_COMPANY_LIST_FAILURE: 'COUNT_PAGINATE_COMPANY_LIST_FAILURE',
	COUNT_PAGINATE_COMPANY_LIST_EMPTY: 'COUNT_PAGINATE_COMPANY_LIST_EMPTY',

	UPDATE_ONE_COMPANY_INFO_REQUEST: 'UPDATE_ONE_COMPANY_INFO_REQUEST',
	UPDATE_ONE_COMPANY_INFO_SUCCESS: 'UPDATE_ONE_COMPANY_INFO_SUCCESS',
	UPDATE_ONE_COMPANY_INFO_FAILURE: 'UPDATE_ONE_COMPANY_INFO_FAILURE',

	UPDATE_ONE_COMPANY_BADGE_INFO_REQUEST: 'UPDATE_ONE_COMPANY_BADGE_INFO_REQUEST',
	UPDATE_ONE_COMPANY_BADGE_INFO_SUCCESS: 'UPDATE_ONE_COMPANY_BADGE_INFO_SUCCESS',
	UPDATE_ONE_COMPANY_BADGE_INFO_FAILURE: 'UPDATE_ONE_COMPANY_BADGE_INFO_FAILURE',

	//## Company Verify Document
	GET_ONE_COMPANY_VERIFY_DOC_LIST_REQUEST: 'GET_ONE_COMPANY_VERIFY_DOC_LIST_REQUEST',
	GET_ONE_COMPANY_VERIFY_DOC_LIST_SUCCESS: 'GET_ONE_COMPANY_VERIFY_DOC_LIST_SUCCESS',
	GET_ONE_COMPANY_VERIFY_DOC_LIST_FAILURE: 'GET_ONE_COMPANY_VERIFY_DOC_LIST_FAILURE',
	GET_ONE_COMPANY_VERIFY_DOC_LIST_EMPTY: 'GET_ONE_COMPANY_VERIFY_DOC_LIST_EMPTY',


	DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_REQUEST: 'DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_REQUEST',
	DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_SUCCESS: 'DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_SUCCESS',
	DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_FAILURE: 'DOWNLOAD_ONE_COMPANY_VERIFY_DOC_PDF_FAILURE',


	//## Company Bank Account Document
	GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_REQUEST: 'GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_REQUEST',
	GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_SUCCESS: 'GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_SUCCESS',
	GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_FAILURE: 'GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_FAILURE',
	GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_EMPTY: 'GET_ONE_COMPANY_BANK_ACCOUNT_DOC_LIST_EMPTY',


	DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_REQUEST: 'DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_REQUEST',
	DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_SUCCESS: 'DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_SUCCESS',
	DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_FAILURE: 'DOWNLOAD_ONE_COMPANY_BANK_ACCOUNT_DOC_PDF_FAILURE',


	DELETE_ONE_COMPANY_INFO_REQUEST: 'DELETE_ONE_COMPANY_INFO_REQUEST',
	DELETE_ONE_COMPANY_INFO_SUCCESS: 'DELETE_ONE_COMPANY_INFO_SUCCESS',
	DELETE_ONE_COMPANY_INFO_FAILURE: 'DELETE_ONE_COMPANY_INFO_FAILURE',

}