export const NAV_ACTION_TYPES = {

	DRAWER_OPEN: 'DRAWER_OPEN',
	DRAWER_CLOSE: 'DRAWER_CLOSE',

	NAV_ERROR_PAGE_START: 'NAV_ERROR_PAGE_START',
	NAV_ERROR_PAGE_FOCUS: 'NAV_ERROR_PAGE_FOCUS',

	NAV_LOGIN_START: 'NAV_LOGIN_START',
	NAV_LOGIN_FOCUS: 'NAV_LOGIN_FOCUS',

	NAV_SAVE_STARTER_URL: 'NAV_SAVE_STARTER_URL',
	NAV_ANY_PAGE_START: 'NAV_ANY_PAGE_START',

	NAV_DASHBOARD_START: 'NAV_DASHBOARD_START',
	NAV_DASHBOARD_FOCUS: 'NAV_DASHBOARD_FOCUS',


	NAV_COMPANY_LIST_START: 'NAV_COMPANY_LIST_START',
	NAV_COMPANY_LIST_FOCUS: 'NAV_COMPANY_LIST_FOCUS',

	NAV_USER_LIST_START: 'NAV_USER_LIST_START',
	NAV_USER_LIST_FOCUS: 'NAV_USER_LIST_FOCUS',


	NAV_DRIVER_LIST_START: 'NAV_DRIVER_LIST_START',
	NAV_DRIVER_LIST_FOCUS: 'NAV_DRIVER_LIST_FOCUS',

	NAV_TRUCK_LIST_START: 'NAV_TRUCK_LIST_START',
	NAV_TRUCK_LIST_FOCUS: 'NAV_TRUCK_LIST_FOCUS',

	NAV_TRUCK_TYPE_LIST_START: 'NAV_TRUCK_TYPE_LIST_START',
	NAV_TRUCK_TYPE_LIST_FOCUS: 'NAV_TRUCK_TYPE_LIST_FOCUS',

	NAV_ADMIN_COMPANY_STATUS_LIST_START: 'NAV_ADMIN_COMPANY_STATUS_LIST_START',
	NAV_ADMIN_COMPANY_STATUS_LIST_FOCUS: 'NAV_ADMIN_COMPANY_STATUS_LIST_FOCUS',


	NAV_JOB_LIST_START: 'NAV_JOB_LIST_START',
	NAV_JOB_LIST_FOCUS: 'NAV_JOB_LIST_FOCUS',


}