import { JOBS_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],


    //## job Data List
    jobListPaginatePage: 0,
    jobListPaginateLimit: null,
    jobListPaginateSortBy: null,
    jobList: [],
    jobListTotalNum: null,


   
}

const jobs = (state = INIT_STATE, action) => {
    switch(action.type) {
        
        //###--------------------------------------------------
    
        case JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_REQUEST: 
            return {
                ...state,
            };
        case JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_SUCCESS: 
            return {
                ...state,
                jobList: action.jobList,
                jobListPaginatePage: action.page,
                jobListPaginateLimit: action.limit,
                jobListPaginateSortBy: action.sortBy,
            };
        case JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_SUCCESS_ADD_MORE: 
            return {
                ...state,
                jobList: [...state.jobList, ...action.jobList],
                jobListPaginatePage: action.page,
                jobListPaginateLimit: action.limit,
                jobListPaginateSortBy: action.sortBy,
            };
        case JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };      
        case JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //###--------------------------------------------------
    
        case JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_REQUEST: 
            return {
                ...state,
            };
        case JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_SUCCESS: 
            return {
                ...state,
                jobListTotalNum: action.jobListTotalNum,
            };
        case JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                jobListTotalNum: null,
            };      
        case JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                jobListTotalNum: 0,
            };   
        //###--------------------------------------------------
        
        //###--------------------------------------------------

        
        default: 
            return state;
    }

}

export { jobs };