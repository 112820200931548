export const USERS_CONSTANTS = {
	ERROR_FAILURE_PAGINATE_USER: 'ERROR_FAILURE_PAGINATE_USER',
	ERROR_FAILURE_COUNT_PAGINATE_USER: 'ERROR_FAILURE_COUNT_PAGINATE_USER',
	ERROR_FAILURE_EDIT_ONE_USER_INFO: 'ERROR_FAILURE_EDIT_ONE_USER_INFO',
	
	ERROR_FAILURE_EDIT_USER_COMPANY_INFO: 'ERROR_FAILURE_EDIT_USER_COMPANY_INFO',
	ERROR_FAILURE_CREATE_ONE_USER_INFO: 'ERROR_FAILURE_CREATE_ONE_USER_INFO',

	ERROR_FAILURE_EDIT_USER_CONTACT_NAME: 'ERROR_FAILURE_EDIT_USER_CONTACT_NAME',

	ERROR_FAILURE_DELETE_ONE_USER_INFO: 'ERROR_FAILURE_DELETE_ONE_USER_INFO',
}