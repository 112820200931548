import { TRUCKS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// USER_STATUS,

} from '../../../constants';
import { TRUCKS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { truckServices } from '../../../api/services';



function requestEditOneTruckInfo(){
	return {
		type: TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_INFO_REQUEST
	}
}

function successEditOneTruckInfo(){
	return {
		type: TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_INFO_SUCCESS,
	}
}



function failureEditOneTruckInfo(error){
	return {
		type: TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_INFO_FAILURE,
		error,
	}
}


function editOneTruckInfo(oneRowData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneTruckInfo());


    			let payload ={
		    		truckId: oneRowData._id,
			        truckTypeId: oneRowData.newTruckTypeId ? oneRowData.newTruckTypeId : (oneRowData.truckType ? oneRowData.truckType._id : null),
			        plateId: oneRowData.plateId ? oneRowData.plateId : null,
			        plateIdProvince: oneRowData.plateIdProvince ? oneRowData.plateIdProvince : null, 
			        childPlateId: oneRowData.childPlateId ? oneRowData.childPlateId : null,
			        childPlateIdProvince: oneRowData.childPlateIdProvince ? oneRowData.childPlateIdProvince : null,
			        weight: oneRowData.weight ? oneRowData.weight : null,
			        width: oneRowData.width ? oneRowData.width : null,
			        depth: oneRowData.depth ? oneRowData.depth : null,
			        height: oneRowData.height ? oneRowData.height : null,
			        // size: oneRowData.size ? oneRowData.size : null,
			        childWeight: oneRowData.childWeight ? oneRowData.childWeight : null, 
			        childWidth: oneRowData.childWidth ? oneRowData.childWidth : null,
			        childDepth: oneRowData.childDepth ? oneRowData.childDepth : null,
			        childHeight: oneRowData.childHeight ? oneRowData.childHeight : null,
			        // childSize: oneRowData.childSize ? oneRowData.childSize : null,
			        // fuelType: oneRowData.fuelType ? oneRowData.fuelType : null,
			        haveCargoInsurance: oneRowData.haveCargoInsurance ? oneRowData.haveCargoInsurance : false,
			        cargoInsuranceCoverage: oneRowData.cargoInsuranceCoverage ? oneRowData.cargoInsuranceCoverage : null,
			        cargoInsuranceExpire: oneRowData.cargoInsuranceExpire ? oneRowData.cargoInsuranceExpire : null,
			        plateExpire: oneRowData.plateExpire ? oneRowData.plateExpire : null,

		    	}

		    	//## [DEBUG]
	    		// console.log('-----Truck Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await truckServices
    			.putUpdateOneTruckInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneTruckInfo(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneTruckInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneTruckInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneTruckInfo(
								TRUCKS_CONSTANTS.ERROR_FAILURE_EDIT_ONE_TRUCK_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneTruckInfo(
							TRUCKS_CONSTANTS.ERROR_FAILURE_EDIT_ONE_TRUCK_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneTruckInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneTruckInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


export const editTruckActions = {


	requestEditOneTruckInfo,
	successEditOneTruckInfo,
	failureEditOneTruckInfo,
	editOneTruckInfo,


}