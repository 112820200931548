// import grey from '@material-ui/core/colors/grey';
import { THEME } from '../styles';
import indigo from '@material-ui/core/colors/indigo';

const drawerWidth = 300;
const overlaySpacing = 24; // 0,8,16,24

const layoutMuiStyles = theme => ({
    overlay:{
        flexGrow: 1,
        paddingLeft: overlaySpacing,
        paddingRight: overlaySpacing,
    },
    root: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',

    },
    appFrame: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
    },
    appBarContainer: {
        flex: 1
    },
    appBar: {
        position: 'absolute',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarRightIconContainer: {
        position: 'absolute',
        right: 50,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        height: '100%',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerIcon:{
        fontSize: theme.typography.h5.fontSize,
    },
    content: {
        width: '100%',
        marginLeft: -drawerWidth,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // height: 'calc(100vh - 56px)',
        // height: 'calc(100%' + ' - ' + (56 + (theme.spacing.unit * 6)) + 'px)',
        // height: `calc(100% - ${56 + (theme.spacing.unit * 6)}px)`,

        marginTop: 56,
        [theme.breakpoints.up('sm')]: {
            // height: 'calc(100% - 64px)',
            // height: 'calc(100%' + ' - ' + (64 + (theme.spacing.unit * 6)) + 'px)',
            // height: `calc(100% - ${64 + (theme.spacing.unit)}px)`,
            marginTop: 64,
        },
        overflowY: 'auto',
        overflowX: 'auto',
    },
    contentShift: {
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    innerContainer:{
        width: '100%',
        // height: '100%',
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    paper: {
        width: '100%',
        height: '100%',
        zIndex: 5,
        padding: theme.spacing.unit
    },
    mediaImageThumbnails: {
        width: 120,
        height: 80,
    },
    cardThumbnails:{
        // marginLeft: 20,
    },
    cardMedia:{
        width: 500, 
        height: 300,
    },
    buttonUpload:{
        backgroundColor: indigo
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    textLinkSuccess: {
        color: 'green'
    },
    textLinkDanger: {
        color: 'red'
    },
    inputCenter: {
        textAlign: 'center !important',
    },


    //##----------------- V0.4 --------------
    

    divider: {
        paddingTop: theme.spacing.unit / 2,
        paddingBottom: theme.spacing.unit  / 2,
    },
    divider2: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    hr: {
        // borderBottomColor:  '#DDDDDD',
        // borderBottomWidth: 5,
        margin: 0,
        width: '100%',
        height: 1,
        backgroundColor: '#dddddd',
    },
    spacing: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    spacing4: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },

    agGridContainer: {
        width: '100%',
        minHeight: 500,
    },
    agGridTableToolbarContainer:{
        paddingBottom: 20,
        textAlign: 'left',
    },
    agGridTable:{
        width: '100%',
        height: '100%',
    },
    agGridCellButton:{
        height: '100%',
        width: '100%',
    },

    dangerButton:{
        backgroundColor: '#CD0000',
        color: '#ffffff',
    },

    paginationButton: {
        backgroundColor: '#2bd5c6',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#2bd5c6',
        }
    },

    lightboxReactModal: {
        zIndex: theme.zIndex.lightBox
    },
    

    documentModal: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    dialogMenu: {
        zIndex: THEME.dialogMenuItemZIndex,
    },
    dialogMenuItem: {
        zIndex: THEME.dialogMenuItemZIndex,
    },
    dialogTextField: {
        zIndex: THEME.dialogTextFieldZIndex,
    },

    listNotification:{
        width: '400px',
        height: '400px',
        overflowX: 'auto',
        overflowY: 'auto',
    },
    listNotificationItem:{
        borderBottom: '1px solid #e6e6e6',
    },

    paperRoot:{
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    }

});

export { 
    drawerWidth,
    overlaySpacing, 
    layoutMuiStyles,
};