import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';





import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes,
    faSave,

} from '@fortawesome/free-solid-svg-icons';

import { 
    APP_CONSTANTS,
    COMPANY_BADGE_LIST,
} from '../../../constants';

// import { 
//     IMAGES,
// } from '../../../assets/images';

import { 
    withMultipleStyles,
} from '../../../utilities';

import { 
    layoutMuiStyles,
    // THEME, 
} from '../../../styles';

import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';
// import { lightboxActions } from '../../lightbox/actions';

import {

    editCompanyActions,
} from '../actions';

class CompanyBadgeEditDialog extends React.Component {

    state = {
        companyId: null,

        badgeList: [],

        
        badgeInput: '',

    }


    constructor(props) {
        super(props);

        this.onPrefetchBadgeList = this.onPrefetchBadgeList.bind(this);

        this.onCloseDialog = this.onCloseDialog.bind(this);

        this.onChangeBadge = this.onChangeBadge.bind(this);
        this.onAddOneBadge = this.onAddOneBadge.bind(this);
        this.onDeleteOneBadge = this.onDeleteOneBadge.bind(this);
        this.onSaveEditBadge = this.onSaveEditBadge.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let retState = null;

        let {
            companyId,
        } = nextProps;


        if(companyId !== prevState.companyId){
            retState = {
                ...retState,
                companyId: companyId,
            }
        }




        return retState;
    }


    componentDidMount(){

    }


    componentDidUpdate(prevProps,prevState){
        if(prevState.companyId !== this.state.companyId){
            this.onPrefetchBadgeList();
        }
    }



    onPrefetchBadgeList = () => {
        if(this.props.companyList){
            if(this.props.companyList.length > 0){
                for (var i = 0; i < this.props.companyList.length; i++){
                    if(this.props.companyList[i]._id === this.state.companyId){
                        this.setState({
                            badgeList: this.props.companyList[i].badge,
                        })

                        break;
                    }
                }
            }
        }
    }




    onCloseDialog = () => {
        // const { dispatch } = this.props;

        // dispatch(lightboxActions.closeLightbox());

        // this.setState({
        //     isOpenLightBox: false,
        // })


        this.props.handleClose();
    }

    onChangeBadge = (name) => (event) => {
        this.setState({ [name]: event.target.value });
    }

    onAddOneBadge = () => {
        let {
            badgeList,
            badgeInput
        } = this.state;

        if(badgeInput){

            let newBadgeList = [...badgeList];
            newBadgeList.push(badgeInput);

            this.setState({
                badgeList: newBadgeList,
            })
        }

    }

    onDeleteOneBadge = (oneBadge) => {
        let {
            badgeList
        } = this.state;



        let newBadgeList = [...badgeList];
        newBadgeList = newBadgeList.filter((row)=>{
            return row !== oneBadge;
        });

        //## [DEBUG]
        // console.log('onDeleteOneBadge');
        // console.log(newBadgeList);

        this.setState({
            badgeList: newBadgeList,
        })
    }


    onSaveEditBadge = async() => {
        const { dispatch } = this.props;

        let editedData = {
            _id: this.state.companyId,
            badge: this.state.badgeList,
        }

        await dispatch(spinnerActions.showSpinner());

        let isSuccess = await dispatch(
            editCompanyActions.editOneCompanyBadgeInfo(
                editedData
            )
        )

        if(isSuccess){
            await dispatch(alertActions.showAlert(
                    APP_CONSTANTS.SUCCESS_DIALOG_TITLE,
                    APP_CONSTANTS.SUCCESS_EDIT_MESSAGE,
                    ALERT_CONSTANTS.SUCCESS_MODE
                )
            )

            if(this.props.reloadAllDataFn){
                await this.props.reloadAllDataFn();
            }

            if(this.props.handleClose){
                await this.props.handleClose();
            }
        }
        else{
            let errorMsg = this.props.companyError.join('\n');

            await dispatch(alertActions.showAlert(
                    APP_CONSTANTS.ERROR_DIALOG_TITLE,
                    errorMsg,
                    ALERT_CONSTANTS.ERROR_MODE
                )
            )
        }

        await dispatch(spinnerActions.hideSpinner());

    }




    render() {
        const { classes } = this.props;
        let { 
            badgeList, 

        } = this.state;


        return (
            <div>
                <Dialog
                    open={this.props.isVisible}
                    onClose={this.onCloseDialog}
                    fullWidth={true}
                    maxWidth={'xl'}
                    aria-labelledby="cbed-dialog-title"
                    aria-describedby="cbed-dialog-description"
                >
                    <DialogTitle id="cbed-dialog-title">
                        {"แก้ไข Badge"}
                    </DialogTitle>
                    <DialogContent>
                        <Grid 
                            container 
                            spacing={8}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                <TextField
                                    id="cbed-select-badge"
                                    select
                                    label="เลือก"
                                    className={classes.dialogTextField}
                                    value={this.state.badgeInput}
                                    onChange={this.onChangeBadge('badgeInput')}
                                    SelectProps={{
                                        MenuProps: {

                                            className: classes.dialogMenu,
                                        },
                                    }}
                                    helperText="เลือกแล้วกดเพิ่ม"
                                    margin="normal"
                                    fullWidth={true}
                                >
                                    {
                                        COMPANY_BADGE_LIST.map(option => (
                                            <MenuItem 
                                                key={option.value} 
                                                value={option.value}
                                                className={classes.dialogMenuItem}
                                            >
                                                {option.value}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Button 
                                    onClick={() => this.onAddOneBadge()} 
                                    color={'primary'} 
                                    // className={classes.paginationButton}
                                    variant="contained"
                                    disabled={false}
                                    fullWidth={true}
                                >
                                    {'เพิ่ม'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={8}
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid 
                                item 
                                xs={12}
                            >
                                <Typography 
                                    variant="h6" 
                                >
                                    {'Badge ที่มีอยู่: '}
                                </Typography>
                            </Grid>
                            {
                                badgeList.map((oneBadge) => {
                                    return (
                                        <Grid item xs={12} key={oneBadge}>
                                            <Button 
                                                onClick={() => this.onDeleteOneBadge(oneBadge)} 
                                                color="primary" 
                                                autoFocus
                                            >
                                                <FontAwesomeIcon 
                                                    icon={faTimes}
                                                    className={
                                                        classNames(
                                                            classes.leftIcon, 
                                                            classes.iconSmall
                                                        )
                                                    }
                                                />
                                                {
                                                    oneBadge
                                                }
                                            </Button>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={this.props.handleClose} 
                            color="primary" 
                            autoFocus
                        >
                            {'ปิด'}
                        </Button>
                        <Button 
                            onClick={() => this.onSaveEditBadge()} 
                            color="primary" 
                            autoFocus
                        >
                            <FontAwesomeIcon 
                                icon={faSave}
                                className={
                                    classNames(
                                        classes.leftIcon, 
                                        classes.iconSmall
                                    )
                                }
                            />
                            {'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};



const mapStateToProps = (state) => ({
    companyError: state.company.error,

    companyList: state.company.companyList,
})


CompanyBadgeEditDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,

    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    companyId: PropTypes.string,

    reloadAllDataFn: PropTypes.func,

}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(CompanyBadgeEditDialog);


const connectedContainers = connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as CompanyBadgeEditDialog };