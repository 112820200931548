import { TRUCKS_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],

    //## Truck Type List
    truckTypeList: [],

    //## Truck Data List
    truckListPaginatePage: 0,
    truckListPaginateLimit: null,
    truckListPaginateSortBy: null,
    truckList: [],
    truckListTotalNum: null,

    //## Truck Cargo Insurance Doc List
    requestTruckCargoInsuranceDocTruckId: null,
    truckCargoInsuranceDocList: [],

    requestDownloadTruckCargoInsurancePdfTruckId: null,
    requestDownloadTruckCargoInsurancePdfDocId: null,
   
}

const trucks = (state = INIT_STATE, action) => {
    switch(action.type) {
        
        //###--------------------------------------------------
    
        case TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_REQUEST: 
            return {
                ...state,
            };
        case TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_SUCCESS: 
            return {
                ...state,
                truckTypeList: action.truckTypeList,

            };
        case TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };      
        case TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //###--------------------------------------------------
        //###--------------------------------------------------
    
        case TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_TYPE_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_TYPE_INFO_SUCCESS: 
            return {
                ...state,

            };
        case TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_TYPE_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };
        //###--------------------------------------------------
    
        case TRUCKS_ACTION_TYPES.GET_PAGINATE_TRUCK_LIST_REQUEST: 
            return {
                ...state,
            };
        case TRUCKS_ACTION_TYPES.GET_PAGINATE_TRUCK_LIST_SUCCESS: 
            return {
                ...state,
                truckList: action.truckList,
                truckListPaginatePage: action.page,
                truckListPaginateLimit: action.limit,
                truckListPaginateSortBy: action.sortBy,
            };
        case TRUCKS_ACTION_TYPES.GET_PAGINATE_TRUCK_LIST_SUCCESS_ADD_MORE: 
            return {
                ...state,
                truckList: [...state.truckList, ...action.truckList],
                truckListPaginatePage: action.page,
                truckListPaginateLimit: action.limit,
                truckListPaginateSortBy: action.sortBy,
            };
        case TRUCKS_ACTION_TYPES.GET_PAGINATE_TRUCK_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };      
        case TRUCKS_ACTION_TYPES.GET_PAGINATE_TRUCK_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //###--------------------------------------------------
    
        case TRUCKS_ACTION_TYPES.COUNT_PAGINATE_TRUCK_LIST_REQUEST: 
            return {
                ...state,
            };
        case TRUCKS_ACTION_TYPES.COUNT_PAGINATE_TRUCK_LIST_SUCCESS: 
            return {
                ...state,
                truckListTotalNum: action.truckListTotalNum,
            };
        case TRUCKS_ACTION_TYPES.COUNT_PAGINATE_TRUCK_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                truckListTotalNum: null,
            };      
        case TRUCKS_ACTION_TYPES.COUNT_PAGINATE_TRUCK_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                truckListTotalNum: 0,
            };   
        //###--------------------------------------------------
        //###--------------------------------------------------
    
        case TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_INFO_SUCCESS: 
            return {
                ...state,

            };
        case TRUCKS_ACTION_TYPES.UPDATE_ONE_TRUCK_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };      
        //###--------------------------------------------------
    
        case TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_REQUEST: 
            return {
                ...state,
                error: [],
                requestTruckCargoInsuranceDocTruckId: action.truckId,
            };
        case TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_SUCCESS: 
            return {
                ...state,
                truckCargoInsuranceDocList: action.docList,
            };
        case TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                truckCargoInsuranceDocList: [],
            };  
        case TRUCKS_ACTION_TYPES.GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                truckCargoInsuranceDocList: [],
            }; 
        //###--------------------------------------------------
    
        case TRUCKS_ACTION_TYPES.DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_REQUEST: 
            return {
                ...state,
                error: [],
                requestDownloadTruckCargoInsurancePdfTruckId: action.truckId,
                requestDownloadTruckCargoInsurancePdfDocId: action.docId,
            };
        case TRUCKS_ACTION_TYPES.DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_SUCCESS: 
            return {
                ...state,

            };
        case TRUCKS_ACTION_TYPES.DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };
        //###--------------------------------------------------

        
        default: 
            return state;
    }

}

export { trucks };