import { COMPANY_TYPE } from '../constants';


function companyTypeCellRenderer(params){

	var fontColor = '#000000';
	var bgColor = '#ffffff';
	var valueShow = '?';

	//## [DEBUG]
	// console.log('companyTypeCellRenderer');
	// console.log(params);

	if(params.value === COMPANY_TYPE.individual.db){
		fontColor = COMPANY_TYPE.individual.fontColor;
		bgColor = COMPANY_TYPE.individual.bgColor;
		valueShow = COMPANY_TYPE.individual.name;
	}
	else if(params.value === COMPANY_TYPE.company.db){
		fontColor = COMPANY_TYPE.company.fontColor;
		bgColor = COMPANY_TYPE.company.bgColor;
		valueShow = COMPANY_TYPE.company.name;
	}



	var ret = "<div style='width: 100%; height: 100%; text-align: center; "
	ret += "background-color:" + bgColor + "; "
	ret += "color: " + fontColor + ";' >";
	ret += valueShow + "</div>"


	return ret;

}

export { 

	companyTypeCellRenderer, 
};


