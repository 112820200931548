// import { THEME } from '../../../styles';

const alertMuiStyles = theme => ({
    dialogRoot:{
        
    }




});

export { 

    alertMuiStyles,
};