import { DRIVERS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	USER_STATUS,

} from '../../../constants';
import { DRIVERS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { driverServices } from '../../../api/services';



function requestEditOneDriverInfo(){
	return {
		type: DRIVERS_ACTION_TYPES.UPDATE_ONE_DRIVER_INFO_REQUEST
	}
}

function successEditOneDriverInfo(){
	return {
		type: DRIVERS_ACTION_TYPES.UPDATE_ONE_DRIVER_INFO_SUCCESS,
	}
}



function failureEditOneDriverInfo(error){
	return {
		type: DRIVERS_ACTION_TYPES.UPDATE_ONE_DRIVER_INFO_FAILURE,
		error,
	}
}


function editOneDriverInfo(oneRowData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneDriverInfo());


    			let payload ={
		    		driverId: oneRowData._id,
			        name: oneRowData.name ? oneRowData.name : null,
			        phoneNumber: oneRowData.phoneNumber ? oneRowData.phoneNumber : null,
			        
			        nid: oneRowData.nid ? oneRowData.nid : null,
			        driverLicenseExpireDate: oneRowData.driverLicenseExpireDate ? oneRowData.driverLicenseExpireDate : null,
			        driverLicenseNo: oneRowData.driverLicenseNo ? oneRowData.driverLicenseNo : null,
			        driverLicenseType: oneRowData.driverLicenseType ? oneRowData.driverLicenseType : null,
			        status: oneRowData.status ? oneRowData.status : USER_STATUS.waitingApprove.code,

		    	}

		    	//## [DEBUG]
	    		// console.log('-----Driver Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await driverServices
    			.putUpdateOneDriverInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneDriverInfo(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneDriverInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneDriverInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneDriverInfo(
								DRIVERS_CONSTANTS.ERROR_FAILURE_EDIT_ONE_DRIVER_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneDriverInfo(
							DRIVERS_CONSTANTS.ERROR_FAILURE_EDIT_ONE_DRIVER_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneDriverInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneDriverInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


export const editDriverActions = {


	requestEditOneDriverInfo,
	successEditOneDriverInfo,
	failureEditOneDriverInfo,
	editOneDriverInfo,


}