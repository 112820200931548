import { NOTIF_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// NOTIF_STATUS,
	// NOTIF_TYPE,
} from '../../../constants';
import { NOTIF_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { notifServices } from '../../../api/services';



function requestEditOneNotifInfo(){
	return {
		type: NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_INFO_REQUEST
	}
}

function successEditOneNotifInfo(){
	return {
		type: NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_INFO_SUCCESS,
	}
}



function failureEditOneNotifInfo(error){
	return {
		type: NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_INFO_FAILURE,
		error,
	}
}


function editOneNotifInfo(oneRowData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneNotifInfo());


    			let payload ={
		    		notificationId: oneRowData._id,
			        handleBy: oneRowData.handleBy,
					remark: oneRowData.remark,
		    	}

		    	// if(typeof oneRowData.read !== 'undefined'){
		    	// 	delete payload['read'];
		    	// }

		    	//## [DEBUG]
	    		// console.log('-----Notif Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await notifServices
    			.putUpdateOneNotifInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneNotifInfo(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneNotifInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneNotifInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneNotifInfo(
								NOTIF_CONSTANTS.ERROR_FAILURE_EDIT_ONE_NOTIF_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneNotifInfo(
							NOTIF_CONSTANTS.ERROR_FAILURE_EDIT_ONE_NOTIF_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneNotifInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneNotifInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


//###----------------------------------------------------------------------


function requestEditOneNotifStatus(){
	return {
		type: NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_STATUS_REQUEST
	}
}

function successEditOneNotifStatus(){
	return {
		type: NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_STATUS_SUCCESS,
	}
}



function failureEditOneNotifStatus(error){
	return {
		type: NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_STATUS_FAILURE,
		error,
	}
}


function editOneNotifStatus(oneRowData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneNotifStatus());


    			let payload ={
		    		notificationId: oneRowData._id,
			        read: oneRowData.read,
					status: oneRowData.status,
		    	}

		    	if(typeof oneRowData.read === 'undefined'){
		    		delete payload['read'];
		    	}

		    	if(typeof oneRowData.status === 'undefined'){
		    		delete payload['status'];
		    	}

		    	//## [DEBUG]
	    		// console.log('-----Notif Status Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await notifServices
    			.putUpdateOneNotifStatus(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneNotifStatus(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneNotifStatus(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneNotifStatus(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneNotifStatus(
								NOTIF_CONSTANTS.ERROR_FAILURE_EDIT_ONE_NOTIF_STATUS
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneNotifStatus(
							NOTIF_CONSTANTS.ERROR_FAILURE_EDIT_ONE_NOTIF_STATUS
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneNotifStatus(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneNotifStatus(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


//###----------------------------------------------------------------------


export const editNotifActions = {


	requestEditOneNotifInfo,
	successEditOneNotifInfo,
	failureEditOneNotifInfo,
	editOneNotifInfo,


	requestEditOneNotifStatus,
	successEditOneNotifStatus,
	failureEditOneNotifStatus,
	editOneNotifStatus,

}