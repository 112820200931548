import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import classNames from 'classnames';

// import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';

import Lightbox from 'react-image-lightbox';
import "../../../assets/css/lightbox.css";


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { 
//     faEye,

// } from '@fortawesome/free-solid-svg-icons';

import { 
    APP_CONSTANTS,
} from '../../../constants';

import { 
    IMAGES,
} from '../../../assets/images';

import { 
    withMultipleStyles,
} from '../../../utilities';

import { 
    layoutMuiStyles,
    THEME, 
} from '../../../styles';

import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';
// import { lightboxActions } from '../../lightbox/actions';

import {
    truckCargoInsuranceDocActions,
} from '../actions';

class TruckCargoInsuranceDocumentDialog extends React.Component {

    state = {
        docList: [],
        

        isOpenLightBox: false,
        lightboxImages: [],
        lightboxPhotoIndex: 0,
    }


    constructor(props) {
        super(props);

        this.onLoadDocumentList = this.onLoadDocumentList.bind(this);
        this.onOpenLightBox = this.onOpenLightBox.bind(this);
        this.onCloseLightBox = this.onCloseLightBox.bind(this);

        this.onDownloadOnePdf = this.onDownloadOnePdf.bind(this);
        this.onOpenNewTab = this.onOpenNewTab.bind(this);
        this._LightBox = this._LightBox.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let retState = null;

        let {
            truckCargoInsuranceDocList,
        } = nextProps;

        let newDocList = truckCargoInsuranceDocList;
        if(JSON.stringify(newDocList) !== JSON.stringify(prevState.docList)){
            let newLightBoxImages = [];
            for(var i = 0; i < newDocList.length; i++){
                if(newDocList[i].contentType){
                    let oneRow = null;
                    if(newDocList[i].contentType === APP_CONSTANTS.IMAGE_CONTENT_TYPE){
                        oneRow = "data:image/jpeg;base64," + newDocList[i].imgBase64;
                    }
                    else if (newDocList[i].contentType === APP_CONSTANTS.DOCUMENT_CONTENT_TYPE){
                        oneRow = IMAGES.PDF;
                    }

                    if(oneRow){
                        newLightBoxImages.push(oneRow);
                    }
                }
            }

            retState = {
                ...retState,
                lightboxImages: newLightBoxImages,
            }
        }


        return retState;
    }


    componentDidMount(){

    }


    componentDidUpdate(prevProps,prevState){
        if(prevProps.isVisible !== this.props.isVisible){
            if(this.props.isVisible === true){
                this.onLoadDocumentList();
            }
        }
    }

    onLoadDocumentList =  async() => {
        const { 
            dispatch,
            
        } = this.props;

        let {
            truckId 
        } = this.props;

        if(truckId){

            await dispatch(spinnerActions.showSpinner());

            //## [DEBUG]
            // console.log('------ invokeParentMethod ------');
            // console.log('------ Data Row ------');
            // console.log(this.props.node)
            // console.log('------ CompanyID ------');
            // console.log(this.props.node.data._id)

            let docList = await dispatch(
                truckCargoInsuranceDocActions.getOneTruckCargoInsuranceDocList(
                    truckId
                )
            );

            if(!docList){
                let errorMsg = this.props.truckError.join('\n');
                await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )

                docList = [];
            }

            await this.setState({
                docList: docList,
            })


            await dispatch(spinnerActions.hideSpinner());
        }

        return Promise.resolve();
    }


    onOpenLightBox = (index) => {
        // const { dispatch } = this.props;

        // dispatch(
        //     lightboxActions.openLightbox(
        //         this.state.lightboxImages
        //     )
        // );

        // console.log('onOpenLightBox')


        this.setState({
            isOpenLightBox: true,
            lightboxPhotoIndex: index,
        })
    }

    onCloseLightBox = () => {
        // const { dispatch } = this.props;

        // dispatch(lightboxActions.closeLightbox());

        this.setState({
            isOpenLightBox: false,
        })

    }

    onCloseDialog = () => {
        // const { dispatch } = this.props;

        // dispatch(lightboxActions.closeLightbox());

        this.setState({
            isOpenLightBox: false,
        })


        this.props.handleClose();
    }

    onDownloadOnePdf = async(docId) => {
        const { dispatch } = this.props;
        let {
            truckId
        } = this.props;

        if(docId){
            let isDownloadSuccess = await dispatch(
                truckCargoInsuranceDocActions.downloadOneTruckCargoInsurancePdf(
                    truckId,
                    docId,
                )
            );

            if(!isDownloadSuccess){
                let errorMsg = this.props.truckError.join('\n');
                await dispatch(alertActions.showAlert(
                        APP_CONSTANTS.ERROR_DIALOG_TITLE,
                        errorMsg,
                        ALERT_CONSTANTS.ERROR_MODE
                    )
                )
            }
        }

        return Promise.resolve();
    }

    onOpenNewTab = (imgBase64) => {

        let imgSrc = "data:image/jpeg;base64,"+ imgBase64;

        // window.open(imgSrc, "_blank")
        var image = new Image();
        image.src = imgSrc;

        var w = window.open('about:blank');

        setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
            // w.document.body.appendChild(w.document.createElement('a'))
            //     .href = imgSrc;

            w.document.write(image.outerHTML);    
        }, 0);
    }

    _LightBox() {

        let { 

            // isOpenLightBox,
            lightboxImages,
            lightboxPhotoIndex, 
        } = this.state;

        let images = lightboxImages;
        // let isOpen = isOpenLightBox;
        let photoIndex = lightboxPhotoIndex;

        return (
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                imageTitle={photoIndex + 1 + "/" + images.length}
                onCloseRequest={() => this.onCloseLightBox()}
                onMovePrevRequest={() =>
                    this.setState({
                        lightboxPhotoIndex: (photoIndex + images.length - 1) % images.length,
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                        lightboxPhotoIndex: (photoIndex + 1) % images.length,
                    })
                }
                reactModalStyle={{
                    overlay: {
                        zIndex: THEME.lightBoxZIndex
                    }
                }}
            />
        )
    }


    render() {
        const { classes } = this.props;
        let { 
            docList, 

        } = this.state;


        return (
            <div>
                <Dialog
                    open={this.props.isVisible}
                    onClose={this.onCloseDialog}
                    fullWidth={true}
                    maxWidth={'xl'}
                    aria-labelledby="tci-dialog-title"
                    aria-describedby="tci-dialog-description"
                >
                    <DialogTitle id="tci-dialog-title">
                        {"เอกสารยืนยันตัวตนบริษัท"}
                    </DialogTitle>
                    <DialogContent>
                        <Grid 
                            container 
                            spacing={8}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            {
                                docList.map( (oneDoc,index) => {
                                    return(
                                        <Grid 
                                            item 
                                            key={oneDoc._id}
                                        >
                                            <Card  
                                                className={classes.cardThumbnails}
                                            >
                                                {
                                                    oneDoc.contentType &&
                                                    oneDoc.contentType === APP_CONSTANTS.IMAGE_CONTENT_TYPE &&
                                                    <CardMedia
                                                        className={classes.cardMedia}
                                                        image={"data:image/jpeg;base64,"+ oneDoc.imgBase64}
                                                        title={"tci-one-doc-" + oneDoc._id}
                                                    />
                                                }
                                                {
                                                    oneDoc.contentType &&
                                                    oneDoc.contentType === APP_CONSTANTS.DOCUMENT_CONTENT_TYPE &&
                                                    <CardMedia
                                                        className={classes.cardMedia}
                                                        image={IMAGES.PDF}
                                                        title={"tci-one-doc-" + oneDoc._id}
                                                    />
                                                }
                                                <CardContent>
                                                    <div>DocId:</div>
                                                    <div>{oneDoc._id}</div>
                                                </CardContent>
                                                
                                                <CardActions>
                                                    <Button 
                                                        onClick={() => this.onOpenLightBox(index)} 
                                                        color="primary"
                                                    >
                                                        Full Screen
                                                    </Button>
                                                    {
                                                        oneDoc._id &&
                                                        oneDoc.contentType &&
                                                        oneDoc.contentType === APP_CONSTANTS.DOCUMENT_CONTENT_TYPE &&
                                                        <Button 
                                                            onClick={() => this.onDownloadOnePdf(oneDoc._id)} 
                                                            color="primary"
                                                        >
                                                            Download Pdf
                                                        </Button>
                                                    }
                                                    {
                                                        oneDoc.contentType &&
                                                        oneDoc.contentType === APP_CONSTANTS.IMAGE_CONTENT_TYPE &&
                                                        <Button 
                                                            onClick={() => this.onOpenNewTab(oneDoc.imgBase64)} 
                                                            color="primary"
                                                        >
                                                            Open New Tab
                                                        </Button>
                                                    }
                                                    {/*
                                                    <Button 
                                                        size="small" 
                                                        variant="contained" 
                                                        color="secondary"
                                                        onClick={event => this.onDeleteOneJobDeliveryDocsImage(event,oneImage.imgId)}
                                                    >
                                                        <DeleteIcon className={classes.leftIcon}/>
                                                        {'Delete'}

                                                    </Button>
                                                    */} 
                                                </CardActions>
                                                  
                                            </Card>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={this.props.handleClose} 
                            color="primary" 
                            autoFocus
                        >
                            {'ปิด'}
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    this.state.isOpenLightBox &&
                    this._LightBox()
                }
            </div>
        );
    }
};



const mapStateToProps = (state) => ({
    truckError: state.trucks.error,
    truckCargoInsuranceDocList: state.trucks.truckCargoInsuranceDocList,
})


TruckCargoInsuranceDocumentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,

    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    truckId: PropTypes.string,

}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(TruckCargoInsuranceDocumentDialog);


const connectedContainers = connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as TruckCargoInsuranceDocumentDialog };