
const KBANK = {
        name: 'ธนาคารกสิกรไทย',
        code: 'KBANK',
};

const SCB = {
        name: 'ธนาคารไทยพาณิชย์',
        code: 'SCB',
};

const BBL = {
        name: 'ธนาคารกรุงเทพ (บัวหลวง)',
        code: 'BBL',
};

const KTB = {
        name: 'ธนาคารกรุงไทย',
        code: 'KTB',
};


const TMB = {
        name: 'ธนาคารทหารไทย',
        code: 'TMB',
};

const BAY = {
        name: 'ธนาคารกรุงศรีอยุธยา',
        code: 'BAY',
};

const TBANK = {
        name: 'ธนาคารธนชาติ',
        code: 'TBANK',
};

const TISCO = {
        name: 'ธนาคารทิสโก้',
        code: 'TISCO',
};

const UOB = {
        name: 'ธนาคารยูโอบี',
        code: 'UOB',
};

const CIMBT = {
        name: 'ธนาคารซีไอเอ็มบีไทย',
        code: 'CIMBT',
};

const GSB = {
        name: 'ธนาคารออมสิน',
        code: 'GSB',
};

const GHB = {
        name: 'ธนาคารอาคารสงเคราะห์',
        code: 'GHB',
};

const BAAC = {
        name: 'ธนาคารเพื่อการเกษตร',
        code: 'BAAC',
};

const LHBANK = {
        name: 'ธนาคารแลนด์ แอนด์ เฮาส์',
        code: 'LHBANK',
};

const SME = {
        name: 'ธนาคารพัฒนาวิสาหกิจ',
        code: 'SME',
};


const mapping = {
    'KBANK': 'ธนาคารกสิกรไทย',  
    'SCB': 'ธนาคารไทยพาณิชย์',
    'BBL': 'ธนาคารกรุงเทพ (บัวหลวง)',
    'KTB': 'ธนาคารกรุงไทย',
    'TMB': 'ธนาคารทหารไทย',
    'BAY': 'ธนาคารกรุงศรีอยุธยา',
    'TBANK': 'ธนาคารธนชาติ',
    'TISCO': 'ธนาคารทิสโก้',
    'UOB': 'ธนาคารยูโอบี',
    'CIMBT': 'ธนาคารซีไอเอ็มบีไทย',
    'GSB': 'ธนาคารออมสิน',
    'GHB': 'ธนาคารอาคารสงเคราะห์',
    'BAAC': 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    'LHBANK': 'ธนาคารแลนด์ แอนด์ เฮาส์',
    'SME': 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย', 
}



const BANK_NAME = {
        KBANK: KBANK,
        SCB: SCB,
        BBL: BBL,
        KTB: KTB,
        TMB: TMB,
        BAY: BAY,
        TBANK: TBANK,
        TISCO: TISCO,
        UOB: UOB,
        CIMBT: CIMBT,
        GSB: GSB,
        GHB: GHB,
        BAAC: BAAC,
        LHBANK: LHBANK,
        SME: SME,

        mapping: mapping,
};

export { BANK_NAME };