import { LIGHTBOX_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],

    isOpen: false,

    images: [],
    photoIndex: 0,

    
}


const lightbox = (state = INIT_STATE, action) => {
    switch(action.type) {
        case LIGHTBOX_ACTION_TYPES.OPEN_LIGHTBOX: 
            return {
                ...state,
                isOpen: true,
                images: action.imageList, 
                photoIndex: 0,
            };
        case LIGHTBOX_ACTION_TYPES.CLOSE_LIGHTBOX: 
            return {
                ...state,
                isOpen: false,
                // images: [],
                // photoIndex: 0,
            };
        case LIGHTBOX_ACTION_TYPES.SET_LIGHTBOX_IMAGE_LIST: 
            return {
                ...state,
                images: action.imageList,
            };
        case LIGHTBOX_ACTION_TYPES.RESET_LIGHTBOX_IMAGE_LIST: 
            return {
                ...state,
                images: [],
                photoIndex: 0,
            };
        case LIGHTBOX_ACTION_TYPES.SET_LIGHTBOX_PHOTO_INDEX: 
            return {
                ...state,

                photoIndex: action.photoIndex,
            };    
       
        //##------------------------------------------------------------------
        default: 
            return state;
    }
}


export { lightbox };