import { DRIVERS_ACTION_TYPES } from '../constants';

const INIT_STATE = {
    error: [],


    //## User Data List
    driverListPaginatePage: 0,
    driverListPaginateLimit: null,
    driverListPaginateSortBy: null,
    driverList: [],
    driverListTotalNum: null,

    //## Driver License Doc List
    requestDriverLicenseDocDriverId: null,
    driverLicenseDocList: [],

    requestDownloadDriverLicensePdfDriverId: null,
    requestDownloadDriverLicensePdfDocId: null,
   
}

const drivers = (state = INIT_STATE, action) => {
    switch(action.type) {
        
        //###--------------------------------------------------
    
        case DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_REQUEST: 
            return {
                ...state,
            };
        case DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_SUCCESS: 
            return {
                ...state,
                driverList: action.driverList,
                driverListPaginatePage: action.page,
                driverListPaginateLimit: action.limit,
                driverListPaginateSortBy: action.sortBy,
            };
        case DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_SUCCESS_ADD_MORE: 
            return {
                ...state,
                driverList: [...state.driverList, ...action.driverList],
                driverListPaginatePage: action.page,
                driverListPaginateLimit: action.limit,
                driverListPaginateSortBy: action.sortBy,
            };
        case DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };      
        case DRIVERS_ACTION_TYPES.GET_PAGINATE_DRIVER_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //###--------------------------------------------------
    
        case DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_REQUEST: 
            return {
                ...state,
            };
        case DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_SUCCESS: 
            return {
                ...state,
                driverListTotalNum: action.driverListTotalNum,
            };
        case DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                driverListTotalNum: null,
            };      
        case DRIVERS_ACTION_TYPES.COUNT_PAGINATE_DRIVER_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                driverListTotalNum: 0,
            };   
        //###--------------------------------------------------
        //###--------------------------------------------------
    
        case DRIVERS_ACTION_TYPES.UPDATE_ONE_DRIVER_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case DRIVERS_ACTION_TYPES.UPDATE_ONE_DRIVER_INFO_SUCCESS: 
            return {
                ...state,

            };
        case DRIVERS_ACTION_TYPES.UPDATE_ONE_DRIVER_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };      
        //###--------------------------------------------------
    
        case DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_REQUEST: 
            return {
                ...state,
                error: [],
                requestDriverLicenseDocDriverId: action.driverId,
            };
        case DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_SUCCESS: 
            return {
                ...state,
                driverLicenseDocList: action.docList,
            };
        case DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                driverLicenseDocList: [],
            };  
        case DRIVERS_ACTION_TYPES.GET_ONE_DRIVER_LICENSE_DOC_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                driverLicenseDocList: [],
            }; 
        //###--------------------------------------------------
    
        case DRIVERS_ACTION_TYPES.DOWNLOAD_ONE_DRIVER_LICENSE_DOC_PDF_REQUEST: 
            return {
                ...state,
                error: [],
                requestDownloadDriverLicensePdfDriverId: action.driverId,
                requestDownloadDriverLicensePdfDocId: action.docId,
            };
        case DRIVERS_ACTION_TYPES.DOWNLOAD_ONE_DRIVER_LICENSE_DOC_PDF_SUCCESS: 
            return {
                ...state,

            };
        case DRIVERS_ACTION_TYPES.DOWNLOAD_ONE_DRIVER_LICENSE_DOC_PDF_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };
        //###--------------------------------------------------

        
        default: 
            return state;
    }

}

export { drivers };