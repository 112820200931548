import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';



import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSave,
    // faPlus,
    faChevronRight,
    faChevronLeft,

} from '@fortawesome/free-solid-svg-icons';

import { 
	withMultipleStyles,
	// extractValues,
	// lookupValue,
	// lookupKey,
	// userStatusCellRenderer,
	// userTypeCellRenderer,
	getDateTimeColumnType,
	jobStatusCellRenderer,
	jobPrivateCellRenderer,
} from '../../../utilities';
import { 
	layoutMuiStyles,

} from '../../../styles';
import { 
	APP_CONSTANTS,
	// USER_STATUS,
	// USER_TYPE, 
	// BANK_NAME,
} from '../../../constants';



import {
	jobListActions,

} from '../actions';


import { alertActions } from '../../alert/actions';
import { ALERT_CONSTANTS } from '../../alert/constants';
import { spinnerActions } from '../../spinner/actions';



class JobListTableContainers extends React.Component {

	state = {

		isPrefetched: false,

		//##---------------- Table Pagination ---------------------------

		page: 0,
		limit: APP_CONSTANTS.DEFAULT_PAGINATION_PAGE_SIZE,
		sortBy: APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY,
		filterBy: {},

		//## Total Page possible to show
		totalPage: 0,
		rowPerPageArray: APP_CONSTANTS.DEFAULT_PAGINATION_ROW_PER_PAGE_ARRAY,

		disabledPrevPageBtn: true,
		disabledNextPageBtn: false,
		

		//##---------------- agGridReact ---------------------------

		context: { componentParent: this },
		frameworkComponents: {

	    },

		defaultColDef: {
			// set every column width
	        width: 100,
	        // make every column editable
	        editable: true,
	        // make every column use 'text' filter by default
	        filter: 'agTextColumnFilter',
	        //## Column resizable
	        resizable: true,	
	        sortable: true,
		},
		columnTypes: {
			nonEditableColumn: {editable: false},
	        dateColumn: getDateTimeColumnType()
		},

		columnDefs: [

			{
				field: "refId",
				headerName: "ID",
				width: 150, 
				editable: false,
			},
			{
				field: "groupIdx",
				headerName: "คันที่",
				width: 150, 
				editable: false,
			},
			{
				field: "status",
				headerName: "status",
				width: 250, 
				editable: false,
				cellRenderer: (params) => jobStatusCellRenderer(params),
			},
			{
				field: "private",
				headerName: "งานเฉพาะกลุ่ม",
				width: 150, 
				editable: false,
				cellRenderer: (params) => jobPrivateCellRenderer(params),
			},
			{
				headerName: "Shipper",
				children: [
					{
						field: "shipper.name",
						headerName: "ชื่อบริษัท", 
						width: 200,
						editable: false,
						
					},
					{
						field: "operator.name",
						headerName: "เจ้าของงาน", 
						width: 200,
						editable: false,
						
					},
					{
						field: "operator.phoneNumber",
						headerName: "เบอร์เจ้าของงาน", 
						width: 200,
						editable: false,
						
					},

				]
			},
			{
				headerName: "สินค้า",
				children: [
					{
						field: "goodsDescription",
						headerName: "รายละเอียด", 
						width: 200,
						editable: false,
						
					},
					{
						field: "goodsWeight",
						headerName: "นํ้าหนักสินค้า(ต่อตัน)", 
						width: 200,
						editable: false,
						
					},
					{
						field: "price",
						headerName: "ราคา(ต่อเที่ยว)", 
						width: 200,
						editable: false,
						
					},
					{
						field: "priceByWeight",
						headerName: "ราคา(ต่อตัน)", 
						width: 200,
						editable: false,
						
					},
					{
						field: "truckType.typeName",
						headerName: "ชนิดรถที่ต้องการ", 
						width: 500,
						editable: false,
						valueGetter: function (params) {

							//## [DEBUG]
							// console.log(params)

							let truckTypeString = '';

							let data = params.data;
							if(data.truckType){
								for(var i = 0; i < data.truckType.length; i++){
									if(i > 0){
										truckTypeString += ", ";
									}
									if(data.truckType[i].typeName){
										truckTypeString += data.truckType[i].typeName
									}

								}
							}

							

							// let  phoneNumber = params.value[0].phoneNumber;

					        // convert code to value
					        return truckTypeString;
					    },
						
					},
					{
						field: "insuranceCoverage",
						headerName: "ประกันสินค้าที่ต้องการ", 
						width: 200,
						editable: false,
						
					},
					{
						field: "porter",
						headerName: "ต้องการคนยกสินค้า", 
						width: 150,
						editable: false,
						
					},
					{
						field: "paymentCondition",
						headerName: "เงื่อนไขการจ่ายเงิน", 
						width: 200,
						editable: false,
						
					},
				]
			},
			{
				headerName: "Carrier",
				children: [
					{
						field: "assignCarrier.name",
						headerName: "ชื่อบริษัทรับงาน", 
						width: 200,
						editable: false,
						
					},
					{
						field: "assignDispatcher.name",
						headerName: "ชื่อคนรับงาน", 
						width: 200,
						editable: false,
						
					},
					{
						field: "assignDispatcher.phoneNumber",
						headerName: "เบอร์คนรับงาน", 
						width: 200,
						editable: false,
						
					},
					

				]
			},
			{
				headerName: "คนขับ",
				children: [
					{
						field: "assignDriver.name",
						headerName: "ชื่อคนขับ", 
						width: 200,
						editable: false,
						
					},
					{
						field: "assignDriver.phoneNumber",
						headerName: "เบอร์คนขับ", 
						width: 200,
						editable: false,
					},
				]
			},
			{
				headerName: "รถบรรทุกที่รับงาน",
				children: [
					{
						field: "assignTruck.plateId",
						headerName: "ทะเบียนแม่", 
						width: 150,
						editable: false,
						
					},
					{
						field: "assignTruck.plateIdProvince",
						headerName: "จังหวัดทะเบียนแม่", 
						width: 150,
						editable: false,
						
					},
					{
						field: "assignTruck.weight",
						headerName: "นํ้าหนักบรรทุกแม่(ตัน)", 
						width: 150,
						editable: false,
						
					},
					{
						field: "assignTruck.childPlateId",
						headerName: "ทะเบียนลูก", 
						width: 150,
						editable: false,
						
					},
					{
						field: "assignTruck.childPlateIdProvince",
						headerName: "จังหวัดทะเบียนลูก", 
						width: 150,
						editable: false,
						
					},
					{
						field: "assignTruck.childWeight",
						headerName: "นํ้าหนักบรรทุกลูก(ตัน)", 
						width: 150,
						editable: false,
						
					},
					{
						field: "assignTruck.truckType",
						headerName: "ชนิดรถ", 
						width: 150,
						editable: false,
						valueGetter: function (params) {

							//## [DEBUG]
							// console.log(params)

							let truckTypeString = '';

							let data = params.data;
							if(data.assignTruck){
								if(data.assignTruck.truckType){
									truckTypeString = data.assignTruck.truckType.typeName
								}
							}

							

							// let  phoneNumber = params.value[0].phoneNumber;

					        // convert code to value
					        return truckTypeString;
					    },
					},
					{
						field: "assignTruck.cargoInsuranceCoverage",
						headerName: "ประกันสินค้าบนรถบรรทุก", 
						width: 150,
						editable: false,
						
					},
					
				]
			},

			{
				field: "createdAt",
				headerName: "วันที่สร้าง",
				editable: false,
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
			},
			{
				field: "updatedAt",
				headerName: "วันที่อัพเดต", 
				editable: false,
				width: 200,
				type: [
					'dateColumn', 
					'nonEditableColumn'
				],
				
			},
		],

		//## Raw data 
		rowData: [
			// {_id: 555,phoneNumber: "Toyota",status: 0,authorizations: ['company-owner'], name: "Celica", nid: 35000, createdAt: '2019-05-12T01:29:15.647Z', updatedAt: '2019-05-23T01:29:15.647Z'},
			// {_id: 666,phoneNumber: "Ford",status: 1,authorizations: ['company-owner','truck-driver-employee'], name: "Mondeo", nid: 32000},
			// {_id: 777,phoneNumber: "Porsche",status: 2,authorizations: ['company-owner','truck-driver-employee'], name: "Boxter", nid: 72000}
		],

		//## Total data row in server database
		rowTotal: 0,

		editedData: [],

		//##------------------- create new user dialog---------
		viewJobId: null,

		isShowCreateNewJobDialog: false,

		//##------------------- Edit Job company dialog---------
		isShowEditJobCompanyDialog: false,

	}


    constructor(props) {
        super(props);

        this.onPrefetch = this.onPrefetch.bind(this);

        // this.agGridApi = this.agGridApi.bind(this);
        // this.agGridColumnApi = this.agGridColumnApi.bind(this);
        this.onEditOneCell = this.onEditOneCell.bind(this);
        this.onSaveEdit = this.onSaveEdit.bind(this);
        this.onResetEditedData = this.onResetEditedData.bind(this);

        this.onClickPrevPage = this.onClickPrevPage.bind(this);
		this.onClickNextPage = this.onClickNextPage.bind(this);
		this.onDisablePrevNextPageButton = this.onDisablePrevNextPageButton.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);

    }

    //##----------------------------------------------
    static getDerivedStateFromProps(nextProps, prevState){
    		
    	let retState = null

    	let {
    		jobList,
    		jobListTotalNum,
    	} = nextProps;

    	let newList = jobList;
    	let newListTotalNum = jobListTotalNum;


    	if(newList){
    		if(newList.length > 0){
    			let newString = JSON.stringify(newList);
    			let oldString = JSON.stringify(prevState.rowData);

    			if(newString !== oldString){
    				retState = {
    					...retState,
    					rowData: newList,
    				}
    			}
    		}
	    }

	    if(newListTotalNum !== prevState.rowTotal){
	    	let totalPage = 0;
	    	let limit = prevState.limit;


	    	let disabledPrevPageBtn = true;
	    	let disabledNextPageBtn = false;

	    	let totalRowInOnePage = prevState.limit;

	    	if(limit > 0){
	    		totalPage = Math.ceil(newListTotalNum / limit)
	    	}
	    	else{
	    		totalPage = 0;
	    	}

	    	if(newListTotalNum <= totalRowInOnePage){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= true;
	    	}

	    	retState = {
	    		...retState,
	    		rowTotal: newListTotalNum,
	    		totalPage: totalPage,
	    		disabledPrevPageBtn: disabledPrevPageBtn,
	    		disabledNextPageBtn: disabledNextPageBtn,
	    	}
	    }





    	return retState;
    }


    componentDidMount(){
    	this.onPrefetch();
    }

    componentDidUpdate(prevProps,prevState){
    	// if(JSON.stringify(prevState.rowData) !== JSON.stringify(this.state.rowData)){
    	// 	console.log('Component Did Update Row Data: ')
    	// 	console.log(this.state.rowData);
    	// }

    	//## Change Limit -> disable next/prev button of table
    	// if(prevState.limit !== this.state.limit){
    	// 	this.onDidUpdateLimit();
    	// }
    	
    }

    //##----------------------------------------------
    async onPrefetch(){
    	const { dispatch } = this.props;

    	let {
    		page,
    		limit,
    		sortBy,
    		filterBy,
    	} = this.state;


    	await dispatch(spinnerActions.showSpinner());

    	let jobList = await dispatch(
    		jobListActions.getPaginateJobList(
    			page,
    			limit,
    			sortBy,
    		)
    	);



    	let jobTotalNum = await dispatch(
    		jobListActions.countPaginateJobList(
    			filterBy
    		)
    	);

    	if(this.state.isPrefetched === false){
    		await this.setState({
    			isPrefetched: true,
    		})
    	}

    	await dispatch(spinnerActions.hideSpinner());


    	if(!jobList || !jobTotalNum){
    		let errorMsg = this.props.jobError.join('\n');

    		await dispatch(alertActions.showAlert(
                    APP_CONSTANTS.ERROR_DIALOG_TITLE,
                    errorMsg,
                    ALERT_CONSTANTS.ERROR_MODE
                )
            )

    	}





    	return Promise.resolve();
    }


    //##----------------------------------------------
    onGridReady = (params) => {
    	this.agGridApi = params.api;
    	this.agGridColumnApi = params.columnApi;
    }

    onEditOneCell = (params) => {
    	// notice that the data always contains the keys rather than values after editing
	       
    	//## [DEBUG]
    	// console.log("onEditOneCell: ", params);

    	let oneRowData = {...params.data};
    	let rowId = oneRowData._id;
    	let editColumnName = params.column ? params.column.colId : null;

    	if(rowId && editColumnName){

    		let {
    			editedData
    		} = this.state;

    		//## [DEBUG]
    		// console.log("OneRowEditData: ", oneRowData);
    		// console.log("rowId: ", rowId);
    		// console.log("editColumnName: ", editColumnName);

    		//## loop update edit Data
    		let isAlreadyEdited = false;
    		for(var i =0; i < editedData.length; i++){
    			if(editedData[i]._id === rowId){
    				editedData[i] = oneRowData;
    				isAlreadyEdited = true;
    				break;
    			}
    		}

    		if(!isAlreadyEdited){
    			editedData.push(oneRowData);
    		}

    		//## [DEBUG]
    		// console.log('editedData: ',editedData);

    		this.setState({
    			editedData: editedData,
    		})

    	}
    }

    onSaveEdit = async() => {
    	// const { dispatch } = this.props;
    	// let {
    	// 	editedData
    	// } = this.state;

    	// if(editedData.length > 0){

	    // 	await dispatch(spinnerActions.showSpinner());

	    // 	const promises = editedData.map( async(oneRow) =>  {


	    // 		let isSuccess = await dispatch(
	    // 			editJobActions.editOneJobContactName(
	    // 				oneRow.phoneNumber,
	    // 				oneRow.name,
	    // 			)
	    // 		)

	    // 		if(isSuccess){
	    // 			return null;
	    // 		}
	    // 		else{
	    // 			let errorMsg = this.props.jobError.join('\n');

	    // 			return errorMsg;
	    // 		}
	    // 	})

	    // 	let updatedResultArray = await Promise.all(promises);
	    // 	let updatedResultArrayFiltered = await updatedResultArray.filter(v => v !== null);

	    // 	if(updatedResultArrayFiltered){
	    // 		if(updatedResultArrayFiltered.length > 0){
	    // 			let allErrorMsg = "";
	    // 			for(var i = 0; i < updatedResultArrayFiltered.length; i++){
	    // 				allErrorMsg += updatedResultArrayFiltered + '\n';
	    // 			}


	    // 			await dispatch(alertActions.showAlert(
	    //                     APP_CONSTANTS.ERROR_DIALOG_TITLE,
	    //                     allErrorMsg,
	    //                     ALERT_CONSTANTS.ERROR_MODE
	    //                 )
	    //             )
	    // 		}
	    // 		else{
	    // 			await this.onResetEditedData();

	    // 			await dispatch(alertActions.showAlert(
	    //                     APP_CONSTANTS.SUCCESS_DIALOG_TITLE,
	    //                     APP_CONSTANTS.SUCCESS_EDIT_MESSAGE,
	    //                     ALERT_CONSTANTS.SUCCESS_MODE
	    //                 )
	    //             )
	    // 		}
	    // 	}
	    
	    // 	await this.onPrefetch();

	    // 	await dispatch(spinnerActions.hideSpinner());
	    // }
	    // else{
	    // 	await dispatch(alertActions.showAlert(
	    //             APP_CONSTANTS.ERROR_DIALOG_TITLE,
	    //             'ไม่มีข้อมูลให้แก้ไข',
	    //             ALERT_CONSTANTS.ERROR_MODE
	    //         )
	    //     )
	    // }


    	return Promise.resolve();
    }

    onResetEditedData = async() => {

    	await this.setState({
    		editedData: [],
    	})

    	return Promise.resolve();
    }

    //##----------------------------------------------




    onClickPrevPage = async() => {
    	let {
    		page,


    	} = this.state;

    	let nextPage = page - 1;

    	if(nextPage <= 0){
    		nextPage = 0;
    	}


    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();

    	return Promise.resolve();
    }

    onClickNextPage = async() => {
    	let {
    		page,
    		totalPage,
    	} = this.state;

    	let nextPage = page + 1;

    	if(nextPage >= (totalPage-1)){
    		nextPage = totalPage-1;
    	}

    	await this.setState({
    		page: nextPage,
    	})

    	await this.onResetEditedData();

    	await this.onPrefetch();

    	await this.onDisablePrevNextPageButton();
    	

    	return Promise.resolve();
    }

    onDisablePrevNextPageButton = () => {
    	let {
    		page,
    		limit,
    		totalPage,
    		disabledPrevPageBtn,
    		disabledNextPageBtn,

    		rowTotal
    	} = this.state;

    	//## [DEBUG]
    	// console.log('--onDisablePrevNextPageButton:---')
    	// console.log('page: ', page);
    	// console.log('totalPage: ', totalPage);


    	if(rowTotal <= limit){
    		disabledPrevPageBtn = true;
			disabledNextPageBtn	= true;
    	}
    	else{
    		if(page <= 0){
	    		disabledPrevPageBtn = true;
				disabledNextPageBtn	= false;
	    	}
	    	else if(page >= (totalPage-1)){
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= true;
	    	}
	    	else{
	    		disabledPrevPageBtn = false;
				disabledNextPageBtn	= false;
	    	}
    	}

    	//## [DEBUG]
    	// console.log('disabledPrevPageBtn: ', disabledPrevPageBtn)
    	// console.log('disabledNextPageBtn: ', disabledNextPageBtn)
		

    	this.setState({
    		disabledPrevPageBtn: disabledPrevPageBtn,
    		disabledNextPageBtn: disabledNextPageBtn,
    	})
    }

    onChangeLimit = async(event) => {

    	let {

    		page,
    		totalPage,
    		
    	} = this.state;

    	let newLimit = parseInt(event.target.value,10);

    	//## {DEBUG}

    	// console.log('newLimit: ' + newLimit);
    	// console.log('currentLimit: ' + this.state.limit);

    	if(newLimit !== this.state.limit){
    		//## Reset page when change new limit
    		page = 0;
	    	 
	    }

    	await this.setState({
    		page: page,
    		limit: newLimit,
    	})


    	await this.onPrefetch();


    	let {
    		rowTotal,
    	} = this.state;

    	//## Calculate new total page after update limit and rowTotal
    	if(newLimit > 0){
    		totalPage = Math.ceil(rowTotal / newLimit)
    	}
    	else{
    		totalPage = 0;
    	}

    	await this.setState({
    		totalPage: totalPage,
    	})


    	await this.onDisablePrevNextPageButton();



    	return Promise.resolve();
    }

    //##----------------------------------------------

    render() {
        const { classes } = this.props;

        return (
        	<Grid container spacing={0}>
        		<Grid 
        			item 
        			xs={12} 
        			className={classes.agGridContainer}
        		>
        			<Grid container spacing={0}>
        				<Grid 
		        			item 
		        			xs={12} 
		        			className={classes.agGridTableToolbarContainer}
		        		>
		        			<Grid 
		        				container 
		        				spacing={16}
		        				direction="row"
							    justify="flex-end"
							    alignItems="center"
		        			>
		        				
							    <Grid item >
							    	<Typography 
			                        	variant="h6" 
			                        >
							        	{'จำนวนแถว: '}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	
			                    	<Select
							            value={this.state.limit}
							            onChange={this.onChangeLimit}
							            inputProps={{
							              	name: 'rowPerpage',
							              	id: 'tab-page-size',
							            }}
							        >
							            {this.state.rowPerPageArray.map(name => (
							              	<MenuItem 
							              		key={name} 
							              		value={name} 
							              	>
							                	{name}
							              	</MenuItem>
							            ))}
							        </Select>
							    </Grid>
							    <Grid item >
			                    	<Typography 
			                        	variant="body1" 
			                        >
			                        	
							        	{(this.state.limit * this.state.page) + 1}
							        	{'-'}
							        	{(this.state.limit * (this.state.page + 1))}
							        	{'   from total:  '}
							        	{this.state.rowTotal}
							      	</Typography>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickPrevPage()} 
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledPrevPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronLeft}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                        </Button>
							    </Grid>
							    <Grid item >
							    	<Button 
				        				onClick={() => this.onClickNextPage()}
				        				color={'default'} 
				        				className={classes.paginationButton}
				        				variant="contained"
				        				disabled={this.state.disabledNextPageBtn}
				        				fullWidth={true}
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faChevronRight}
		                                    className={
		                                    	classNames(
		                                    		
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                        </Button>
							    </Grid>
							    <Grid item >
				        			<Button 
				        				onClick={() => this.onSaveEdit()} 
				        				variant={'contained'}
				        				color="primary"
				        			>
				        				<FontAwesomeIcon 
		                                    icon={faSave}
		                                    className={
		                                    	classNames(
		                                    		classes.leftIcon, 
		                                    		classes.iconSmall
		                                    	)
		                                    }
		                                />
			                            {'Save'}
			                        </Button>
			                    </Grid>
		                    </Grid>
		        		</Grid>
        			</Grid>
		        	<div
						className={classNames(
							"ag-theme-balham",
		                    classes.agGridTable, 
		                )}
					>
						<AgGridReact
							reactNext={true}
							onGridReady={this.onGridReady}
							context={this.state.context}
							frameworkComponents={this.state.frameworkComponents}
							defaultColDef={this.state.defaultColDef}
							columnTypes={this.state.columnTypes}
							floatingFilter={true}
							columnDefs={this.state.columnDefs}
							rowData={this.state.rowData}
							onCellValueChanged={this.onEditOneCell}
						>
						</AgGridReact>
					</div>
				</Grid>
			</Grid>
        )
    }

}






const mapStateToProps = (state) => ({
	jobError: state.jobs.error,

	jobList: state.jobs.jobList,
	jobListTotalNum: state.jobs.jobListTotalNum,

})


JobListTableContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(JobListTableContainers);

const connectedContainers = connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(injectedStylesContainers);
export { connectedContainers  as JobListTableContainers };