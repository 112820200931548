import { COMPANY_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
	// COMPANY_STATUS,
	COMPANY_TYPE, 
} from '../../../constants';
import { COMPANY_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { companyServices } from '../../../api/services';



function requestEditOneCompanyInfo(){
	return {
		type: COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_INFO_REQUEST
	}
}

function successEditOneCompanyInfo(){
	return {
		type: COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_INFO_SUCCESS,
	}
}



function failureEditOneCompanyInfo(error){
	return {
		type: COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_INFO_FAILURE,
		error,
	}
}


function editOneCompanyInfo(oneRowData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneCompanyInfo());


    			let payload ={
		    		companyId: oneRowData._id,
			        companyType: oneRowData.type ? oneRowData.type : COMPANY_TYPE.individual.db,
			        name: oneRowData.name ? oneRowData.name : null,
			        email: oneRowData.email ? oneRowData.email : null,
			        address: oneRowData.address ? oneRowData.address : null,
			        shippingAddress: oneRowData.shippingAddress ? oneRowData.shippingAddress : null,
			        remark: oneRowData.remark ? oneRowData.remark : null,
			        nid: oneRowData.nid ? oneRowData.nid : null,
			        bankAccNo: oneRowData.bankAccNo ? oneRowData.bankAccNo : null,
			        bankAccCode: oneRowData.bankAccCode ? oneRowData.bankAccCode : null,
			        bankAccName: oneRowData.bankAccName ? oneRowData.bankAccName : null,
			        // carrierStatus: oneRowData.carrierStatus ? oneRowData.carrierStatus : COMPANY_STATUS.waitingApprove.code,
			        // shipperStatus: oneRowData.carrierStatus ? oneRowData.carrierStatus : COMPANY_STATUS.waitingApprove.code,

			        badge: oneRowData.badge ? (oneRowData.badge.length > 0 ? oneRowData.badge : [] ) : [],

			        adminCompanyStatus: oneRowData.adminCompanyStatus ? oneRowData.adminCompanyStatus : null,
					adminRemark2: oneRowData.adminRemark2 ? oneRowData.adminRemark2 : null,
					adminRemark3: oneRowData.adminRemark3 ? oneRowData.adminRemark3 : null,
					adminRemark4: oneRowData.adminRemark4 ? oneRowData.adminRemark4 : null,
					adminRemark5: oneRowData.adminRemark5 ? oneRowData.adminRemark5 : null,
					adminRemark6: oneRowData.adminRemark6 ? oneRowData.adminRemark6 : null,
		    	}

		    	if(typeof oneRowData.carrierStatus !== 'undefined'){
		    		payload.carrierStatus = oneRowData.carrierStatus;
		    		payload.shipperStatus = oneRowData.carrierStatus;
		    	}

		    	//## [DEBUG]
	    		// console.log('-----Company Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await companyServices
    			.putUpdateOneCompanyInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneCompanyInfo(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneCompanyInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneCompanyInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneCompanyInfo(
								COMPANY_CONSTANTS.ERROR_FAILURE_EDIT_ONE_COMPANY_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneCompanyInfo(
							COMPANY_CONSTANTS.ERROR_FAILURE_EDIT_ONE_COMPANY_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneCompanyInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneCompanyInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


//###------------------------------------------------------------------------
function requestEditOneCompanyBadgeInfo(){
	return {
		type: COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_BADGE_INFO_REQUEST
	}
}

function successEditOneCompanyBadgeInfo(){
	return {
		type: COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_BADGE_INFO_SUCCESS,
	}
}



function failureEditOneCompanyBadgeInfo(error){
	return {
		type: COMPANY_ACTION_TYPES.UPDATE_ONE_COMPANY_BADGE_INFO_FAILURE,
		error,
	}
}


function editOneCompanyBadgeInfo(oneRowData){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestEditOneCompanyBadgeInfo());


    			let payload ={
		    		companyId: oneRowData._id,

			        badge: oneRowData.badge ? (oneRowData.badge.length > 0 ? oneRowData.badge : [] ) : [],
		    	}


		    	//## [DEBUG]
	    		// console.log('-----Company Info Update Payload ----')
	    		// console.log(payload)

    			let responseJson = await companyServices
    			.putUpdateOneCompanyBadgeInfo(
    				token,
    				payload
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						await dispatch(
								successEditOneCompanyBadgeInfo(
									
								)
							);

						return true;
					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureEditOneCompanyBadgeInfo(errMsg)
	                        );
	                    return false;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureEditOneCompanyBadgeInfo(
	                    		responseJson.requestError
	                    	)
						);
	                    return false;
	                }
	                else{
	                    await dispatch(
							failureEditOneCompanyBadgeInfo(
								COMPANY_CONSTANTS.ERROR_FAILURE_EDIT_ONE_COMPANY_INFO
							)
						);
		                return false;
	                }
				}
				else{
					await dispatch(
						failureEditOneCompanyBadgeInfo(
							COMPANY_CONSTANTS.ERROR_FAILURE_EDIT_ONE_COMPANY_INFO
						)
					);
	                return false;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureEditOneCompanyBadgeInfo(
    					error.message
    				)
    			);
	    		return false;
    		}
    	}
    	else{
    		await dispatch(
    			failureEditOneCompanyBadgeInfo(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return false;
    	}
    }
}


//###----------------------------------------------------------------------


export const editCompanyActions = {


	requestEditOneCompanyInfo,
	successEditOneCompanyInfo,
	failureEditOneCompanyInfo,
	editOneCompanyInfo,


	requestEditOneCompanyBadgeInfo,
	successEditOneCompanyBadgeInfo,
	failureEditOneCompanyBadgeInfo,
	editOneCompanyBadgeInfo,

}