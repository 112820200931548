import { SPINNER_ACTION_TYPES } from '../constants';



function showSpinner() { 
    return { 
        type: SPINNER_ACTION_TYPES.SHOW_SPINNER
    } 
}

function hideSpinner() { 
    return { 
        type: SPINNER_ACTION_TYPES.HIDE_SPINNER
    } 
}


export const spinnerActions = {
	showSpinner,
	hideSpinner
}