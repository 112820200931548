import { JOBS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
} from '../../../constants';
import { JOBS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { jobServices } from '../../../api/services';



function requestGetPaginateJobList(page,limit,sortBy){
	return {
		type: JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_REQUEST
	}
}

function successGetPaginateJobList(jobList,page,limit,sortBy){
	return {
		type: JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_SUCCESS,
		jobList,
		page,
		limit,
		sortBy,
	}
}

function successGetPaginateJobListAddMore(jobList,page,limit,sortBy){
	return {
		type: JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_SUCCESS_ADD_MORE,
		jobList,
		page,
		limit,
		sortBy,
	}
}

function failureGetPaginateJobList(error){
	return {
		type: JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_FAILURE,
		error,
	}
}

function emptyGetPaginateJobList(error){
	return {
		type: JOBS_ACTION_TYPES.GET_PAGINATE_JOB_LIST_EMPTY,
		error,
	}
}

function getPaginateJobList(page, limit, sortBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestGetPaginateJobList());

    			//## Get Only Job below available JOb
    			let filterBy = {
    				
    			}

    			let select = null;

    			if(!sortBy){
    				sortBy = APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY;
    			}

    			let payload = {
    				page: page,
    				limit: limit,
    				filterBy: filterBy,
    				sortBy: sortBy,
    				select: select,
    			}

    			let responseJson = await jobServices.getPaginateJobList(
						    				token,
						    				payload
						    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){
							// if(page === 0){
							// 	await dispatch(
							// 		successGetPaginateJobList(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }
							// else if(page > 0){
							// 	await dispatch(
							// 		successGetPaginateJobListAddMore(
							// 			responseJson.data,
							// 			page,
							// 			limit,
							// 			sortBy,
							// 		)
							// 	);
							// }

							await dispatch(
								successGetPaginateJobList(
									responseJson.data,
									page,
									limit,
									sortBy,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyGetPaginateJobList(
		                			JOBS_CONSTANTS.ERROR_FAILURE_PAGINATE_USER
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetPaginateJobList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetPaginateJobList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureGetPaginateJobList(
								JOBS_CONSTANTS.ERROR_FAILURE_PAGINATE_USER
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureGetPaginateJobList(
							JOBS_CONSTANTS.ERROR_FAILURE_PAGINATE_USER
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetPaginateJobList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetPaginateJobList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


//##-------------------------------------------------------------------------
function requestCountPaginateJobList(page,limit,sortBy){
	return {
		type: JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_REQUEST
	}
}

function successCountPaginateJobList(jobListTotalNum){
	return {
		type: JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_SUCCESS,
		jobListTotalNum,
	}
}

function failureCountPaginateJobList(error){
	return {
		type: JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_FAILURE,
		error,
	}
}

function emptyCountPaginateJobList(error){
	return {
		type: JOBS_ACTION_TYPES.COUNT_PAGINATE_JOB_LIST_EMPTY,
		error,
	}
}

function countPaginateJobList(filterBy){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestCountPaginateJobList());

    			//## Get Only Job below available JOb



    			let responseJson = await jobServices
    			.getCountTotalJobNum(
					token,						    				
    				filterBy
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){

							await dispatch(
								successCountPaginateJobList(
									responseJson.data
								)
							);

							return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyCountPaginateJobList(
		                			JOBS_CONSTANTS.ERROR_EMPTY_COUNT_PAGINATE_USER
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureCountPaginateJobList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureCountPaginateJobList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureCountPaginateJobList(
								JOBS_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_USER
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureCountPaginateJobList(
							JOBS_CONSTANTS.ERROR_FAILURE_COUNT_PAGINATE_USER
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(failureCountPaginateJobList(error.message));
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureCountPaginateJobList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


export const jobListActions = {

	requestGetPaginateJobList,
	successGetPaginateJobList,
	successGetPaginateJobListAddMore,
	failureGetPaginateJobList,
	emptyGetPaginateJobList,
	getPaginateJobList,


	requestCountPaginateJobList,
	successCountPaginateJobList,
	failureCountPaginateJobList,
	emptyCountPaginateJobList,
	countPaginateJobList,


}