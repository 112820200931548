import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faEye,

} from '@fortawesome/free-solid-svg-icons';

import { 
    withMultipleStyles,
} from '../../../utilities';

import { layoutMuiStyles } from '../../../styles';

class CompanyBankAccountDocumentRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {

        //## [DEBUG]
        // console.log('------ invokeParentMethod ------');
        // console.log('------ Data Row ------');
        // console.log(this.props.node)
        // console.log('------ CompanyID ------');
        // console.log(this.props.node.data._id)

        let context = this.props.context;

        if(this.props.node){
            if(this.props.node.data){
                let companyId = this.props.node.data._id ? this.props.node.data._id : null;
                context.componentParent.onViewCompanyBankAccountDocument(companyId)
            }
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <span>
                <button 
                    onClick={() => this.invokeParentMethod()} 
                    className={
                        classNames(
                            classes.agGridCellButton, 
                        )
                    }
                >
                    <FontAwesomeIcon 
                        icon={faEye}
                        className={
                            classNames(
                                classes.leftIcon, 
                                classes.iconSmall
                            )
                        }
                    />
                    {'ดูเอกสารบัญชีธนาคาร'}
                </button>
            </span>
        );
    }
};





CompanyBankAccountDocumentRenderer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    layoutMuiStyles
)(CompanyBankAccountDocumentRenderer);


export { injectedStylesContainers  as CompanyBankAccountDocumentRenderer };