import { combineReducers } from 'redux';

// import { authentication } from './authentication.reducer';
// import { userData } from './user-data.reducer';
// import { alert } from './alert.reducer';
// import { meterData } from './meter-data.reducer';

import { root } from '../features/root/reducers';
import { auth } from '../features/auth/reducers';
import { nav } from '../features/nav/reducers';
import { alert } from '../features/alert/reducers';
import { spinner } from '../features/spinner/reducers';
import { notif } from '../features/notif/reducers';
import { lightbox } from '../features/lightbox/reducers';

import { company } from '../features/company/reducers';
import { users } from '../features/users/reducers';
import { drivers } from '../features/drivers/reducers';
import { trucks } from '../features/trucks/reducers';
import { jobs } from '../features/jobs/reducers';
import { admin } from '../features/admin/reducers';

const rootReducer = combineReducers({
  	root,
  	auth,
  	nav,
  	alert,
  	spinner,
  	notif,
  	lightbox,
  	company,
  	users,
  	drivers,
    trucks,
    jobs,
    admin,
});

export { rootReducer };