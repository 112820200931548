import axios from 'axios';
import { API_CONSTANTS } from '../../api';


/**
*   NAME: getPaginateUserList
*   DESCRIPTION: get paginate company list
*   METHOD: POST
*/
async function getPaginateUserList(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.USER_LIST_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getPaginateUserList Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: getCountTotalUserNum
*   DESCRIPTION: count total company list
*   METHOD: POST
*/
async function getCountTotalUserNum(token,filterBy) {
    const requestOptions = {
        url: API_CONSTANTS.USER_COUNT_TOTAL_NUM_URL,
        method: 'POST',
        
        headers: { 
            'Accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token,

            // 'Allow': 'POST',
            // 'Access-Control-Expose-Headers': 'Access-Control-*',
            // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept"
        },
        // credentials: 'include',
        // withCredentials: true,
        data: JSON.stringify({
            filterBy: filterBy,
        }),
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("getCountTotalUserNum Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: postCreateNewUserInfo
*   DESCRIPTION: post create new user info
*   METHOD: POST
*/
async function postCreateNewUserInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.USER_CREATE_NEW_URL,
        method: 'POST',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("postCreateNewUserInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

/**
*   NAME: putUpdateUserCompanyInfo
*   DESCRIPTION: put update user company info
*   METHOD: PUT
*/
async function putUpdateUserCompanyInfo(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.USER_EDIT_COMPANY_DATA_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateUserCompanyInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}




/**
*   NAME: putUpdateUserCompanyInfoByEmail
*   DESCRIPTION: put update user company info
*   METHOD: PUT
*/
async function putUpdateUserCompanyInfoByEmail(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.USER_EDIT_COMPANY_DATA_BY_EMAIL_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateUserCompanyInfoByEmail Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: putUpdateUserContactName
*   DESCRIPTION: put update user company info
*   METHOD: PUT
*/
async function putUpdateUserContactName(token,payload) {
    const requestOptions = {
        url: API_CONSTANTS.USER_EDIT_CONTACT_NAME_DATA_URL,
        method: 'PUT',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("putUpdateUserContactName Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}


/**
*   NAME: deleteOneUserInfo
*   DESCRIPTION: delete one User Info
*   METHOD: GET
*/
async function deleteOneUserInfo(token,payload){

    const requestOptions = {
        url: API_CONSTANTS.USER_DELETE_ONE_INFO_URL,
        method: 'DELETE',
        
        // mode: 'cors',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + token,
                   // 'Access-Control-Allow-Origin':'*',
                   // 'Access-Control-Allow-Credentials': 'true',
                   // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                   // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        // credentials: 'include',
        data: JSON.stringify(payload)
    };


    try {

        let responseJson = await axios.request(requestOptions);

        return responseJson.data;
    }
    catch (error){
        //## [DEBUG]
        console.error("deleteOneUserInfo Services Request Error:");
        console.error(error);

        var data = {requestError: error.message, response: error.response};
        return data;
    }
}

export const userServices = {
    getPaginateUserList,
    getCountTotalUserNum,

    postCreateNewUserInfo,
    putUpdateUserCompanyInfo,
    putUpdateUserCompanyInfoByEmail,
    putUpdateUserContactName,

    deleteOneUserInfo,

};