import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { withMultipleStyles } from '../../../utilities';
// import { THEME } from '../../../styles';
import { alertMuiStyles } from '../styles';

import { alertActions } from '../actions';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AlertScreenContainers extends React.Component {

    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }


    onClose = () => {
        const { dispatch } = this.props;

        dispatch(alertActions.hideAlert());

    };


    render() {
        // const { classes } = this.props;

        return (
            this.props.isShowAlert && 
            <Dialog
                open={this.props.isShowAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {
                        this.props.title 
                        ?
                        this.props.title 
                        :
                        'Alert Title'
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {
                            this.props.message 
                            ?
                            this.props.message 
                            :
                            'Alert Message'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                        {'ปิด'}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}

const mapStateToProps = (state) => ({
    isShowAlert: state.alert.isShowAlert,
    title: state.alert.title,
    message: state.alert.message,
    type: state.alert.type,
})


AlertScreenContainers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

//## Mulitple Inject of MUI Theme Styles
var injectedStylesContainers = withMultipleStyles(
    alertMuiStyles
)(AlertScreenContainers);

const connectedContainers = connect(mapStateToProps)(
    injectedStylesContainers
);
export { connectedContainers  as AlertScreenContainers };