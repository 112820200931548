import React from 'react';
import { connect } from 'react-redux';



import logo from '../../../assets/svg/react_logo.svg';
import '../../../App.css';

class SplashPage extends React.Component {


    render() {
        return (
            <div className="App" id="splash_main">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p>
                        Edit <code>src/root/pages/RootPage.js</code> and save to reload.
                    </p>
                    <a
                        className="App-link"
                        href="https://reactjs.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Learn React
                    </a>
                </header>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});

const connectedSplashPage = connect(mapStateToProps)(SplashPage);
export { connectedSplashPage as SplashPage };
