export const TRUCKS_CONSTANTS = {
	ERROR_FAILURE_ALL_TRUCK_TYPE: 'ERROR_FAILURE_ALL_TRUCK_TYPE',

	ERROR_FAILURE_PAGINATE_TRUCK: 'ERROR_FAILURE_PAGINATE_TRUCK',
	ERROR_FAILURE_COUNT_PAGINATE_TRUCK: 'ERROR_FAILURE_COUNT_PAGINATE_TRUCK',
	ERROR_EMPTY_COUNT_PAGINATE_TRUCK: 'ERROR_EMPTY_COUNT_PAGINATE_TRUCK',
	ERROR_FAILURE_EDIT_ONE_TRUCK_INFO: 'ERROR_FAILURE_EDIT_ONE_TRUCK_INFO',
	
	ERROR_FAILURE_GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST: 'ERROR_FAILURE_GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST',
	ERROR_FAILURE_GET_ONE_TRUCK_CARGO_INSURANCE_DOC_LIST_EMPTY: 'ไม่มีเอกสาร',
	ERROR_FAILURE_DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF: 'ERROR_FAILURE_DOWNLOAD_ONE_TRUCK_CARGO_INSURANCE_DOC_PDF',

	ERROR_FAILURE_EDIT_ONE_TRUCK_TYPE_INFO: 'ERROR_FAILURE_EDIT_ONE_TRUCK_TYPE_INFO',
	
}