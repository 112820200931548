
import { AUTH_ACTION_TYPES } from '../constants';
import { AUTH_CONSTANTS } from '../constants';
import { GLOBAL_CONSTANTS } from '../../../constants';

// import { SPINNER_ACTION_TYPES } from '../../spinner/constants';

import { loginServices } from '../../../api/services';



import { createErrorMessageFromJsonResponse } from '../../../utilities';

// import { spinnerActions } from '../../spinner/actions';

function requestLogin() { 
    return { 
        type: AUTH_ACTION_TYPES.LOGIN_REQUEST
    } 
}

function successLogin(phoneNumber,token) { 
    return { 
        type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
        phoneNumber,
        token
    } 
}

function failureLogin(error) { 
    return { 
        type: AUTH_ACTION_TYPES.LOGIN_FAILURE, 
        error 
    } 
}

function emptyLogin(error) { 
    return { 
        type: AUTH_ACTION_TYPES.LOGIN_EMPTY,
        error
    } 
}

function login(phoneNumber,password){
    return async (dispatch,getState) => {
        let { playerId } = getState().notif;

        await dispatch(requestLogin());

        try {

            //## {DEBUG}[MOCK]
            if(!playerId){
                playerId = 'd357630d-614a-45c2-ad1a-252dec1a0dec';
            }

            let responseJson = await loginServices
            .login(
                phoneNumber, 
                password, 
                playerId
            );

            //## [DEBUG]
            // console.log("Login Response");
            // console.log(responseJson);

            

            if(responseJson){
                if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
                    if(responseJson.token){
                        var token = responseJson.token;

                        //## Save and Set State
                        await localStorage.setItem('token', token);
                        await localStorage.setItem('phoneNumber', phoneNumber);
                        await dispatch(successLogin(phoneNumber,token));
                        return token;
                        
                    }
                    else{
                        await dispatch(
                            emptyLogin(
                                AUTH_CONSTANTS.ERROR_EMPTY_LOGIN_USER_DATA
                            )
                        );
                        return null;
                    }
                }
                else if(responseJson.requestError) {
                    await dispatch(
                        failureLogin(
                            responseJson.requestError
                        )
                    );
                    return null;
                }
                else{

                    if(responseJson.message){
                        if(responseJson.error){
                            //## [DEBUG][SERVER-ERROR] Logging
                            console.log("[SERVER-ERROR] Response:");
                            console.log(responseJson.error);
                        }

                        var errMsg = createErrorMessageFromJsonResponse(responseJson);
                        await dispatch(
                            failureLogin(
                                errMsg
                            )
                        );
                    }
                    else{

                        await dispatch(
                            failureLogin(
                                AUTH_CONSTANTS.ERROR_LOGIN_NO_SERVER_ERROR_MESSAGE
                            )
                        );
                    }
                    
                    return null;
                }
            }
            else{
                await dispatch(
                    failureLogin(
                        AUTH_CONSTANTS.ERROR_LOGIN_NO_SERVER_ERROR_MESSAGE
                    )
                );
                return null;
            }
        }
        catch(error){

            await dispatch(
                failureLogin(
                    error.message
                )
            );
            return null;
        }
    }
}




export const loginActions = {
    requestLogin,
    successLogin,
    failureLogin,
    emptyLogin,
    login,
};