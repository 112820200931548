import { TRUCKS_ACTION_TYPES } from '../constants';
import { 
	APP_CONSTANTS,
	GLOBAL_CONSTANTS,
} from '../../../constants';
import { TRUCKS_CONSTANTS } from '../constants';

import { createErrorMessageFromJsonResponse } from '../../../utilities';

import { truckTypeServices } from '../../../api/services';



function requestGetAllTruckTypeList(){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_REQUEST
	}
}

function successGetAllTruckTypeList(truckTypeList){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_SUCCESS,
		truckTypeList,
	}
}



function failureGetAllTruckTypeList(error){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_FAILURE,
		error,
	}
}

function emptyGetAllTruckTypeList(error){
	return {
		type: TRUCKS_ACTION_TYPES.GET_ALL_TRUCK_TYPE_LIST_EMPTY,
		error,
	}
}

function getAllTruckTypeList(){
	return async (dispatch,getState) => {

    	var { token } = getState().auth;
    	if(!token){
    		token = await localStorage.getItem('token');
    	}


    	if(token){
    		try{
    			await dispatch(requestGetAllTruckTypeList());


    			let responseJson = await 
    			truckTypeServices.getAllTruckTypeList(
    				token,
    			);



				if(responseJson){
					if(responseJson.code === GLOBAL_CONSTANTS.CODE_SUCCESS){
						if(responseJson.data){

							await dispatch(
								successGetAllTruckTypeList(
									responseJson.data,
								)
							);

		                    return responseJson.data;
		                }
		                else{
		                	await dispatch(
		                		emptyGetAllTruckTypeList(
		                			TRUCKS_CONSTANTS.ERROR_FAILURE_ALL_TRUCK_TYPE
		                		)
		                	);
		                	return null;
		                }

					}
					else if(responseJson.response) {

	                    var errMsg = createErrorMessageFromJsonResponse(responseJson.response.data);
	                        await dispatch(
	                        	failureGetAllTruckTypeList(errMsg)
	                        );
	                    return null;
	                }
					else if(responseJson.requestError) {
	                    await dispatch(
	                    	failureGetAllTruckTypeList(
	                    		responseJson.requestError
	                    	)
						);
	                    return null;
	                }
	                else{
	                    await dispatch(
							failureGetAllTruckTypeList(
								TRUCKS_CONSTANTS.ERROR_FAILURE_ALL_TRUCK_TYPE
							)
						);
		                return null;
	                }
				}
				else{
					await dispatch(
						failureGetAllTruckTypeList(
							TRUCKS_CONSTANTS.ERROR_FAILURE_ALL_TRUCK_TYPE
						)
					);
	                return null;
				}
    		}
    		catch(error){
    			await dispatch(
    				failureGetAllTruckTypeList(
    					error.message
    				)
    			);
	    		return null;
    		}
    	}
    	else{
    		await dispatch(
    			failureGetAllTruckTypeList(
    				APP_CONSTANTS.ERROR_UNAUTHORIZED_REQUEST
    			)
    		);
	    	return null;
    	}
    }
}


//##-------------------------------------------------------------------------


export const truckTypeListActions = {

	requestGetAllTruckTypeList,
	successGetAllTruckTypeList,

	failureGetAllTruckTypeList,
	emptyGetAllTruckTypeList,
	getAllTruckTypeList,





}