import { NOTIF_ACTION_TYPES } from '../constants';
import { 
    APP_CONSTANTS,

} from '../../../constants';

const INIT_STATE = {
    error: [],

    subscribedNotification: false,
    playerId: null,

    notificationCounter: 0,
    notificationList: [],

    isRequestRefreshJobList: false,


    //## Notification Data List
    notificationListPaginatePage: 0,
    notificationListPaginateLimit: APP_CONSTANTS.DEFAULT_PAGINATION_PAGE_SIZE,
    notificationListPaginateSortBy: APP_CONSTANTS.DEFAULT_PAGINATION_SORT_BY,
    notificationListPaginateFilterBy: null,
    // notificationList: [],
    notificationListTotalNum: null,


}

const notif = (state = INIT_STATE, action) => {
    switch(action.type) {
        case NOTIF_ACTION_TYPES.SUBSCRIBE_NOTIFICATION: 
            return {
                ...state,
                subscribedNotification: true,
                playerId: action.playerId,

            };
        case NOTIF_ACTION_TYPES.UNSUBSCRIBE_NOTIFICATION: 
            return {
                ...state,
                subscribedNotification: false,
                playerId: null,
            };
        //##--------------------------------------------------------
        case NOTIF_ACTION_TYPES.SAVE_PLAYER_ID_REQUEST: 
            return {
                ...state,
                playerId: action.playerId,
            };
        case NOTIF_ACTION_TYPES.SAVE_PLAYER_ID_SUCCESS: 
            return {
                ...state,
                
            };
        case NOTIF_ACTION_TYPES.SAVE_PLAYER_ID_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //##-------------------------------------------------------------    
        case NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_REQUEST: 
            return {
                ...state,
                error: [],
                notificationList: [],
            };
        case NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_SUCCESS: 
            return {
                ...state,
                error: [],
                notificationList: action.notificationList,
            };
        case NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                notificationList: [],
            }; 
        case NOTIF_ACTION_TYPES.GET_NOTIFICATION_LIST_FROM_LOCAL_STORAGE_EMPTY: 
            return {
                ...state,
                error: [],
                notificationList: [],
            }; 
        //##-------------------------------------------------------------    
        case NOTIF_ACTION_TYPES.SET_NOTIF_COUNTER: 
            return {
                ...state,
                error: [],
                notificationCounter: action.newNotifCounter,
            };
        case NOTIF_ACTION_TYPES.RESET_NOTIF_COUNTER: 
            return {
                ...state,
                notificationCounter: 0
            };
        //##------------------------------------------------------- 
        case NOTIF_ACTION_TYPES.REFRESH_JOB_LIST_NOTIFICATION_REQUEST: 
            return {
                ...state,
                isRequestRefreshJobList: true
            };
        case NOTIF_ACTION_TYPES.REFRESH_JOB_LIST_NOTIFICATION_SUCCESS: 
            return {
                ...state,
                isRequestRefreshJobList: false
            };
        
        //##-------------------------------------------------------
        case NOTIF_ACTION_TYPES.SAVE_NOTIF_DATA_SUCCESS: 
            return {
                ...state,
                error: [],
                // notificationList: [action.notificationObj,...state.notificationList],
            };
        case NOTIF_ACTION_TYPES.SAVE_NOTIF_DATA_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            }; 
        //##-------------------------------------------------------     
        //###--------------------------------------------------
    
        case NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_REQUEST: 
            return {
                ...state,
            };
        case NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_SUCCESS: 
            return {
                ...state,
                notificationList: action.notificationList,
                notificationListPaginatePage: action.page,
                notificationListPaginateLimit: action.limit,
                notificationListPaginateSortBy: action.sortBy,
            };
        case NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_SUCCESS_ADD_MORE: 
            return {
                ...state,
                notificationList: [...state.notificationList, ...action.notificationList],
                notificationListPaginatePage: action.page,
                notificationListPaginateLimit: action.limit,
                notificationListPaginateSortBy: action.sortBy,
            };
        case NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
            };      
        case NOTIF_ACTION_TYPES.GET_PAGINATE_NOTIF_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
            };
        //###--------------------------------------------------
    
        case NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_REQUEST: 
            return {
                ...state,
            };
        case NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_SUCCESS: 
            return {
                ...state,
                notificationListTotalNum: action.notifListTotalNum,
            };
        case NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],
                notificationListTotalNum: null,
            };      
        case NOTIF_ACTION_TYPES.COUNT_PAGINATE_NOTIF_LIST_EMPTY: 
            return {
                ...state,
                error: [...state.error, action.error],
                notificationListTotalNum: 0,
            };   
        //###--------------------------------------------------
        //###--------------------------------------------------
    
        case NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_INFO_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_INFO_SUCCESS: 
            return {
                ...state,

            };
        case NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_INFO_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };
        //###--------------------------------------------------
    
        case NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_STATUS_REQUEST: 
            return {
                ...state,
                error: [],
            };
        case NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_STATUS_SUCCESS: 
            return {
                ...state,

            };
        case NOTIF_ACTION_TYPES.UPDATE_ONE_NOTIF_STATUS_FAILURE: 
            return {
                ...state,
                error: [...state.error, action.error],

            };    

        //###--------------------------------------------------    
        default: 
            return state;
    }

}

export { notif };